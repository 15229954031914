.primryAbortButton {
  height: 2.871rem;
  width: 10rem;
  border-radius: 0.25rem;
  background-color: #00a0f0;
  font-family: "RakutenRoundRegular";
  font-size: 1rem;
  border-radius: 0.25rem;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.secButton {
  height: 3rem;
  width: 10.125rem;
  color: #019ff0;
  margin-right: 1rem;
  background-color: white;
  border: 0.063rem solid #00a0f0;
  border-radius: 0.25rem;
}

.outerBox {
  height: 20.125rem;
  width: 54.813rem;
  border-radius: 0.375rem;
  background-color: #ffffff;
  margin-left: 0.938rem;
}

.innerBox {
  box-sizing: border-box;
  height: 14.688rem;
  width: 49.625rem;
  border: 0.188rem dashed #00a0f0;
  border-radius: 0.5rem;
  margin: 0 auto;
  margin-top: 2.625rem;
  padding-top: 3.75rem;
}

.uploadImage {
  cursor: pointer;
  height: 3.438rem;
  width: 4.938rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.inputButton {
  display: none;
}

.imageText {
  color: #333333;
  font-family: "RakutenRoundRegular";
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.063rem;
  text-align: center;
}

.noteText {
  color: #333333;
  font-family: "RakutenRoundRegular";
  font-size: 0.875rem;
  line-height: 1rem;
  margin-left: 1rem;
}

.ProgressBarLength {
  width: 88%;
  position: relative;
  margin-top: 1rem;
  margin-left: 3rem;
}
.ProgressBarCancel {
  position: absolute;
  right: -1rem;
  top: -0.4rem;
  font-weight: bold;
  font-size: medium;
  color: #7a7676;
  cursor: pointer;
}

.zipFileAlign {
  margin-top: -0.25rem;
}

.cursorNotAllowed {
  cursor: not-allowed;
}
