@import "../../../../variables.scss";

@media screen {
  .el-details-publicContainer {
    background: url("../../../../assets/images/el_bg.svg");
    height: 41.29rem;
    // margin: auto;
  }

  .el-details-innerContainer {
    margin-left: 2.7rem;
    padding: 0;
    position: relative;
    background-color: transparent;
    word-wrap: break-word;
    font-family: "RakutenRoundSemiBold";
  }

  .el-details-mainContainer {
    margin-top: 5rem;
    background-color: white;
  }

  .el-details-coursePageLabelIcon {
    margin-left: -0.2rem;
    margin-top: 1.37rem;
  }

  .el-details-coursePageLabel {
    font-size: 1rem;
    text-transform: uppercase;
    font-family: "RakutenRoundRegular";
    position: relative;
    margin-top: 1.2rem;
    margin-left: 0.3rem;
    margin-right: auto;
  }

  .el-details-courseName {
    // height: 7.625rem;
    width: 42.375rem;
    font-family: "RakutenRoundSemiBold";
    font-size: 3rem;
    line-height: 3.7rem;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-height: 12rem;
    margin-left: -0.3rem;
  }

  .el-details-courseTimeLanguage {
    font-family: "RakutenRoundSemiBold";
    font-size: 0.875rem;
    text-transform: uppercase;
  }

  .el-details-courseTimeLanguageAlignment {
    display: flex;
    margin-top: 2rem;
    white-space: nowrap;
    flex-wrap: nowrap;
  }

  .el-details-courseProviderIconName {
    display: flex;
    column-gap: 4px;
    align-items: center;
    margin-left: 1rem;
  }

  .el-details-courseProvider {
    font-family: "RakutenRoundRegular";
    font-size: 0.875rem;
    text-transform: uppercase;
  }

  .el-details-courseProviderContent {
    margin-top: -0.2rem;
    margin-right: -1rem;
  }

  .el-details-enrollStatus {
    font-family: "RakutenRoundRegular";
    font-size: 1rem;
    margin-top: 0.8rem;
  }

  .el-details-textualSection {
    font-size: 5rem;
    margin-bottom: 1rem;
    word-wrap: break-word;
    min-height: 15rem;
    margin-bottom: 3rem;
    margin-left: 4.2rem;
    width: 37%;
  }

  .el-details-detailLabel {
    font-size: 2rem;
    font-family: "RakutenRoundRegular";
    margin-bottom: 1rem;
    word-wrap: break-word;
    margin-left: 0.48rem;
  }

  .el-details-otherContentContainer {
    background: #fff;
    min-height: 15rem;
  }

  .el-details-contentPadding {
    word-wrap: break-word;
    font-size: 1rem;
    width: 35rem;
    margin-left: 1.5rem;
  }

  .el-details-enrollContainer {
    margin-top: 72px;
  }

  .el-details-descriptionText > li {
    white-space: pre-wrap;
  }

  .el-details-courseImage {
    width: 35rem;
    height: 22.18125rem;
    object-fit: cover;
    border-radius: 0.5rem;
    margin-top: 20rem;
    position: inherit;
    margin-left: -0.48rem;
    margin-top: 1.4rem;
  }

  .el-details-courseImageBelowText {
    height: 5rem;
    width: 33.5rem;
    background: #fff;
    align-items: center;
    justify-content: center;
  }

  .el-details-courseImageBelowText > span {
    color: $primary;
    font-size: 1.5rem;
    font-family: "RakutenRoundSemiBold";
  }

  .el-details-iconRotate {
    transform: rotate(-180deg);
  }

  .el-details-chevronIcon {
    padding: 0 0.5rem;
  }

  .el-details-publicContentContainer {
    min-height: 15rem;
    background-color: #f7f7f7;
  }

  .el-details-bannerContainer {
    display: flex;
    flex-direction: row;
    margin-left: "10px";
  }

  .el-details-bannerContent {
    display: flex;
    flex-direction: column;
    width: 18rem;
    text-align: center;
    margin: 4rem 0 0 0;
  }

  .el-details-bannerLabel {
    font-size: 1.125rem;
    font-family: "RakutenRoundregular";
  }

  .el-details-bannerDescription {
    font-size: 1.875rem;
    width: 320px;
    height: 80px;
    font-family: "RakutenRoundRegular";
  }

  .el-details-bannerPublicHorizontalLine {
    width: 5.25rem;
    height: 0;
    border: 0.0625rem solid $primary;
    margin: 7rem 4rem;
  }

  .el-details-buttonContainer {
    margin-top: 5rem;
  }

  .el-details-primaryButton {
    width: 12.5rem;
    height: 3.125rem;
    font-size: 1.125rem;
    margin-right: 1rem;
    margin-top: 15px;
  }

  .el-details-publicButton {
    background-color: $primary;
  }

  .el-details-unenrollButton {
    background-color: #828282;
  }

  .el-details-statusTextBold {
    font-family: "RakutenRoundSemiBold";
    font-size: 0.875rem;
  }

  .el-details-quoteContainer {
    margin-bottom: 5rem;
    background-color: #fff;
    padding: 5rem 0;
  }

  .el-details-modalFooterContent {
    color: #ff0000;
    font-size: 0.75rem;
    margin: 2rem 0 0 2rem;
  }

  .el-details-filler {
    height: 3rem;
    background-color: #fff;
  }

  .el-details-secondContainer {
    width: 82.63625rem !important;
    margin: 0rem;
    padding: 0rem;
    padding-bottom: 7.5rem;
  }

  .el-details-container {
    // max-width: 82.5rem;
    background-color: white;
    padding: 0;
    position: relative;
    margin-top: 7rem;
  }

  .el-details-alertContainer {
    top: 4rem;
  }

  .el-details-descriptionButton {
    margin-top: 2rem;
    background-color: transparent;
    border: none;
    color: $primary;
    text-transform: uppercase;
  }

  .el-details-showMore {
    overflow: hidden;
    word-wrap: break-word;
    height: auto;
    width: 98%;
  }

  .el-details-showLess {
    padding-bottom: 1rem;
    max-height: 10rem;
    overflow: hidden;
    word-wrap: break-word;
    -webkit-mask-image: linear-gradient(#fff, #fff, rgba(255, 255, 255, 0.2));
    position: relative;
    width: 98%;
  }

  .el-details-contentPadding a {
    text-decoration: underline !important;
    color: $primary !important;
  }

  .el-details-timeStamp {
    color: #ff0000;
  }

  .el-details-already-Completed {
    margin-top: 1.5rem;
    margin-left: 1rem;
  }

  .el-details-StepsContainer {
    max-width: 82.5rem;
    padding: 0;
    position: relative;
    background-color: transparent;
    word-wrap: break-word;
    display: flex;
    flex-direction: row;
  }

  .el-details-courseDescription {
    font-size: 2rem;
    margin-bottom: 1rem;
    word-wrap: break-word;
    font-family: "RakutenRoundRegular";
  }

  .course-detail-breadcrumb {
    padding: 3rem 0.6rem 3rem 0rem;
    font-family: "RakutenRoundRegular";
  }

  .course-detail-breadcrumb a {
    color: $primary;
  }
}

@media screen and (min-width: 4320px) {
  .el-details-innerContainer {
    margin-left: 135.5rem;
  }

  .el-details-textualSection {
    margin-left: 135.5rem;
    width: 8%;
  }

  .el-details-otherContentContainer {
    margin-left: 0rem;
    margin-right: 133rem;
  }

  .el-details-courseImage {
    margin-left: -81rem;
  }
}

@media screen and (min-width: 3400px) and (max-width: 4319px) {
  .el-details-innerContainer {
    margin-left: 92rem;
  }

  .el-details-textualSection {
    margin-left: 92rem;
    width: 13%;
  }

  .el-details-otherContentContainer {
    margin-left: 0rem;
    margin-right: 91rem;
  }

  .el-details-courseImage {
    margin-left: -55rem;
  }
}

@media screen and (min-width: 2880px) and (max-width: 3000px) {
  .el-details-textualSection {
    margin-left: 37.5rem;
  }

  .el-details-otherContentContainer {
    margin-left: 34.5rem;
    max-width: 67.5%;
  }
}

@media screen and (min-width: 2160px) and (max-width: 2880px) {
  .el-details-innerContainer {
    margin-left: 48rem;
  }

  .el-details-textualSection {
    margin-left: 49rem;
    width: 17%;
  }

  .el-details-otherContentContainer {
    margin-left: 0rem;
    margin-right: 48rem;
  }

  .el-details-courseImage {
    margin-left: -30rem;
  }
}

@media screen and (min-width: 1920px) and (max-width: 2160px) {
  .el-details-innerContainer {
    margin-left: 25.5rem;
  }

  .el-details-textualSection {
    margin-left: 25.5rem;
    width: 25%;
  }

  .el-details-otherContentContainer {
    margin-left: 0rem;
    margin-right: 23.5rem;
  }

  .el-details-courseImage {
    margin-left: -17rem;
  }
}

@media screen and (min-width: 1800px) and (max-width: 1920px) {
  .el-details-innerContainer {
    margin-left: 14.5rem;
  }

  .el-details-textualSection {
    margin-left: 18.5rem;
    width: 27%;
  }

  .el-details-otherContentContainer {
    margin-left: 0rem;
    margin-right: 16.5rem;
  }

  .el-details-courseImage {
    margin-left: -10rem;
  }
}

@media screen and (max-width: 1680px) {
  .el-details-innerContainer {
    margin-left: 9rem;
  }

  .el-details-courseImage {
    margin-left: -7rem;
  }
}

@media screen and (max-width: 1600px) {
  .el-details-innerContainer {
    margin-left: 8.5rem;
  }

  .el-details-courseImage {
    margin-left: -7rem;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1500px) {
  .el-details-innerContainer {
    margin-left: 4rem;
  }

  .el-details-textualSection {
    margin-left: 4rem;
    width: 35%;
  }

  .el-details-otherContentContainer {
    margin-left: 0rem;
    margin-right: 5.5rem;
  }

  .el-details-courseImage {
    margin-left: -4rem;
  }
}

@media screen and (max-width: 1439px) {
  .el-details-innerContainer {
    margin-left: 4.5rem;
  }

  .el-details-courseImage {
    margin-left: -3rem;
    width: 32rem;
    height: 20.18125rem;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1280px) {
  .el-details-courseImage {
    margin-left: -7rem;
  }

  .el-details-bannerContainer {
    margin-left: -2rem;
  }

  .el-details-bannerPublicHorizontalLine {
    margin: 7rem 2rem;
  }
}

@media screen and (max-width: 476px) {
  .course-detail-breadcrumb {
    padding: 1rem;
    font-family: "RakutenRoundRegular";
  }

  .course-detail-breadcrumb a {
    color: $primary;
  }

  .el-details-publicContainer {
    background: url("../../../../assets/images/elearningBanner.png") no-repeat;
    margin-left: 0;
    height: auto;
  }

  .el-details-innerContainer {
    padding: 0;
    position: relative;
    background-color: transparent;
    word-wrap: break-word;
    margin-left: 0;
    font-family: "RakutenRoundSemiBold";
  }

  .el-details-detailBreadCrumb {
    padding: 3.5rem;
  }

  .el-details-coursePageIconLabel {
    margin-top: 1.5rem;
    padding-left: 16px;
  }

  .el-details-coursePageLabelIcon {
    margin-top: 0;
  }

  .el-details-coursePageLabel {
    font-size: 0.875rem;
    text-transform: uppercase;
    font-family: "RakutenRoundRegular";
    position: relative;
    margin-left: 0.4rem;
    margin-right: auto;
    margin-top: 0;
  }

  .el-details-courseName {
    font-family: "RakutenRoundSemiBold";
    max-height: auto;
    height: auto;
    font-size: 1.75rem;
    line-height: 2.1875rem;
    margin-top: 4px;
    display: -webkit-box;
    max-width: 20.25rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .el-details-courseTimeLanguage {
    // font-family: "RakutenRoundSemiBold";
    // font-size: 0.875rem;
    // text-transform: uppercase;
    // width: 9rem;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // margin-top: 0;
  }

  .el-details-courseTimeLanguageAlignment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
    width: 100%;
  }

  .el-details-courseProviderIconName {
    display: flex;
    column-gap: 4px;
    width: max-content;
  }

  .el-details-courseProvider {
    font-family: "RakutenRoundRegular";
    font-size: 0.75rem;
    text-transform: uppercase;
    width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 0;
    margin-left: 0;
    margin-top: 0.2rem;
  }

  .el-details-courseProviderContent {
    margin-top: -0.3rem;
    margin-right: -1rem;
  }

  .el-details-enrollStatus {
    font-family: "RakutenRoundRegular";
    font-size: 1rem;
    width: 19rem;
  }

  .el-details-textualSection {
    margin: 0;
    font-size: 1.375rem;
    word-wrap: break-word;
    line-height: normal;
    height: auto;
    min-height: auto;
    padding: 0 16px;
  }

  .el-details-detailLabel {
    font-family: "RakutenRoundRegular";
    margin-bottom: 1rem;
    word-wrap: break-word;
    margin-left: 0.48rem;
    // font-size: 1.5rem;
  }

  .el-details-otherContentContainer {
    background: #fff;
    min-height: 15rem;
  }

  .el-details-contentPadding {
    word-wrap: break-word;
    margin-left: 0;
    width: 328px;
  }

  .el-details-descriptionText > li {
    white-space: pre-wrap;
  }

  .el-details-courseImage {
    height: 14.7rem;
    width: 23.5rem;
    border-radius: 0;
    margin-left: 0;
    object-fit: fill;
    margin-top: 1rem;

    position: inherit;
    object-fit: cover;
  }

  .el-details-courseImageBelowText {
    height: 5rem;
    width: 33.5rem;
    background: #fff;
    align-items: center;
    justify-content: center;
  }

  .el-details-courseImageBelowText > span {
    color: $primary;
    font-size: 1.5rem;
    font-family: "RakutenRoundSemiBold";
  }

  .el-details-iconRotate {
    transform: rotate(-180deg);
  }

  .el-details-chevronIcon {
    padding: 0 0.5rem;
  }

  .el-details-publicContentContainer {
    min-height: 15rem;
    background-color: #f7f7f7;
  }

  .el-details-bannerContainer {
    display: flex;
    flex-direction: column;
    margin-left: "10px";
  }

  .el-details-bannerContent {
    display: flex;
    flex-direction: column;
    width: 18rem;
    text-align: center;
    margin: 2rem 2.5rem 2rem;
  }

  .el-details-bannerLabel {
    font-size: 1.125rem;
    font-family: "RakutenRoundregular";
  }

  .el-details-bannerDescription {
    font-size: 18px;
    width: 189px;
    height: 46px;
    margin-left: 46px;
    font-family: "RakutenRoundRegular";
  }

  .el-details-bannerPublicHorizontalLine {
    width: 0;
    height: 40px;
    border: 0.0625rem solid $primary;
    margin: 0.5rem 11rem 0.5rem;
  }

  .el-details-buttonContainer {
    z-index: 1000;
    background-color: white;
    margin-top: 0;
  }

  .el-details-statusContainer {
    display: none;
  }

  .el-details-primaryButton {
    position: fixed;
    bottom: 0;
    width: 20rem;
    height: 2.5rem;
    width: 18rem;
    font-size: 1.125rem;
    margin-right: 1rem;
    margin-top: 15px;
    margin-bottom: 0.6rem;
  }

  .el-details-publicButton {
    background-color: $primary;
  }

  .el-details-unenrollButton {
    background-color: #828282;
  }

  .el-details-statusTextBold {
    font-family: "RakutenRoundSemiBold";
    font-size: 0.875rem;
  }

  .el-details-quoteContainer {
    margin-bottom: 5rem;
    background-color: #fff;
    padding: 5rem 0;
  }

  .el-details-modalFooterContent {
    color: #ff0000;
    font-size: 0.75rem;
    margin: 2rem 0 0 2rem;
  }

  .el-details-filler {
    height: 3rem;
    background-color: #fff;
  }

  .el-details-secondContainer {
    width: 23.5rem !important;
    margin: 0rem;
    padding: 0rem;
    padding-bottom: 7.5rem;
  }

  .el-details-container {
    background-color: white;
    padding: 0;
    position: relative;
    margin-top: 7rem;
  }

  .el-details-alertContainer {
    top: 4rem;
  }

  .el-details-descriptionButton {
    margin-top: 2rem;
    background-color: transparent;
    border: none;
    color: $primary;
    text-transform: uppercase;
  }

  .el-details-showMore {
    overflow: hidden;
    word-wrap: break-word;
    height: auto;
    width: 98%;
  }

  .el-details-showLess {
    padding-bottom: 1rem;
    max-height: 10rem;
    overflow: hidden;
    word-wrap: break-word;
    -webkit-mask-image: linear-gradient(#fff, #fff, rgba(255, 255, 255, 0.2));
    position: relative;
    width: 98%;
  }

  .el-details-contentPadding a {
    text-decoration: underline !important;
    color: $primary !important;
    right: 50px;
  }

  .el-details-timeStamp {
    color: #ff0000;
  }

  .el-details-already-Completed {
    margin-top: 0.5rem;
    margin-left: 2rem;
  }

  .el-details-StepsContainer {
    // max-width: 82.5rem;
    padding: 0;
    position: relative;
    background-color: transparent;
    word-wrap: break-word;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .el-details-enrollContainer {
    background-color: white;
    overflow: hidden;
    z-index: 1000;
  }

  .el-details-buttonContainer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-bottom: 10px;
  }

  .primaryButton {
    margin-top: 0;
  }

  .el-details-primaryFilledOutlineButton {
    position: none;
    left: 0px;
    top: 0rem;
    margin-left: 18.5rem;
    background: none;
  }

  .el-details-courseDescription {
    font-size: 1.6rem;
    margin-top: 1.3rem;
    margin-bottom: 1rem;
    word-wrap: break-word;
    font-family: "RakutenRoundRegular";
  }

  .el-details-mainContainer {
    margin-top: 0rem;
  }
}
