@font-face {
  font-family: "RakutenRoundBold";
  src: url("./assets/fonts/RakutenRounded_Bd.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "RakutenRoundLight";
  src: url("./assets/fonts/RakutenRounded_Lt.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "RakutenRoundRegular";
  src: url("./assets/fonts/RakutenRounded_Rg.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "RakutenRoundSemiBold";
  src: url("./assets/fonts/RakutenRounded_SBd.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "RakutenRoundBlack";
  src: url("./assets/fonts/RakutenRounded_Blk.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "RakutenSansRegular";
  src: url("./assets/fonts/RakutenSans_UI_Rg.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "RakutenSansSemiBold";
  src: url("./assets/fonts/RakutenSans_UI_SBd.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "RakutenSansBold";
  src: url("./assets/fonts/RakutenSans_UI_Bd.ttf") format("truetype");
  font-weight: normal;
}

body {
  background-color: #f7f7f7;
  position: relative;
  min-height: 100vh;
  overflow-y: auto;
  /* padding-bottom: 22.5rem; */
  height: auto;
  font-family: "RakutenRoundRegular";
}

#root {
  overflow-y: auto;
}

/* opacity applies to any disabled button */
button:disabled {
  opacity: 0.4;
}

.ant-btn.ant-btn-lg {
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  font-family: "RakutenRoundSemiBold";
  padding: 0px;
}

.ant-modal .ant-modal-title {
  font-family: "RakutenRoundSemiBold";
  font-size: 24px;
  font-weight: 400;
}

.ant-table-wrapper .ant-table {
  font-size: 14px;
  font-family: "RakutenRoundRegular";
  line-height: 17.67px;
  background-color: transparent;
}

.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td {
  background-color: #ebebeb;
}

.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background-color: #d1d1d1;
}

.ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin-top: 40px;
}

.ant-table-ellipsis--2 {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.row > .ant-row {
  margin: 0px 15px;
}

td .ant-checkbox .ant-checkbox-inner,
th .ant-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  border-radius: 3px;
}

.ant-dropdown .ant-table-filter-dropdown {
  max-width: 182px;
}

.ant-dropdown .ant-table-filter-dropdown .ant-dropdown-menu-title-content {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ant-dropdown
  .ant-table-filter-dropdown
  .ant-table-filter-dropdown-search-input
  input {
  min-width: 0px;
}

.ant-progress-text {
  color: black !important;
}

.ant-tag {
  border-radius: 4px !important;
  /* border: 1.5px solid #000000; */
  background: transparent;
}

.ant-table-wrapper .ant-table thead > tr > :first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.ant-table-wrapper .ant-table thead > tr > :last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.ant-table-wrapper .ant-table tbody > :nth-child(2) > td:first-of-type {
  border-top-left-radius: 8px;
}

.ant-table-wrapper .ant-table tbody > :nth-child(2) > td:last-of-type {
  border-top-right-radius: 8px;
}

.ant-table-wrapper .ant-table tbody > :last-child > td:first-of-type {
  border-bottom-left-radius: 8px;
}

.ant-table-wrapper .ant-table tbody > :last-child > td:last-of-type {
  border-bottom-right-radius: 8px;
}

tbody:before {
  line-height: 8px;
  content: "\200C";
  display: block;
}

.ant-table-wrapper tr.ant-table-expanded-row > td,
:where(.css-dev-only-do-not-override-htwhyh).ant-table-wrapper
  tr.ant-table-expanded-row:hover
  > td {
  background-color: #ffffff;
}

.ant-table-expanded-row
  .ant-table-wrapper
  .ant-table
  tbody
  > tr
  > td:first-of-type {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.ant-table-expanded-row
  .ant-table-wrapper
  .ant-table
  tbody
  > tr
  > td:last-of-type {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.ant-notification {
  z-index: 9999;
}

.ant-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 0px;
}
