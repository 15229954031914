@import "../../../../variables.scss";

.placeholderHeader {
  font-family: RakutenRoundRegular;
  font-size: 2.25rem;
  margin-bottom: 1rem;
}

.placeholderFlowContainer {
  display: flex;
  position: relative;
}

.placeholderFlowFirstCircle {
  background: #ffffff;
  border-radius: 50%;
  width: 6.625rem;
  height: 6.625rem;
  box-shadow: 0 0.125rem 0.25rem 0 #d1d1d1;
  display: inline-block;
  margin-left: 1.5rem;
  text-align: center;
  padding-top: 2.25rem;
  z-index: 900;
}

// .placeholderLineContainer {
//   position: absolute;
//   top: 2.125rem;
//   display: flex;
//   align-items: center;
// }

.placeholderLineHr {
  width: 34.375rem;
  border-top: solid 0.0625rem;
  display: inline-block;
}

.placeholderLineText {
  margin-left: 1.25rem;
  font-family: RakutenRoundSemiBold;
  font-size: 0.875rem;
  line-height: 1.0625rem;
}

.container {
  // border-right: 0.0625rem solid $primary;
}

a.courseTile {
  width: 22.5625rem;
  height: 12.5rem;
  background-color: #ffffff;
  box-shadow: 0 0.125rem 0.25rem 0 #d1d1d1;
  border-radius: 0.375rem;
  position: relative;
  display: inline-block;
  margin-right: 5.3125rem;
  color: inherit;
}

a.courseTile:hover {
  box-shadow: 0 0.125rem 0.75rem 0 #d1d1d1;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.courses-taking-header {
  font-family: RakutenRoundRegular;
  font-size: 2.25rem;
  margin: 0;
  margin-bottom: 2.6875rem;
}

.myLearning:link,
.myLearning:hover,
.myLearning:visited {
  font-family: RakutenRoundRegular;
  font-size: 0.875rem;
  margin-bottom: 1.625rem;
  margin-right: 3.875rem;
  text-decoration: underline;
  color: $primary;
  letter-spacing: 0.0675rem;
}

.courseName {
  margin-top: 1.4rem;
  font-family: RakutenRoundSemiBold;
  font-size: 1.4rem;
  color: $primary;
  margin-left: 1.5rem;
  display: inline-block;
  width: 14.9375rem;
  height: 3.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.courseImage {
  position: absolute;
  right: -3.9375rem;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 9.5rem;
  height: 9.5rem;
  width: 7.9375rem;
  top: 1.5625rem;
  border-radius: 0.375rem;
}

.subheading {
  margin-left: 1.5rem;
  padding-top: 1.25rem;
}

.subheadingImage {
  width: 1.4375rem;
}

.subheadingText {
  font-family: RakutenRoundRegular;
  font-size: 0.75rem;
  margin-left: 0.3125rem;
  color: #333333;
}

.seesionStart {
  display: inline-block;
  width: 11.375rem;
  margin-left: 1.5rem;
  margin-top: 0.875rem;
  font-family: RakutenRoundRegular;
  font-size: 0.875rem;
  line-height: 1.3125rem;
}

.placeholderHeader {
  font-family: RakutenRoundRegular;
  font-size: 2.25rem;
  margin-bottom: 1rem;
}

.placeholderText {
  font-family: RakutenRoundRegular;
  font-size: 1.125rem;
  margin-bottom: 2.7813rem;
  line-height: 1.625rem;
  margin-right: 1.25rem;
}

.placeholderFlowContainer {
  display: flex;
  position: relative;
}

.placeholderFlowFirstCircle {
  background: #ffffff;
  border-radius: 50%;
  width: 6.625rem;
  height: 6.625rem;
  box-shadow: 0 0.125rem 0.25rem 0 #d1d1d1;
  display: inline-block;
  text-align: center;
  z-index: 900;
  margin-left: 1.5rem;
  padding-top: 2.25rem;
}

@media screen {
  .parent-container {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .enrollment-container {
    margin: 0.7rem;
  }

  .enrollment-status {
    font-family: "RakutenRoundSemiBold";
    font-size: 2.25rem;
    margin-bottom: 0.3rem;
  }

  .enrollment-sub-heading {
    font-family: "RakutenRoundRegular";
    padding-top: 0.3rem;
    font-size: 1.125rem;
    width: 50rem;
  }

  .placeholderFlowThirdText {
    font-family: RakutenRoundSemiBold;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    width: 3.5rem;
    color: $primary;
    display: inline-block;
    width: 5rem;
  }

  .placeholderLineContainer {
    position: absolute;
    top: 2.125rem;
    display: flex;
    align-items: center;
  }

  .placeholderLineHr {
    width: 34.375rem;
    border-top: solid 0.0625rem;
    display: inline-block;
  }

  .placeholderFlowSecondCircle {
    background: #ffffff;
    // background: pink;
    border-radius: 50%;
    width: 6.625rem;
    height: 6.625rem;
    box-shadow: 0 0.125rem 0.25rem 0 #d1d1d1;
    display: inline-block;
    text-align: center;
    z-index: 900;
    margin-left: 2.25rem;
    padding-top: 1.6875rem;
  }

  .placeholderFlowSecondCircle {
    background: #ffffff;
    border-radius: 50%;
    width: 6.625rem;
    height: 6.625rem;
    box-shadow: 0 0.125rem 0.25rem 0 #d1d1d1;
    display: inline-block;
    margin-left: 2.25rem;
    text-align: center;
    padding-top: 1.6875rem;
    z-index: 900;
  }

  .placeholderFlowFirstText {
    font-family: RakutenRoundSemiBold;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    width: 3.5rem;
    color: $primary;
    display: inline-block;
    width: 3.5rem;
  }

  .placeholderFlowThirdText {
    font-family: RakutenRoundSemiBold;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    width: 3.5rem;
    color: $primary;
    display: inline-block;
    width: 5rem;
  }

  .placeholderFlowSecondText {
    font-family: RakutenRoundSemiBold;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: $primary;
    display: inline-block;
    width: 4.625rem;
  }
}

@media screen and (min-width: 4320px) and (max-width: 5760px) {
  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60rem;
  }
}

@media screen and (min-width: 3000px) and (max-width: 4320px) {
  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 65rem;
  }
}

@media screen and (min-width: 3000px) and (max-width: 3300px) {
  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 65rem;
  }
}

@media screen and (min-width: 2880px) and (max-width: 3000px) {
  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70rem;
  }
}

@media screen and (min-width: 2160px) and (max-width: 2880px) {
  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70rem;
  }
}

@media screen and (min-width: 1800px) and (max-width: 2160px) {
  .upcoming-session-lg-screen {
    display: flex;
  }

  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 68rem;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1800px) {
  a.courseTile {
    margin-left: 10rem;
  }

  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 63rem;
  }

  .upcoming-session-lg-screen {
    display: flex;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1600px) {
  .headerContainer1 {
    margin-left: 2.75rem;
    margin-top: 4rem;
  }

  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 63rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .headerContainer1 {
    margin-left: 1rem;
    width: 49rem;
  }

  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 53rem;
  }

  .upcoming-session-lg-screen {
    display: flex;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1400px) {
  .headerContainer1 {
    margin-left: 2rem;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .headerContainer1 {
    margin-left: 0.5rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .placeholderFlowSecondCircle {
    background: #ffffff;
    background: yellow;
    border-radius: 50%;
    width: 6.625rem;
    height: 6.625rem;
    box-shadow: 0 0.125rem 0.25rem 0 #d1d1d1;
    display: inline-block;
    text-align: center;
    z-index: 900;
    margin-left: 2.25rem;
    padding-top: 1.6875rem;
  }

  .headerContainer1 {
    margin-left: 4rem;
  }

  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 63rem;
  }
}

@media screen and (max-width: 768px) {
  .placeholderFlowSecondCircle {
    background: #ffffff;
    background: green;
    border-radius: 50%;
    width: 6.625rem;
    height: 6.625rem;
    box-shadow: 0 0.125rem 0.25rem 0 #d1d1d1;
    display: inline-block;
    text-align: center;
    z-index: 900;
    margin-left: 2.25rem;
    padding-top: 1.6875rem;
  }
}

@media screen and (max-width: 476px) {
  .parent-container {
    margin-left: -1rem;
    margin-right: 1rem;
  }

  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .courses-taking-header {
    font-family: RakutenRoundSemiBold;
    font-size: 1.125rem;
    margin-top: 1rem;
    margin-bottom: 2.6875rem;
    margin-left: 0px;
  }

  .placeholderFlowFirstCircle {
    background: #ffffff;
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
    box-shadow: 0 0.125rem 0.25rem 0 #d1d1d1;
    display: inline-block;
    text-align: center;
    z-index: 900;
    margin-left: 1rem;
    padding-top: 1.6875rem;
  }

  .placeholderFlowSecondCircle {
    background: #ffffff;
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
    box-shadow: 0 0.125rem 0.25rem 0 #d1d1d1;
    display: inline-block;
    text-align: center;
    z-index: 900;
    margin-left: 1rem;
    padding-top: 1rem;
  }

  .placeholderFlowSecondText {
    font-family: RakutenRoundSemiBold;
    font-size: 0.575rem;
    line-height: 1.0625rem;
    color: $primary;
    display: inline-block;
    width: 4.625rem;
  }

  .placeholderFlowThirdText {
    font-family: RakutenRoundSemiBold;
    font-size: 0.575rem;
    line-height: 1.0625rem;
    width: 0.5rem;
    color: $primary;
    display: inline-block;
    width: 4.625rem;
  }

  .placeholderFlowFirstText {
    font-family: RakutenRoundSemiBold;
    font-size: 0.575rem;
    line-height: 1.0625rem;
    width: 3.5rem;
    color: $primary;
    display: inline-block;
    width: 3.5rem;
  }

  .placeholderLineHr {
    width: 19.3rem;
    border-top: solid 0.0625rem;
    display: inline-block;
  }

  .placeholderLineContainer {
    position: absolute;
    top: 1.125rem;
    display: flex;
    align-items: center;
  }

  .placeholderLineText {
    margin-left: 0.4rem;
    font-family: RakutenRoundSemiBold;
    font-size: 0.575rem;
    line-height: 1.0625rem;
    width: 10px;
  }

  .enrollment-container {
    margin: 0rem;
  }

  .enrollment-status {
    font-size: 1rem;
    font-family: "RakutenRoundSemiBold";
    margin-top: 0.5rem;
  }

  .enrollment-sub-heading {
    font-family: "RakutenRoundRegular";
    padding-top: 0.3rem;
    font-size: 0.875rem;
    width: 22rem;
  }

  .courses-taking-header {
    font-family: "RakutenRoundRegular";
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0.8rem;
    margin-left: -1rem;
  }

  .myLearning:link,
  .myLearning:hover,
  .myLearning:visited {
    font-family: "RakutenRoundRegular";
    font-size: 0.875rem;
    font-weight: 900;
    margin-bottom: 0;
    margin-left: 2.5rem;
    text-decoration: underline;
    color: $primary;
    letter-spacing: 0.0675rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a.courseTile {
    width: 278px;
    height: 158px;
    background-color: #ffffff;
    box-shadow: 0 0.125rem 0.25rem 0 #d1d1d1;
    border-radius: 0.375rem;
    position: relative;
    display: inline-block;
    padding-right: 5rem;
    margin-right: 5rem;
    color: inherit;
  }

  .courseImage {
    position: absolute;
    right: -3rem;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 9.5rem;
    height: 103px;
    width: 87px;
    top: 1.5625rem;
    border-radius: 0.375rem;
    margin-right: 1rem;
  }

  .seesionStart {
    display: inline-block;
    width: 11.375rem;
    margin-left: 1.5rem;
    margin-top: 0rem;
    font-family: "RakutenRoundRegular";
    font-size: 0.875rem;
    line-height: 1.3125rem;
  }

  .courseName {
    margin-top: 0.6rem;
    margin-left: 1.5rem;
    width: 14.9375rem;
    height: 3rem;
  }

  .mobileScroll {
    display: flex;
    overflow: hidden;
    overflow-x: scroll;
    margin-left: -1rem;
    height: 158px;
  }
}
