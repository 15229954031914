.carousel-baseLine {
  height: 0.125rem;
  background-color: #d1d1d1;
  border-radius: 0.125rem;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  margin-top: 0.125rem;
}
.carousel-progressLine {
  height: 100%;
}
.carousel-btn {
  border: none;
  background-color: transparent;
  box-shadow: none;
}

.carousel-btn[disabled] {
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
}
