.headerRow {
  margin-top: 4rem;
  display: flex;
  justify-content: space-between;
}

.headerText {
  font-family: RakutenRoundSemiBold;
  font-size: 1.25rem;
  line-height: 1.8125rem;
}

.fillingFast-slider {
  margin-top: 1.5rem;
}

.placeholderText {
  font-family: RakutenRoundRegular;
  font-size: 1.125rem;
  line-height: 1.625rem;
}

@media screen {
}

@media screen and (max-width: 476px) {
  .headerText {
    font-family: RakutenRoundSemiBold;
    font-size: 1rem;
    line-height: 1.8125rem;
  }
  .headerRow {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 23rem;
    padding-top: 2rem;
  }
  .custom-mobile-slider {
    padding-left: 0.5rem;
    padding-right: 1rem;
    width: 380px;
  }
  .placeholderText {
    font-family: RakutenRoundRegular;
    font-size: 1rem;
    line-height: 1rem;
    padding-left: 0.6rem;
  }
}
