.course {
  width: 100%;
  height: 12.36px;
  font-family: "RakutenRoundRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 40px;
  padding-left: 6px;
}

.black {
  color: black;
}
.white {
  color: #f7f7f7;
}
.imageWidth {
  width: 24px;
}
