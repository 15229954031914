@import "../../../variables.scss";

@media screen {
  .myteamreport-container {
    margin-top: 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .myteam-Report .ant-select-selector {
    height: 3rem !important;
  }

  .teamreports-pagetitle {
    font-family: "RakutenRoundSemiBold";
    font-size: 2rem;
    margin-left: 0.5rem;
  }

  .myteam-breadcrumbs {
    margin-top: 2rem;
    width: 82rem;
    margin-bottom: 1rem;
    color: $primary;
    font-family: "RakutenRoundRegular";
  }

  .myreporttile-container {
    margin-top: 2rem;
    width: 82rem;
  }

  .myteam-tile {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  .attandancestats-tile {
    height: 8.5rem;
    background-color: #ffffff;
    margin-bottom: 2rem;
    padding: 1rem;
    text-transform: uppercase;
    color: #303030;
    font-family: "RakutenRoundSemiBold";
    border: solid 1px #9c9c9c;
    border-radius: 0.25rem;
  }

  .reportfilter-Schedule-Container {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    align-items: baseline;
    margin-top: 1rem;
    width: 83rem;
  }

  .reportfilter-datePicker {
    height: 2.6rem;
    width: 14.3rem;
    border-radius: 0px;
    margin-left: -2rem;
    margin-bottom: 1rem;

    .ant-picker-input {
      font-family: "RakutenRoundSemiBold";
    }
  }

  .reportfilter-region {
    .ant-select-dropdown .ant-select-item-option {
      font-family: "RakutenRoundRegular";
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 2.6rem;
      width: 7.3rem;
    }

    .ant-select-selector {
      height: 2.6rem;
      border-radius: 0px;
    }

    .ant-select-selection-item {
      width: 10rem;
      font-family: "RakutenRoundSemiBold";
      margin: auto;
    }
  }

  .reportfilter-scheduleTime {
    font-size: 0.875rem;
    display: flex;
    flex-direction: row;
    position: relative;
    top: -0.6rem;
    font-family: "RakutenRoundRegular";
  }
}

.reportfilter-selectRegion {
  .ant-select-selector {
    height: 3rem !important;
  }

  .ant-select-selection-item {
    width: 10rem;
    font-family: "RakutenRoundSemiBold";
    margin: auto;
  }
}

.reports-container {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  width: 85rem;
}

.reportfilter-applyButton {
  background-color: $primary;
  border-radius: 0.25rem;
  font-family: "RakutenRoundSemiBold";
  font-size: 0.9rem;
  line-height: 1.25rem;
  color: #ffffff;
  padding: 0.7rem 0.2rem;
  text-transform: uppercase;
  width: 5.7rem;
  height: 2.6rem;
  margin-top: 15px;
}

.reportfilter-resetButton {
  background-color: $primary;
  border-radius: 0.25rem;
  font-family: "RakutenRoundSemiBold";
  font-size: 0.9rem;
  line-height: 1.25rem;
  color: #ffffff;
  padding: 0.7rem 0.8rem;
  text-transform: uppercase;
  width: 5.7rem;
  height: 2.6rem;
  margin-top: 15px;
}

.reportfilter-downloadCSVButton {
  margin-right: 2rem;
  background-color: white;
  color: black;
  font-size: 1.5rem;
}

.teamreports-categoryStats {
  margin-bottom: 3rem;
}

@media screen and (max-width: 768px) {
  .myteamreport-container {
    padding-left: 42rem;
    margin-top: -1rem;
    width: 23rem;
    overflow-x: hidden;
  }

  .myreporttile-container {
    padding-left: 1rem;
  }

  .myteamreport {
    width: 23rem;
    overflow-x: hidden;
  }

  .teamreports-header2 {
    width: 23rem;
    overflow: scroll;
    margin-right: 60rem;
  }

  .teamreports-categoryStats {
    margin-bottom: 3rem;
    padding-right: 55rem;
  }

  .reports-container {
    width: 23rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
}
