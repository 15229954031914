.rightIcon {
	transform: rotate(180deg);
}

.tableSearch {
	position: absolute;
	top: 4.45rem;
	left: 58rem;
}
.downloadButton {
	position: absolute;
	top: 4.5rem;
	left: 58rem;
}
