@import "../../../variables.scss";
.Antdcategory-management-container {
  margin-top: 7rem;
}
.category-card-column {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.categorManagement-page-title {
  font-family: "RakutenRoundSemiBold";
  font-size: 2.5rem;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.categorManagement-pageCount {
  margin-left: 11.5rem;
  margin-top: -4.5rem;
  font-size: 0.87rem;
  font-family: "RakutenRoundRegular";
}

.errorMessageCategoryModal {
  margin-left: 0.4rem;
  margin-top: -2.188rem;
  font-size: small;
  margin-bottom: 1.07rem;
  color: red;
}

.newButton {
  padding: 1rem;
  padding-left: 66.5rem;
  margin-top: -4rem;
  margin-bottom: 2rem;
}

.newCategoryButton {
  border-radius: 0px;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "RakutenRoundSemiBold";
  color: #ffffff;
  border-radius: 0.25rem;
  text-transform: uppercase;
  height: 2.85rem;
  border-top-right-radius: 0.25rem;
  background: $primary;
}

@media screen and (min-width: 4320px) {
  .Antdcategory-management-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 37.5%;
    margin-right: 34%;
  }
}

@media screen and (min-width: 3400px) and (max-width: 4319px) {
  .Antdcategory-management-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 33.5%;
    margin-right: 29.5%;
  }
}

@media screen and (min-width: 3200px) and (max-width: 3400px) {
  .Antdcategory-management-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 29.5%;
    margin-right: 29.5%;
  }
}

@media screen and (min-width: 3000px) and (max-width: 3200px) {
  .Antdcategory-management-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 26.5%;
    margin-right: 26.5%;
  }
}

@media screen and (min-width: 2880px) and (max-width: 3000px) {
  .Antdcategory-management-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 26.5%;
    margin-right: 26.5%;
  }
}

@media screen and (min-width: 2160px) and (max-width: 2880px) {
  .Antdcategory-management-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 25%;
    margin-right: 25%;
  }
}

@media screen and (min-width: 1920px) and (max-width: 2160px) {
  .Antdcategory-management-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 17.5%;
    margin-right: 17.5%;
  }
}

@media screen and (min-width: 1800px) and (max-width: 1920px) {
  .Antdcategory-management-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 13.5%;
    margin-right: 12.5%;
  }
}

@media screen and (min-width: 1680px) and (max-width: 1800px) {
  .Antdcategory-management-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 11%;
    margin-right: 10%;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1679px) {
  .Antdcategory-management-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 6%;
    margin-right: 6%;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1599px) {
  .Antdcategory-management-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media screen and (min-width: 1350px) and (max-width: 1440px) {
  .Antdcategory-management-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 0;
    margin-right: 5%;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1350px) {
  .Antdcategory-management-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .Antdcategory-management-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 5%;
    margin-right: 5%;
  }
  .newButton {
    padding: 1rem;
    padding-left: 54.5rem;
    margin-top: -5rem;
    margin-bottom: 2rem;
  }
}

@media screen and (min-width: 1150px) and (max-width: 1200px) {
  .Antdcategory-management-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 5%;
    margin-right: 5%;
  }
}
