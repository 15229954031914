@import "../../variables.scss";
@media screen {
  .primaryOutlineButton {
    font-family: "RakutenRoundSemiBold";
    font-size: 1rem;
    line-height: 0.5rem;
    color: $primary;
    background-color: white;
    border: 1px solid $primary;
    text-transform: uppercase;
    border-radius: 3px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 312px;
    height: 54px;
  }

  .iconStyle {
    padding: 0.7rem;
    position: absolute;
    top: 4px;
    left: 175px;
  }
}
@media screen and (max-width: 476px) {
  .primaryOutlineButton {
    font-family: "RakutenRoundSemiBold";
    font-size: 13px;
    line-height: 0.5rem;
    color: $primary;
    background-color: white;
    border: 1px solid $primary;
    text-transform: uppercase;
    border-radius: 3px;
    width: 168px;
    height: 32px;
  }

  .iconStyle {
    padding: 0.7rem;
  }
}
