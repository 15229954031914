:root {
  --primary: white;
  --secondary: white;
  --tertiary: white;
  --secondary-with-opacity: rgba($secondary, 0.5);
  --primary-with-opacity: rgba($primary, 0.5);
}

$primary: var(--primary);
$secondary: var(--secondary);
$tertiary: var(--tertiary);
$secondary-with-opacity: var(--secondary-with-opacity);
$primary-with-opacity: var(--primary-with-opacity);
