@import "../../../variables.scss";
.promotional-banner-crumbs {
  color: $primary;
}
.antd-adminpromotionalbanner-container {
  margin-top: 7rem;
}
.promotionalbanner-breadcrumb {
  width: 83rem;
}
.promotionalbanner-pagetitle {
  font-family: "RakutenRoundSemiBold";
  font-size: 2.5rem;
  margin-bottom: 3rem;
}

.promotionalbanner-pageCount {
  margin-left: 24rem;
  margin-top: -4.9rem;
  font-size: 0.87rem;
  font-family: "RakutenRoundRegular";
}

.adminpromotionalbanner-alert {
  margin-top: -1rem;
  margin-bottom: 1rem;
}
.promotionalbanner-tabs {
  width: 25rem;

  .ant-tabs-tab-btn {
    font-family: "RakutenRoundRegular";
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  .ant-tabs-nav .ant-tabs-nav-wrap {
    overflow: visible;
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $primary !important;
  }

  .ant-tabs-tab .ant-tabs-tab-btn:hover {
    color: $primary !important;
  }

  .ant-tabs-nav-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .ant-tabs-tab {
    width: 45%;
    justify-content: center;
  }

  .ant-tabs-ink-bar {
    width: 5rem;
    border-bottom: 3px solid $primary;
    border-radius: 5px;
  }
}

.promotionalbanner-table {
  width: 83rem;
}

.banneraddedit-errorMessage {
  // margin-left: 1rem;
  // margin-top: -4rem;
  font-size: small;
  color: red;
}
.banneraddedit-errorMessage-region {
  width: 17rem;
  font-size: small;
  color: red;
  margin-top: -1.5rem;
}
.promotionalbanner-searchstyle {
  width: 10rem;
  height: 2.7rem;
  font-size: 1rem;
  font-family: "RakutenRoundRegular";
  border-radius: 4px;
}

.promotionalbanner-addNewBanner {
  background-color: $primary;
  font-family: "RakutenRoundSemiBold";
  font-size: 1rem;
  color: #ffffff;
  padding: 0.7rem 1.5rem;
  border: none;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.7rem;
}

.antd-banneraddedit-container {
  margin-top: 7rem;
}
.banneraddedit-biggerSvgBox {
  background-color: #ffffff;
  width: 25.45rem;
  height: 15.9375rem;
  border-radius: 0.3125rem;
  border: dashed 0.0625rem;
}
.banneraddedit-biggerSvgBox {
  cursor: pointer;
  max-width: 24rem;
  height: 17rem;
}
.banneraddedit-biggerSvg {
  width: 10rem;
  margin: 5rem;
  margin-left: 7rem;
}
.banneraddedit-image {
  width: 25rem;
}
.banneraddedit-imageText {
  color: #000000;
  font-family: "RakutenRoundRegular";
  font-size: 0.8125rem;
  letter-spacing: 0;
  line-height: 1.0625rem;
  text-align: center;
  margin-top: 5rem;
  margin-left: -1rem;
  font-weight: bold;
}
.banneraddedit-imageGuideline {
  width: 25.45rem;
  text-align: center;
  font-family: RakutenRoundRegular;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  margin-top: 5rem;
  color: #828282;
}

.addbanneraddedit-dashboard {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-left: 5rem;
}
.addbanneraddedit-column-space {
  padding-left: 3rem;
}

.banneraddedit-startdatecolumn {
  margin-left: 3rem;
}
.banneraddedit-enddatecolumn {
  margin-left: 10rem;
}
.banneraddedit-priority {
  margin-left: -17.5rem;
}

.banneraddedit-priority {
  .ant-select-selector {
    width: 18rem !important;
    height: 3rem !important;
    font-family: "RakutenRoundSemiBold";
  }
  .ant-select-arrow {
    margin-top: 0px;
    margin-right: 4rem;
  }
  .ant-select-selection-placeholder {
    margin: auto;
  }
  .ant-select-selection-item {
    margin: auto;
  }
}
.banneraddedit-selectregion {
  margin-left: 1rem;
  width: 17rem;
}

.banneraddedit-referenceURL {
  margin-top: 9rem;
}

.banneraddededit-button-container {
  margin: 0rem;
  padding: 0rem;
  margin-top: 4rem;
}
.banneraddedit-resbutton {
  background-color: #828282;
  border-radius: 0.25rem;
  font-family: "RakutenRoundSemiBold";
  font-size: 1rem;
  line-height: 1.25rem;
  color: #ffffff;
  padding: 0.7rem 1.5rem;
  border: none;
  text-transform: uppercase;
  height: 2.85rem;
  min-width: 11rem;
}
.banneraddedit-subButton {
  background-color: $primary;
  font-family: "RakutenRoundSemiBold";
  font-size: 1rem;
  color: #ffffff;
  border-radius: 0.25rem;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
  text-transform: uppercase;
  height: 2.85rem;
  min-width: 11rem;
}
@media screen and (min-width: 4320px) {
  .antd-adminpromotionalbanner-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 37.5%;
    margin-right: 34%;
  }
  .antd-banneraddedit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 33.5%;
    margin-right: 34%;
  }
  .promotionalbanner-tabs {
    margin-left: -5rem;
  }
  .promotionalbanner-searchbar {
    margin-left: -9rem;
  }
  .promotionalbanner-addNewBannerbutton {
    margin-left: 7rem;
  }
}

@media screen and (min-width: 3400px) and (max-width: 4319px) {
  .antd-adminpromotionalbanner-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 33.5%;
    margin-right: 29.5%;
  }
  .antd-banneraddedit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 33.5%;
    margin-right: 29.5%;
  }
}

@media screen and (min-width: 3200px) and (max-width: 3400px) {
  .antd-adminpromotionalbanner-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 29.5%;
    margin-right: 29.5%;
  }
  .antd-banneraddedit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 29.5%;
    margin-right: 29.5%;
  }
}

@media screen and (min-width: 3000px) and (max-width: 3200px) {
  .antd-adminpromotionalbanner-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 26.5%;
    margin-right: 26.5%;
  }
  .antd-banneraddedit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 26.5%;
    margin-right: 26.5%;
  }
}

@media screen and (min-width: 2880px) and (max-width: 3000px) {
  .antd-adminpromotionalbanner-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 26.5%;
    margin-right: 26.5%;
  }
  .antd-banneraddedit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 26.5%;
    margin-right: 26.5%;
  }
}

@media screen and (min-width: 2160px) and (max-width: 2880px) {
  .antd-adminpromotionalbanner-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 25%;
    margin-right: 25%;
  }
  .antd-banneraddedit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 25%;
    margin-right: 25%;
  }
  .promotionalbanner-searchbar {
    margin-left: -2rem;
  }
  .promotionalbanner-addNewBannerbutton {
    margin-left: 9rem;
  }
}

@media screen and (min-width: 1920px) and (max-width: 2160px) {
  .antd-adminpromotionalbanner-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 17.5%;
    margin-right: 17.5%;
  }
  .antd-banneraddedit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 17.5%;
    margin-right: 17.5%;
  }
  .promotionalbanner-searchbar {
    margin-left: -3rem;
  }
  .promotionalbanner-addNewBannerbutton {
    margin-left: 11rem;
  }
}

@media screen and (min-width: 1800px) and (max-width: 1920px) {
  .antd-adminpromotionalbanner-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 13.5%;
    margin-right: 12.5%;
  }
  .promotionalbanner-pagetitle {
    margin-left: 0rem;
  }
  .antd-banneraddedit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 13.5%;
    margin-right: 12.5%;
  }
  .promotionalbanner-searchbar {
    margin-left: -3rem;
  }
  .promotionalbanner-addNewBannerbutton {
    margin-left: 0rem;
  }
}

@media screen and (min-width: 1680px) and (max-width: 1800px) {
  .antd-adminpromotionalbanner-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 11%;
    margin-right: 10%;
  }
  .promotionalbanner-pagetitle {
    margin-left: 0rem;
  }
  .antd-banneraddedit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 11%;
    margin-right: 10%;
  }
  .promotionalbanner-searchbar {
    margin-left: -3rem;
  }
  .promotionalbanner-addNewBanner {
    margin-left: 11rem;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1679px) {
  .antd-adminpromotionalbanner-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 6%;
    margin-right: 6%;
  }
  .antd-banneraddedit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 6%;
    margin-right: 6%;
  }
  .promotionalbanner-searchbar {
    margin-left: -2rem;
  }
  .promotionalbanner-addNewBannerbutton {
    margin-left: 11rem;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1599px) {
  .antd-adminpromotionalbanner-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 5%;
    margin-right: 5%;
  }
  .antd-banneraddedit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media screen and (max-width: 1400px) {
  .promotionalbanner-pagetitle {
    width: 24rem;
  }
  .promotionalbanner-pageCount {
    width: 18px;
    margin-left: 23.7rem;
  }
  .promotionalbanner-tabs {
    width: 20rem;
    margin-left: 4rem;
  }
  .banneraddedit-errorMessage-region {
    width: 15rem;
    margin-top: -2rem;
    font-size: small;
    color: red;
  }

  .promotionalbanner-addNewBannerbutton {
    margin-left: 3rem;
    width: 11rem;
  }
  .antd-adminpromotionalbanner-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 6%;
    margin-right: 6%;
  }
  .antd-banneraddedit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 6%;
    margin-right: 6%;
  }
  .promotionalbanner-searchbar {
    margin-left: 0rem;
  }
  .promotionalbanner-addNewBannerbutton {
    margin-left: 8rem;
  }
}
@media screen and (min-width: 1350px) and (max-width: 1440px) {
  .antd-adminpromotionalbanner-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 0;
    margin-right: 5%;
    width: 100%;
  }
  .antd-banneraddedit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 0;
    margin-right: 5%;
    width: 100%;
  }
  .promotionalbanner-searchbar {
    margin-left: -1rem;
  }
  .promotionalbanner-addNewBannerbutton {
    margin-left: 8rem;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1350px) {
  .antd-adminpromotionalbanner-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 1%;
    margin-right: 5%;
  }
  .antd-banneraddedit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 1%;
    margin-right: 5%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .antd-adminpromotionalbanner-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 5%;
    margin-right: 5%;
  }
  .antd-banneraddedit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 5%;
    margin-right: 5%;
  }

  .promotionalbanner-searchbar {
    margin-left: -10rem;
  }
  .promotionalbanner-addNewBannerbutton {
    margin-left: 1rem;
  }
}

@media screen and (min-width: 1150px) and (max-width: 1200px) {
  .promotionalbanner-breadcrumb {
    margin-left: auto;
    margin-top: auto;
  }

  .promotionalbanner-pagetitle {
    width: 19rem;
    font-size: 2rem;
  }
  .promotionalbanner-pageCount {
    margin-left: 19rem;
  }
  .promotionalbanner-tabs {
    width: 19rem;
    margin-left: 0.5rem;
  }
  .promotionalbanner-searchstyle {
    width: 8rem;
  }

  .promotionalbanner-searchbar {
    margin-left: 2rem;
  }

  .promotionalbanner-addNewBanner {
    width: 11rem;
    margin-left: 6rem;
  }
  .promotionalbanner-addNewBannerbutton {
    margin-left: 2rem;
  }

  .promotionalbanner-table {
    width: 83rem;
  }

  .antd-adminpromotionalbanner-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 5%;
    margin-right: 5%;
  }
  .antd-banneraddedit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 5%;
    margin-right: 5%;
  }
  .banneraddedit-tessst {
    width: 50rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .addbanneraddedit-dashboard {
    padding-left: 1rem;
    width: 35rem;
  }
}
