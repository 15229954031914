.tileContainer {
  height: 8.5rem;
  width: 18rem;
  background-color: #ffffff;
  margin: 1rem;
  padding: 1rem;
  text-transform: uppercase;
  color: #303030;
  font-family: "RakutenRoundSemiBold";
  border: solid 1px #9C9C9C;
  border-radius: 0.25rem;
}
.tileLabel {
  font-size: 0.875rem;
  line-height: 1rem;
  width: 100%;
}
.tileValue {
  font-size: 1.75rem;
  width: 100%;
  padding-top: .5rem;
}

.tileInsight{
  font-family: "RakutenRoundRegular";
  font-weight: 600;
  font-size: small;
  width: 100%;
  text-transform: none;
  margin-top: .5rem;
}
.tileInsight > span {
  color: #008000;
}
.courseType {
  border-radius: 0.25rem;
  color: #ffffff;
  text-align: center;
  padding: 0.25rem 0.75rem;
  font-size: 0.75rem;
  float: right;  
  text-transform: uppercase;
  font-family: "RakutenRoundRegular";
  margin-left: 1rem;
}

.il {
  background-color: green;
}
.el {
  background-color: cadetblue;
}