.clearAll {
  color: #00a0f0;
  cursor: pointer;
  font-family: "RakutenRoundSemiBold";
  font-size: x-small;
  position: relative;
  margin-left: 90%;
  width: 100%;
  margin-top: -1.45rem;
}
