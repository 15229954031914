@media screen and (min-width: 4320px) {
  .calendar-container {
    margin: 35%;
    margin-top: 8em;
  }
}

@media screen and (min-width: 3400px) and (max-width: 4319px) {
  .calendar-container {
    margin: 25%;
    margin-top: 8em;
  }
}

@media screen and (min-width: 3200px) and (max-width: 3400px) {
  .calendar-container {
    margin: 25%;
    margin-top: 8em;
  }
}

@media screen and (min-width: 3000px) and (max-width: 3200px) {
  .calendar-container {
    margin: 20%;
    margin-top: 8em;
  }
}

@media screen and (min-width: 2880px) and (max-width: 3000px) {
  .calendar-container {
    margin: 20%;
    margin-top: 8em;
  }
}

@media screen and (min-width: 2160px) and (max-width: 2880px) {
  .calendar-container {
    margin: 20%;
    margin-top: 8em;
  }
}

@media screen and (min-width: 1920px) and (max-width: 2160px) {
  .calendar-container {
    margin: 20%;
    margin-top: 8em;
  }
}

@media screen and (min-width: 1800px) and (max-width: 1920px) {
  .calendar-container {
    margin: 15%;
    margin-top: 8em;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1800px) {
  .calendar-container {
    margin: 10%;
    margin-top: 8em;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1600px) {
  .calendar-container {
    margin: 7%;
    margin-top: 8em;
  }
}

@media screen and (max-width: 1440px) {
  .calendar-container {
    margin: 4em;
    margin-top: 8em;
  }
}
