.card {
  border-radius: 0.5rem;
  border-color: #f7f7f7;
  background-color: #f7f7f7;
  width: 19.5rem;
  min-height: 22.25rem;
  max-height: 22.25rem;
  box-shadow: 0 0.125rem 0.25rem 0 #d1d1d1;
  margin-right: 0.3125rem;
  z-index: 0;
  overflow: hidden;
}

.cardLP {
  border-radius: 0.5rem;
  border-color: #f7f7f7;
  background-color: #f7f7f7;
  width: 19.5rem;
  min-height: 22.25rem;
  max-height: 22.25rem;
  box-shadow: 0 0.125rem 0.25rem 0 #d1d1d1;
  margin: 1rem;
  z-index: 0;
}

.card:hover {
  box-shadow: 0 0.125rem 0.75rem 0 #d1d1d1;
}

.cardTitle {
  width: 17.5rem;
  height: 3.75rem;
  font-size: 1.5rem !important;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 1rem;
  letter-spacing: 0;
  line-height: 1.875rem;
  font-family: "RakutenRoundSemiBold";
  color: #000000 !important;
}

.spanData {
  width: 13.75rem;
}

.cardDiv {
  margin-right: 0.625rem;
  padding-right: 0rem;
}

.instructorledIcon {
  height: 0.875rem;
  width: 1.438rem;
  margin-left: 1rem;
  margin-top: 1.519rem;
  position: "relative";
  top: "-2px";
  left: "-5px";
}

.courseProviderIcon {
  height: 0.875rem;
  width: 0.813rem;
  margin-right: 0.125rem;
  margin-top: -0.13rem;
}

.overlay {
  padding: 0;
}

.body {
  padding-left: 0;
  padding-top: 0.5rem;
  margin-bottom: 1.5rem;
  color: #333333;
}

.textData {
  width: 12.5rem;
  margin-top: 0.75rem;
  margin-left: 1rem;
  padding-left: 0rem;
  padding-top: 0rem;
  font-family: "RakutenRoundRegular";
  font-size: 0.875rem;
  line-height: 1.438rem;
}

.courseProvider {
  margin-left: 0.2rem;
  padding-left: 1rem;
  margin-top: 0.938rem;
  margin-right: 0rem;
  padding-right: 0rem;
  font-family: "RakutenRoundRegular";
  color: #000000 !important;
  font-size: 0.75rem;
  line-height: 1.063rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.overlayText {
  margin-top: 1.519rem;
  margin-left: 0.188rem;
  font-family: "RakutenRoundRegular";
  font-size: 0.75rem;
  line-height: 2.5rem;
  letter-spacing: 0;
  color: #ffffff;
  position: "absolute";
  top: "-25px";
  left: "-5px";
}

.imageDiv {
  border-radius: 0.5rem 0.5rem 0 0;
}

.image {
  border-radius: 0.5rem 0.5rem 0 0;
  margin-bottom: 1rem;
  width: 312px;
  height: 172px;
  filter: brightness(50%);
}

.duration {
  margin-left: 0rem;
  padding-left: 1rem;
  font-family: "RakutenRoundRegular";
  font-size: 0.875rem;
  line-height: 1.438rem;
  overflow: hidden;
  white-space: nowrap;
}

.roundProgressBar {
  /* margin-top: -1.75rem;
  margin-left: 0.2rem;
  padding-top: 0rem;
  height: 5rem;
  width: 5rem; */
}

.assignedStatus {
  border-radius: 0.25rem;
  background-color: #bf0000;
  color: #ffffff;
  text-align: center;
  padding: 0.25rem 0.75rem;
  font-size: 0.75rem;
  margin-left: 8.25rem;
  text-transform: uppercase;
  font-family: "RakutenRoundRegular";
}

.cardOverlayText {
  margin-top: 1.25rem;
}

.circleProgressBar {
  width: 5rem;
  height: 5rem;
  cursor: pointer;
  float: right;
  margin-left: 0.3rem;
  margin-top: 1.15rem;
}

.addSelectCourse {
  cursor: pointer;
}

.wishlistImage {
  padding-top: 0.2rem;
  z-index: 2;
  position: "absolute";
  top: "30px";
  left: "-5px";
  margin-left: 12rem;
}

.wishlistImage > img {
  width: 20px;
  height: 20px;
  display: inline !important;
}

.plusCircleStyle {
  position: absolute;
}
