.baseLine {
  width: 3em;
  height: 0.25em;
  background-color: #ebebeb;
  border-radius: 2px;
}

.progressLine {
  height: 100%;
}

.countValue {
  font-family: "RakutenRoundSemiBold";
  font-size: 0.8125rem;
}

.currentCount {
  font-family: "RakutenRoundRegular";
}

.minCountValue {
  font-family: "RakutenRoundRegular";
  font-size: 0.8125rem;
}

.progressCircle {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  background-color: #ebebeb;
}

.progressCircle:after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-animation: colorload 2s;
  animation: colorload 2s;
}

.progressCircle .innerCircle {
  font-size: 0.8125rem;
  color: #8b8b8b;
  position: absolute;
  left: 50%;
  top: 50%;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  margin-left: -1.188rem;
  margin-top: -1.188rem;
  text-align: center;
  border-radius: 50%;
  background: #fff;
  z-index: 1;
  font-family: "RakutenRoundSemiBold";
  font-size: 0.8125rem;
}

.progressCircle:last-child {
  margin-left: 0.5rem;
}
.outerContainer {
  cursor: pointer;
}
.circleProgressBar {
  width: 5rem;
  height: 5rem;
  cursor: pointer;
  float: left;
}

.textInside {
  color: #000000;
  font-family: "RakutenRoundRegular";
  font-size: 0.75rem;
  letter-spacing: 0;
  line-height: 0.938rem;
}

.userStatus {
  color: #000000;
  font-family: "RakutenRoundRegular";
  font-size: 0.75rem;
  letter-spacing: 0;
  line-height: 1.063rem;
  max-width: 4.5rem;
  text-align: center;
}

.occupiedSeats {
  color: #000000;
  font-family: "RakutenRoundRegular";
  font-size: 1.063rem;
  letter-spacing: 0;
  line-height: 1.063rem;
}

.batchSize {
  color: #000000;
  font-family: "RakutenRoundSemiBold";
  font-size: 1.063rem;
  letter-spacing: 0;
  line-height: 1.063rem;
}
