@media screen {
  .hero-breadCrumb {
    margin-top: 0rem !important;
  }

  .hero-banner-responsive {
    margin-left: 3.75rem;
    padding-top: 2.375rem;
    margin-Left:-28.25rem;
  }

  .hero-banner-responsive-title {
    padding-top: 8.2rem;
    height: 3.9375rem;
    width: 53.0625rem;
    color: #ffffff;
    font-family: "RakutenRoundRegular";
    font-size: 3.5rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 3.9375rem;
  }

  .hero-banner-responsive-description {
    margin-left: 0.1875rem;
    margin-top: 5rem;
    height: 4.25rem;
    width: 43.5rem;
    color: #ffffff;
    font-family: "RakutenRoundRegular";
    font-size: 1rem;
    letter-spacing: 0;
    line-height: 1.4375rem;
  }
}
@media screen and (max-width: 1400px) {
  .hero-banner-responsive {
    margin-left:-19.25rem;
  }
}
@media screen and (max-width: 1200px) {
  .hero-banner-responsive {
    margin-left:-17.25rem;
  }
}
@media screen and (max-width: 768px) {
  .mobile-hero-banner {
    width: 23.6rem;
    overflow: hidden;
    margin-top: 0 !important;
  }

  .hero-breadCrumb {
    margin-top: 0rem !important;
  }

  .hero-banner-responsive {
    margin-left: 1rem;
    padding-top: 2rem;
    margin-Left:1rem;
  }

  .hero-banner-responsive-title {
    padding-top: 3rem;
    height: 3.9375rem;
    width: 53.0625rem;
    color: #ffffff;
    font-family: "RakutenRoundSemiBold";
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 3.9375rem;
  }

  .hero-banner-responsive-description {
    margin-left: 0.1875rem;
    margin-top: 4rem;
    height: 4.25rem;
    width: 21.5rem;
    color: #ffffff;
    font-family: "RakutenRoundRegular";
    font-size: 1rem;
    letter-spacing: 0;
    line-height: 1.4375rem;
  }
}
