.privilege-secondary-button {
    background-color: #828282;
    border-radius: 0.25rem;
    font-family: "RakutenRoundSemiBold";
    font-size: 1rem;
    line-height: 1.25rem;
    color: #ffffff;
    padding: 0.7rem 1.5rem;
    border: none;
    text-transform: uppercase;
    height: 2.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .privilege-primary-button {
    background-color: #00a0f0;
    border-radius: 0.25rem;
    font-family: "RakutenRoundSemiBold";
    font-size: 1rem;
    line-height: 1.25rem;
    color: #ffffff;
    padding: 0.7rem 1.5rem;
    border: none;
    text-transform: uppercase;
    padding: 0.7rem 1.5rem;
    height: 2.7rem;
    width: 6.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }