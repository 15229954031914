.searchFilterOuter {
  display: "flex";
  flex-wrap: "wrap";
  margin-bottom: 0.538rem;
  margin-top: 2.375rem;
}

.searchFilterInner {
  display: "flex";
  flex-wrap: "wrap";
  margin-bottom: 3.938rem;
}
.customSelectStyle {
  border-radius: 2px;
  border-color: #000;
}
.titleStyle {
  font-family: "RakutenRoundRegular";
  font-size: 0.8125rem;
  font-weight: 300;
  text-transform: uppercase;
}
.optionsStyle {
  font-size: 0.8125rem !important;
  text-transform: uppercase !important;
  color: #000 !important;
  font-weight: lighter !important;
  font-family: "RakutenRoundRegular";
}