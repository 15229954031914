.addNewCourse {
	position: relative;
	left: 0.5rem;
	top: 3rem;
	border-radius: 0.25rem;
}

.legendStyle {
	position: relative;
	top: 3rem;
}

.searchBar {
	top: 3rem;
	/* left: 12rem; */
	position: relative;
}

.pageFilter {
	position: relative;
	top: 3.5rem;
	/* left: 15rem; */
}

.tableSearch {
	position: absolute;
	top: 7.6rem;
	left: 70rem;
	width: 12.5rem;
}

.downloadButtonStyle {
	position: absolute;
	top: 7.6rem;
	left: 66rem;
}

.numberButton {
	border: 2px solid black;
	border-radius: 4px;
	padding: 2px;
}

.wrapText {
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	line-height: 1.58rem;
}

.countryIcon {
	width: 2rem;
	height: 1.25rem;
}

.globeIcon {
	width: 2rem;
	height: 2rem;
}

.subTitle {
	margin: 0;
}

.waitlistLabel {
	word-break: break-word;
	font-size: 0.8125rem;
	display: block;
	width: 3rem;
	float: left;
	top: 3rem;
	position: relative;
}

.waitlistCircle {
	position: relative;
	left: 0rem;
	top: 2.5rem;
	display: flex;
}

.plusIconStyle {
	transform: scale(0.65);
}

.primaryButtonRed {
	background-color: red;
	border-radius: 0.25rem;
	font-family: "RakutenRoundSemiBold";
	font-size: 1rem;
	line-height: 1.25rem;
	color: #ffffff;
	padding: 0.7rem 1.5rem;
	border: none;
	text-transform: uppercase;
	position: relative;
	left: 0.5rem;
	top: 3rem;
}

/* for modal */
.alertText {
	font-family: "RakutenRoundBold";
	font-size: 0.813rem;
	text-align: center;
	color: tomato;
}

.disclaimerMessage {
	font-family: "RakutenRoundRegular";
	font-size: 0.813rem;
	text-align: left;
	color: #333333;
	margin-left: 1.25rem;
	margin-top: 1.063rem;
}

.modal {
	min-width: 66.313rem;
	min-height: 37.125rem;
	align-content: center;
	border-radius: 0.5rem;
}

.functionalBox {
	width: 61.938rem;
	min-height: 22.813rem;
	position: relative;
	margin-left: 1.25rem;
	display: inline-block;
	background-color: #f7f7f7;
	white-space: initial;
	border-radius: 0.5rem;
}

.boldTitle {
	font-family: "RakutenRoundSemiBold";
	font-size: 1.5rem;
	line-height: 3.063rem;
}

.boldSubtitle {
	font-family: "RakutenRoundSemiBold";
	font-size: 1rem;
	line-height: 1.25rem;
}

.title {
	font-family: "RakutenRoundRegular";
	font-size: 1.5rem;
	margin-left: 1.25rem;
	line-height: 3.063rem;
	margin-bottom: -0.625rem;
	flex: 0 0 auto;
	width: auto;
	max-width: 100%;
}

.body {
	border-top: none;
}

.footer {
	border-top: none;
}

.header {
	border-bottom: none;
}

.footerButton {
	margin-top: 2.125rem;
	margin-left: 28.875rem;
	margin-bottom: 2.125rem;
}

.importExcel {
	display: flex;
	justify-content: center;
	margin-top: 1.563rem;
	height: 1.0625rem;
	color: #000000;
	font-family: "RakutenRoundRegular";
	font-size: 0.875rem;
	letter-spacing: 0;
	line-height: 1.0625rem;
	text-align: "center";
}