@import "../../../variables.scss";

.settings-learnings-platform-select .ant-select-selector {
  border-radius: 0px !important;
  height: 2.5rem;
}

.settings-add-platform-button {
  background-color: $primary;
  color: white;
  font-weight: bold;
  height: 2.5rem;
  text-transform: uppercase;
}

.settings-add-platform-button:hover {
  background-color: $primary !important;
  border-color: $primary !important;
  color: white !important;
}

.setting-add-platform {
  border-radius: 0;
  margin: 1rem;
  margin-left: 2rem;
}
