@import "../../../../variables.scss";

@media screen {
  .courseDetailAccordian {
    width: 100px;
  }

  .courseDetailAccordian > .div {
    margin: 1rem;
    border-radius: 0.5rem;
    box-shadow: none;
    border: none !important;
    border-bottom: none !important;
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  .headerContainer {
    display: flex;
    justify-content: flex-start;
  }

  .headerSessionLabel {
    display: flex;
    flex-direction: column;
    width: 19.5rem;
    font-family: "RakutenRoundRegular";
  }

  .accSessionName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 18rem;
  }

  .accBodyContainer {
    font-size: 0.875rem;
    font-family: "RakutenRoundRegular";
    padding: 0rem 1.5rem 1rem;
    visibility: visible;
  }

  .agendaLabel {
    font-family: "RakutenRoundSemiBold";
    text-transform: uppercase;
    padding-top: 1rem;
  }

  .boldText {
    font-family: "RakutenRoundSemiBold";
  }

  .agendaInnerContainer {
    margin-top: 1rem;
  }

  .countryIcon {
    width: 2rem;
    height: 1.25rem;
    margin: 0rem 0.75rem 0 0;
  }

  .globeIcon {
    width: 2rem;
    height: 2rem;
    margin: 0.5rem 0.75rem 0 0;
  }

  .iconReverse {
    transform: rotate(-180deg);
  }

  .sessionPublic {
    background-color: $primary-with-opacity;
    border-radius: 0.9rem;
    width: 476px;

    margin-left: 1.7rem;
    margin-bottom: 24px;
  }

  .sessionPrivate {
    background-color: $secondary-with-opacity;
    width: 476px;
    border-radius: 0.9rem;
    margin-left: 1.7rem;
    margin-bottom: 24px;
  }

  .chevron {
    width: 1rem;
    margin: 0 0 0 0.75rem;
  }

  .noSession {
    background-color: #f7f7f7;
    width: 476px;
    border-radius: 0.5rem;
    margin-left: 2rem;
  }

  .noSessionName {
    line-height: 2.5rem;
  }
}

@media screen and (max-width: 476px) {
  .courseDetailAccordian {
    width: 360px;
    border: none !important;
  }

  .courseDetailAccordian > .div {
    margin: 1rem;
    border-radius: 0.5rem;
    border: none !important;
    border-bottom: none !important;
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  .headerContainer {
    display: flex;
  }

  .headerSessionLabel {
    display: flex;
    flex-direction: column;
    width: 20rem;
    font-family: "RakutenRoundRegular";
  }

  .accSessionName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 11em;
  }

  .accBodyContainer {
    font-size: 0.875rem;
    font-family: "RakutenRoundRegular";
    margin: 0 0rem;
    visibility: visible;
  }

  .agendaLabel {
    font-family: "RakutenRoundSemiBold";
    text-transform: uppercase;
    padding-top: 1rem;
  }

  .boldText {
    font-family: "RakutenRoundSemiBold";
  }

  .agendaInnerContainer {
    margin-top: 1rem;
  }

  .sessionPublic {
    background-color: $primary-with-opacity;
    border-radius: 0.9rem;
    width: 328px;

    margin-left: 1.3rem;
    margin-bottom: 1.7rem;
  }

  .sessionPrivate {
    background-color: $secondary-with-opacity;
    width: 328px;

    margin-left: 1.3rem;
    margin-bottom: 1.7rem;
  }

  .noSession {
    background-color: #f0f0f0;
    width: 328px;
    border-radius: 0.5rem;
    margin-left: 1.5rem;
  }
}
