.searchResults {
  width: 18.25rem;
  position: absolute;
  top: 3rem;
  height: 30rem;
  overflow: hidden;
  overflow-y: auto;
  z-index: 999;
  color: #000000;
  font-family: "RakutenRoundRegular";
  font-size: small;
}

.searchResultsIL {
  background-color: #e5f5fd;
}

.searchResultsUdemy {
  background-color: #f5aaaa;
  margin-left: -54.75rem;
}

.searchResultsEL {
  background-color: #ebebeb;
  margin-left: -18.25rem;
}

.searchResultsLP {
  background-color: #ffffff;
  margin-left: -36.5rem;
}

.searchResultsHide {
  display: none;
}

.searchResults::-webkit-scrollbar {
  display: none;
}

.dataItem {
  margin-bottom: 0rem;
  padding: 1rem;
}

.dataItemDisabled {
  margin-bottom: 0rem;
  padding: 1rem;
}

.dataItem:hover {
  background-color: white;
  font-family: "RakutenRoundBold";
}

.courseHead {
  font-family: "RakutenRoundSemiBold";
  margin-top: 1rem;
  margin-left: 1rem;
  margin-bottom: 0.5rem;
  font-size: small;
}