@import "../../../../variables.scss";
.ec-modal {
  padding: 5rem;
  min-width: 66.313rem;
  min-height: 37.125rem;
  align-content: center;
  font-family: "RakutenRoundSemiBold";
}

.ec-switch-button {
  font-family: "RakutenRoundSemiBold";
  font-size: 1rem;
  line-height: 0.5rem;
  color: $primary;
  background-color: white;
  border: 1px solid $primary;
  border-radius: 3px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 17rem;
  height: 3rem;
}
.ec-modal .ant-modal-body {
  margin-top: 2rem;
}

.ec-form-labelstyle .ant-form-item-label > label {
  font-family: "RakutenRoundRegular";
}

.ec-label-style {
  font-family: "RakutenRoundRegular";
}

.ec-labelinput {
  width: 15rem;
  .ant-input {
    font-family: "RakutenRoundRegular";
  }
}
.ec-category-acronym {
  width: 15rem;
  margin-left: 1rem;
  margin-right: -1rem;
}

.ec-validation {
  padding-top: 2.5rem;
  margin-bottom: 0rem;
}

.ec-error-validation {
  font-family: "RakutenRoundRegular";
  margin-left: 0.4rem;
  margin-top: -2.188rem;
  font-size: small;
  margin-bottom: 1.07rem;
  color: red;
  width: 13rem;
}
.ec-subcategories {
  font-family: "RakutenRoundSemiBold";
  font-size: 1rem;
  margin: 1rem;
  margin-left: 20rem;
}
.ec-modalSelect {
  .ant-select-selection-placeholder {
    font-family: "RakutenRoundSemiBold";
  }
  .ant-select-selection-item {
    font-family: "RakutenRoundSemiBold";
  }
}
.ec-modal-buttons {
  margin-left: 15rem;
}
.ec-cancel-button {
  margin: 1rem;
  background-color: #828282;
  border-radius: 0.25rem;
  font-family: "RakutenRoundSemiBold";
  font-size: 1rem;
  line-height: 1.25rem;
  color: #ffffff;
  padding: 0.7rem 1.5rem;
  height: 2.5rem;
  border: none;
}
.ec-submit-button {
  margin: 1rem;
  background-color: $primary;
  border-radius: 0.25rem;
  font-family: "RakutenRoundSemiBold";
  font-size: 1rem;
  line-height: 1.25rem;
  color: #ffffff;
  padding: 0.7rem 1.5rem;
  border: none;
  height: 2.5rem;
  margin-top: 15px;
}
