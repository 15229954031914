@media screen {
  .scromPlayer-leftArrow {
    box-sizing: border-box;
    height: 1.219rem;
    width: 1.664rem;
    cursor: pointer;
    margin-left: 0.75rem;
  }

  .scromPlayer-title {
    height: 1.563rem;
    color: #333333;
    font-family: "RakutenRoundRegular";
    font-size: 1.25rem;
    font-weight: 600;
    letter-spacing: 0;
  }

  .scromPlayer-container {
    max-width: 82.5rem;
    padding: 0;
    position: relative;
    margin-top: 15rem;
  }

  .scromPlayer-secondContainer {
    width: 82.63625rem !important;
    margin: 0rem;
    padding: 0rem;
  }

  .scromPlayer-componentContainer {
    max-width: 100%;
    background-color: #f7f7f7;
    position: relative;
    margin-bottom: 9.375rem;
    margin-top: 0rem;
  }

  .scromPlayer-buttonStyle {
    margin-left: 1rem;

    display: inline;
    width: auto;
  }
  .scromPlayer-courseNameWrapper {
    display: flex;
    justify-content: space-evenly;
    margin-top: -2rem;
  }
}

@media screen and (max-width: 768px) {
  .scromPlayer-Back {
    position: fixed;
    bottom: 0;
    margin-bottom: 0rem;
    width: 31rem;
    height: 4.5rem;
    background-color: white;
    margin-left: -1rem;
  }
  .scromPlayer-BackButton {
    margin-left: 2rem;
  }
  .scromPlayer-leftArrow {
    box-sizing: border-box;
    height: 1.219rem;
    width: 1.664rem;
    cursor: pointer;
    margin-left: 0.75rem;
  }

  .scromPlayer-title {
    height: 1.563rem;
    color: #333333;
    font-family: "RakutenRoundRegular";
    font-size: 1.25rem;
    font-weight: 600;
    letter-spacing: 0;
  }

  .scromPlayer-container {
    max-width: 82.5rem;
    padding: 0;
    position: relative;
    margin-top: 15rem;
  }

  .scromPlayer-secondContainer {
    width: 82.63625rem !important;
    margin: 0rem;
    padding: 0rem;
  }

  .scromPlayer-componentContainer {
    max-width: 100%;
    background-color: #f7f7f7;
    position: relative;
    margin-bottom: 9.375rem;
    margin-top: 0rem;
  }

  .scromPlayer-buttonStyle {
    margin-left: 1rem;
    display: inline;
    width: 5rem;
  }
  .scromPlayer-courseNameWrapper {
    display: flex;
    justify-content: space-evenly;
    margin-top: 0.5rem;
  }

  .video-player {
    position: relative;
  }

  .player-controls {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    display: none;
  }

  .focused .player-controls {
    display: block;
  }

  .video-title {
    color: white;
    margin: 0;
  }

  .back-button {
    color: white;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
}
