@import "../../../variables.scss";

@media screen {
  .contentEnd {
    justify-content: end;
  }

  .rangeLabel {
    font-size: 14px;
    width: 74px;
    font-family: "RakutenRoundRegular";
  }

  .header {
    margin: 24px auto;
    align-items: center;
  }

  .buttonContainer {
    width: 160px;
    margin-left: 24px;
  }

  .privateSession {
    color: $secondary;
  }

  .publicSession {
    color: #00a0f0;
  }

  .attendance-pageTitle {
    font-family: "RakutenRoundSemiBold";
    font-size: 2.5rem;
  }

  .attendance-header {
    width: 84rem;
    align-items: center;
  }

  .attendance-crumbs {
    margin-top: 2rem;
    width: 82rem;
    margin-bottom: 1rem;
    color: #00a0f0;
    font-family: "RakutenRoundRegular";
  }

  .attendance-buttonContainer {
    display: flex;
    justify-content: center;
    background-color: #00a0f0;
    color: #ffffff;
    font-family: "RakutenRoundRegular";
    width: 10rem;
    border-radius: 5px;
    font-size: 1rem;
    align-items: center;
  }

  .attendance-table {
    width: 83rem;
    margin-top: 1rem;
  }

  .attendance-rangeLabel {
    font-family: "RakutenRoundRegular";
  }

  .Attendance-alert {
    font-family: "RakutenRoundRegular";
    margin-top: 1.6rem;
  }

  .anticon svg {
    vertical-align: baseline;
  }
}

@media screen and (max-width: 1400px) {
  .attendance-crumbs {
    width: 72rem;
  }

  .attendance-header {
    width: 73rem;
  }

  .attendance-table {
    width: 71rem;
  }
}
