@import "../../../variables.scss";

@media screen {
  .attendance-details-list-completed .ant-select-selector {
    color: #47b881 !important;
  }

  .attendance-details-list-partial .ant-select-selector {
    color: $secondary !important;
  }

  .attendance-details-list-excused .ant-select-selector {
    color: #7d00be !important;
  }

  .attendance-details-list-no-show .ant-select-selector {
    color: #ff0000 !important;
  }

  .attendance-details-list-final-completed .ant-select-selector {
    background-color: #47b881 !important;
    color: white !important;
    text-align: start;
  }

  .attendance-details-list-final-partial .ant-select-selector {
    background-color: $secondary !important;
    color: white !important;
    text-align: start;
  }

  .attendance-details-list-final-excused .ant-select-selector {
    background-color: #7d00be !important;
    color: white !important;
    text-align: start;
  }

  .attendance-details-list-final-no-show .ant-select-selector {
    background-color: #ff0000 !important;
    color: white !important;
    text-align: start;
    text-align: start;
  }

  .attendance-details-list-final-completed .ant-select-arrow,
  .attendance-details-list-final-partial .ant-select-arrow,
  .attendance-details-list-final-excused .ant-select-arrow,
  .attendance-details-list-final-no-show .ant-select-arrow {
    color: white !important;
  }

  .AttendanceDetail-crumbs {
    font-family: "RakutenRoundRegular";
    width: 86rem;
    margin-top: 2rem;
  }

  .attendanceDetail-pageTitle {
    font-family: "RakutenRoundSemiBold";
    font-size: 2.5rem;
  }

  .attendanceDetail-header {
    width: 86.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .attendanceDetail-search {
    width: 42rem;
  }

  .attendanceDetai-subTitle {
    margin: 0;
    font-size: 16px;
    width: 80rem;
  }

  .attendanceDetail-table {
    margin-right: 5rem;
    width: 81rem;
  }

  .attendanceDetail-updateButton {
    width: 10rem;
    height: 3rem;
    background-color: $primary;
    color: #ffffff;
    font-family: "RakutenRoundRegular";
    font-size: 1rem;
    font-weight: 600;
  }

  .attendanceDetail-fileButton {
    min-width: 7.6rem;
    height: 2.4rem;
    background-color: #ffffff;
    border: 1px solid $primary;
    color: $primary;
    font-family: "RakutenRoundRegular";
    font-size: 1rem;
    font-weight: 800;
  }
}

@media screen and (max-width: 1400px) {
  .AttendanceDetail-crumbs {
    width: 75rem;
  }

  .attendanceDetail-header {
    width: 75rem;
  }

  .attendanceDetail-search {
    width: 53rem;
  }

  .attendanceDetail-subtitle {
    width: 75rem;
  }

  .attendanceDetail-table {
    width: 70rem;
  }
}
