.outlined {
    color: #00A0F0 ;
    border: 1px solid #00A0F0;
    background: white !important;
}

.outlined:hover {
    color: #00A0F0 !important;
    border: 1px solid #00A0F0;
    background: white !important;
}