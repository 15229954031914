@import "../../../variables.scss";

.il-details-publicContainer {
  min-height: 545px;
  // background: url("../../../assets/icons/courseDetailsBlue.png");
  margin-top: 4rem;
}

.il-details-privateContainer {
  min-height: 545px;
  // background: url("../../../assets/icons/courseDetailsYellow.png");
  margin-top: 4rem;
}

.il-details-innerContainer {
  padding: 0;
  position: relative;
  background-color: transparent;
  padding-left: 0px;
  margin: auto;
  width: 81rem;
}

.statusTextBold {
  font-family: "RakutenRoundSemiBold";
  font-size: 0.875rem;
  margin-top: 5rem;
}

.il-details-coursePageLabel {
  font-size: 1rem;
  text-transform: uppercase;
  padding-left: 0.25rem;
  padding-top: 4.7rem;
  font-family: "RakutenRoundRegular";
}

.il-details-courseIcon {
  padding-top: 5rem;
  padding-left: 0px;
}

.il-details-courseName {
  font-family: "RakutenRoundSemiBold";
  font-size: 46px;
  line-height: 3.7rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  height: 12rem;
  padding-top: 0.8rem;
  width: 40rem;
  overflow-wrap: break-word;
  padding-left: 0px;
}

.il-details-description1 {
  padding-left: 25px;
  padding-top: 1rem;
  margin-left: -1.3rem;
}

.il-details-courseTimeLanguage {
  font-family: "RakutenRoundSemiBold";
  font-size: 0.875rem;
  text-transform: uppercase;
  padding-top: 26px;
  padding-left: 0px;
  min-width: 10rem;
  max-width: 15rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.il-details-courseProvider {
  padding-top: 26px;
}

.il-details-courseProvider-icon {
  position: relative;
  top: -0.1rem;
  left: 1.3rem;
  padding-top: 0px;
  padding-right: 4px;
}

.il-details-courseProvider-name {
  font-family: "RakutenRoundRegular";
  font-size: 0.875rem;
  width: 200px;

  text-transform: uppercase;
  padding-left: 1.5rem;
  padding-top: 0px;
}

.il-details-enrollStatus {
  font-size: 1rem;
  margin-right: -5rem;
  margin-top: 0.5rem;
  margin-left: 1rem;
}

.il-details-textualSection {
  min-height: 15rem;
  margin-bottom: 3rem;
  margin-top: 1rem;
  margin-right: 0;
  margin-left: 0;
}

.il-details-statusContainer {
  width: 25rem;
}

.il-details-noteText {
  color: #ff0000;
  font-size: 0.875rem;
  font-family: "RakutenRoundRegular";
  padding: 0.5 rem 0;
  margin-left: 0.2rem;
  margin-top: 1rem;
}

.il-details-detailLabel {
  font-size: 2rem;
  margin-bottom: 1rem;
  word-wrap: break-word;
  font-family: "RakutenRoundRegular";
}

.il-details-otherContentContainer {
  background-color: white !important;
  min-height: 17rem;
  padding-top: 0px;
}

.il-details-contentPadding {
  word-wrap: break-word;
  padding-right: 1rem;
  width: 615px;
  font-family: "RakutenRoundRegular";
}

.il-details-description {
  top: 450px;
  width: 83rem;
}

.il-details-descriptionText > li {
  white-space: pre-wrap;
  font-family: "RakutenRoundRegular";
}

.il-details-courseImage {
  width: 536px;
  height: 290px;
  object-fit: cover;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  margin-top: 2.5rem;
  position: absolute;
  top: -32rem;
  right: 0.6rem;
}

.il-details-courseImageBelowText {
  height: 5rem;
  width: 33.5rem;
  background: #fff;
  align-items: center;
  justify-content: center;
  line-height: 16px;
  font-size: 24px;
}

.il-details-courseImageBelowText > span {
  color: $primary;

  font-family: "RakutenRoundSemiBold";
}

.il-details-scheduleToggleText {
  color: $primary;
  font-family: "RakutenRoundRegular";
  font-size: 14px;
  cursor: pointer;
  display: flex;
}

.il-details-scheduleRow {
  margin-left: 25.62rem;
}

.il-details-scheduleOuterRow {
  margin-top: -3.25rem;
  background: #fff;
  max-width: 33.5rem;
}

.il-details-iconRotate {
  transform: rotate(-180deg);
}

.il-details-chevronIcon {
  padding: 0 0.5rem;
}

.il-details-ssessionExpand {
  background-color: #fff;
  display: flex;
  height: 4rem;
  width: 33.5rem;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.il-details-noSessionExpand {
  background-color: #fff;
  display: flex;
  height: 8rem;
  width: 33.5rem;
  font-family: "RakutenRoundRegular";
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 2rem;
  font-size: 0.875rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.il-details-privateContentContainer {
  min-height: 15rem;
  background-color: $secondary-with-opacity;
}

.il-details-publicContentContainer {
  min-height: 15rem;
  background-color: $primary-with-opacity;
}

.il-details-bannerContainer {
  display: flex;
  flex-direction: row;
  margin-left: -20px;
  justify-content: center;
}

.il-details-bannerContent {
  display: flex;
  flex-direction: column;
  width: 18rem;
  text-align: center;
  margin: 4rem 0 0 0;
}

.il-details-accord {
  height: 450px;
  overflow: visible;
}

.il-details-bannerLabel {
  font-size: 1.125rem;
  font-family: "RakutenRoundregular";
}

.il-details-bannerDescription {
  font-size: 1.875rem;
  width: 320px;
  height: 80px;
  font-family: "RakutenRoundRegular";
}

.il-details-bannerPrivateHorizontalLine {
  width: 5.25rem;
  height: 0;
  border: 0.0625rem solid $secondary;
  margin: 7rem 3rem;
}

.il-details-bannerPublicHorizontalLine {
  width: 5.25rem;
  height: 0;
  border: 0.0625rem solid $primary;
  margin: 7rem 3rem;
}

.il-details-enrollContainer {
  height: 10rem;
  margin-top: 55px;
}

// .il-details-primaryButton {
//   width: 12.5rem;
//   height: 3.125rem;
//   margin-right: 1rem;
// }

.il-details-unenrollButton {
  background-color: #828282;
}

.il-details-statusTextBold {
  font-family: "RakutenRoundSemiBold";
  font-size: 0.875rem;
  margin-top: 5rem;
}

.il-details-quoteContainer {
  margin-bottom: 5rem;
  background-color: #fff;
  padding: 5rem 0;
}

.il-details-modalFooterContent {
  color: #ff0000;
  font-size: 0.75rem;
  margin: 2rem 0 0 2rem;
}

.il-details-filler {
  height: 3rem;
  background-color: #fff;
}

.il-details-secondContainer {
  width: 82.63625rem !important;
  margin: 0rem;
  padding: 0rem;
  padding-bottom: 7.5rem;
}

.il-details-container {
  max-width: 82.5rem;
  background-color: white;
  padding: 0;
  position: relative;
  margin-top: 7rem;
}

.il-details-alertContainer {
  top: 4rem;
  width: 83rem;
  margin: auto;
}

.il-details-descriptionButton {
  margin-top: 2rem;
  background-color: transparent;
  border: none;
  color: $primary;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-family: "RakutenRoundRegular";
}

.il-details-showMore {
  overflow: hidden;
  word-wrap: break-word;
  height: auto;
  width: 98%;
}

.il-details-courseImageBelowPanel {
  background-color: white;
  width: 536px;
  max-height: 48rem;
  overflow: scroll;
  overflow-x: hidden;
}

.il-details-showLess {
  padding-bottom: 1rem;
  max-height: 10rem;
  overflow: hidden;
  word-wrap: break-word;
  -webkit-mask-image: linear-gradient(#fff, #fff, rgba(255, 255, 255, 0.2));
  position: relative;
  width: 98%;
}

.il-details-contentPadding a {
  text-decoration: underline !important;
  color: $primary !important;
}

.il-details-breadCrumbStyle {
  padding: 3.5rem;
  margin-left: 1rem;
}

.il-details-rowSize {
  height: 330px;
}

.il-details-courseObjectives {
  margin-top: 6rem;
  width: 84rem;
}

.il-detail-outlineButton {
  margin-top: -3rem;
}

.il-details-accordionContent {
  bottom: 9rem;
  height: auto;
  overflow: visible;
  z-index: 300;
  right: -6.7rem;
  box-shadow: 0 0.125rem 0.25rem 0 #d1d1d1;
}

.il-details-accordion {
  height: 40px;
}

.il-course-detail-breadcrumb {
  padding-top: 3rem;
  font-family: "RakutenRoundRegular";
}

.il-course-detail-breadcrumb {
  .ant-breadcrumb li:last-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 68rem;
    display: block;
  }
}

.il-course-detail-breadcrumb a {
  color: $primary;
}

@media screen and (min-width: 1790px) {
  .il-details-bannerContainer {
    margin-left: -20px;
  }
}

@media screen and (max-width: 1300px) {
  .il-details-bannerPrivateHorizontalLine {
    width: 3.25rem;
    margin: 7rem 2rem;
  }

  .il-details-bannerPublicHorizontalLine {
    width: 3.25rem;
    margin: 7rem 2rem;
  }

  .il-details-bannerContainer {
    margin-left: 0px;
    margin-right: 122px;
  }
}

@media screen and (max-width: 1400px) {
  .il-details-innerContainer {
    padding-left: 3rem;
  }

  .il-details-courseName {
    width: 36rem;
  }

  .il-details-accordionContent {
    right: -3.7rem;
  }
}

@media screen and (max-width: 1230px) {
  .il-details-courseImage {
    position: absolute;
    right: 6.5rem;
  }

  .il-details-accordionContent {
    right: -0.8rem;
  }
}

@media screen and (max-width: 1230px) {
  .il-details-courseImage {
    position: absolute;
    right: 6.5rem;
  }

  .il-details-accordionContent {
    right: 2.2rem;
  }
}

@media screen and (max-width: 476px) {
  .il-course-detail-breadcrumb {
    padding: 1rem;
    font-family: "RakutenRoundRegular";
  }

  .il-course-detail-breadcrumb a {
    color: $primary;
  }

  .il-secondContainer {
    width: 20.63625rem !important;
    margin: 0rem;
    padding: 0rem;
    padding-bottom: 0.5rem;
  }

  .il-details-publicContainer {
    min-height: 360px;
    // background: url("../../../assets/icons/courseDetailsBlue.png") no-repeat;
    margin-top: 0rem;
  }

  .il-details-privateContainer {
    min-height: 360px;
    // background: url("../../../assets/icons/courseDetailsYellow.png") no-repeat;
    margin-top: 0rem;
  }

  .il-details-innerContainer {
    padding: 0;
    position: relative;
    background-color: transparent;
    width: 23.5rem;
  }

  .il-details-coursePageLabel {
    font-size: 14px;
    text-transform: uppercase;
    padding-left: 0.25rem;

    padding-top: 31px;
    font-family: "RakutenRoundRegular";
  }

  .il-details-courseIcon {
    padding-top: 36px;
    padding-left: 20px;
  }

  .il-details-courseName {
    font-family: "RakutenRoundSemiBold";
    font-size: 28px;
    font-weight: 600;
    line-height: 2rem;
    text-overflow: ellipsis;
    overflow-wrap: anywhere;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    padding-left: 20px;
    height: 80px;
    padding-top: 0.8rem;
    width: 22rem;
  }

  .il-details-courseTimeLanguage {
    font-family: "RakutenRoundSemiBold";
    font-size: 0.875rem;
    text-transform: uppercase;
    padding-top: 0.9rem;
    padding-left: 20px;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .il-details-courseProvider {
    padding-top: 15px;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 40px;
    white-space: nowrap;
  }

  .il-details-courseProvider-icon {
    position: relative;
    top: 0rem;
    left: 1.7rem;
    padding-top: 0px;
  }

  .il-details-courseProvider-name {
    font-family: "RakutenRoundRegular";
    font-size: 0.875rem;
    text-transform: uppercase;
    padding-left: 2rem;
    padding-top: 0rem;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 30px;
    white-space: nowrap;
  }

  .il-details-enrollStatus {
    font-size: 1rem;
    margin-right: 1rem;
  }

  .il-details-noteText {
    color: #ff0000;
    font-size: 10px;
    font-family: "RakutenRoundRegular";
    padding: 0.5rem;
    margin-left: 13px;
    margin-bottom: 0px;
    top: 70px;
  }

  .il-details-detailLabel {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    word-wrap: break-word;
    font-family: "RakutenRoundRegular";
  }

  .il-details-contentPadding {
    word-wrap: break-word;
    padding-right: 1rem;
    width: 328px;
  }

  .il-details-courseImage {
    height: 204px;
    object-fit: cover;
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
    margin-top: 10px;
    position: absolute;
    left: -25rem;
    top: 11rem;
    right: 0;
  }

  .il-details-courseImageBelowText {
    font-size: 18px;
    font-weight: 600;
    font-family: "RakutenRoundRegular";
    width: 310px;
    height: 55px;
    object-fit: cover;
    background: none;
    line-height: 16px;
  }

  .il-details-courseImageBelowText > span {
    color: $primary;
    font-size: 1.5rem;
    font-family: "RakutenRoundSemiBold";
  }

  .il-details-scheduleToggleText {
    color: $primary;
    font-family: "RakutenRoundRegular";
    font-size: 0.875rem;
    cursor: pointer;
  }

  .il-details-scheduleRow {
    margin-left: 25.62rem;
  }

  .il-details-scheduleOuterRow {
    margin-top: -3.25rem;
    background: #fff;
    max-width: 33.5rem;
  }

  .il-details-iconRotate {
    transform: rotate(-180deg);
  }

  .il-details-chevronIcon {
    padding: 0 0.5rem;
  }

  .il-details-ssessionExpand {
    background-color: transparent;
    display: flex;
    height: 6rem;
    width: 23rem;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    margin-top: -3rem;
  }

  .il-details-textualSection {
    min-height: 15rem;
    margin-bottom: 3rem;
    margin-top: 1rem;
  }

  .il-details-noSessionExpand {
    background-color: transparent;
    display: flex;
    height: 70px;
    width: 362px;
    font-family: "RakutenRoundRegular";
    justify-content: center;
    align-items: center;
    text-align: left;
    padding: 0 1.5rem;
    margin: 1rem 1rem;
    font-size: 0.875rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .il-details-privateContentContainer {
    min-height: 15rem;
    background-color: $secondary-with-opacity;
  }

  .il-details-publicContentContainer {
    min-height: 15rem;
    background-color: #e5f5fd;
  }

  .il-details-bannerContainer {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    justify-content: center;
  }

  .il-details-bannerContent {
    display: flex;
    flex-direction: column;
    width: 18rem;
    text-align: center;
    margin: 2rem 2.3rem 2rem;
  }

  .il-details-bannerLabel {
    font-size: 1.125rem;

    font-family: "RakutenRoundregular";
  }

  .il-details-bannerDescription {
    font-size: 18px;
    width: 189px;
    height: 46px;
    margin-left: 46px;
    font-family: "RakutenRoundRegular";
  }

  .il-details-bannerPrivateHorizontalLine {
    width: 0;
    height: 40px;
    border: 0.0625rem solid $secondary;
    margin: 1rem 11rem;
  }

  .il-details-bannerPublicHorizontalLine {
    width: 0;
    height: 40px;
    border: 0.0625rem solid $primary;
    margin: 0.5rem 11rem 0.5rem;
  }

  .il-details-statusContainer {
    display: none;
  }

  // .il-details-primaryButton {
  //   width: 12.5rem;
  //   height: 3.125rem;
  //   font-size: 1.125rem;
  //   margin-right: 1rem;
  // }
  .il-details-publicButton {
    background-color: $primary;
  }

  .il-details-privateButton {
    background-color: $secondary;
  }

  .il-details-unenrollButton {
    background-color: #828282;
  }

  .il-details-statusTextBold {
    font-family: "RakutenRoundSemiBold";
    font-size: 0.875rem;
    margin-top: 5rem;
  }

  .il-details-quoteContainer {
    margin-bottom: 5rem;
    background-color: #fff;
    padding: 5rem 0;
  }

  .il-details-modalFooterContent {
    color: #ff0000;
    font-size: 0.75rem;
    margin: 2rem 0 0 2rem;
  }

  .il-details-filler {
    height: 3rem;
    background-color: #fff;
  }

  .il-details-secondContainer {
    width: 23.5rem !important;
    margin: 0rem;
    padding: 0rem;
    padding-bottom: 0rem;
  }

  .il-details-container {
    max-width: 82.5rem;
    background-color: white;
    padding: 0;
    position: relative;
    margin-top: 7rem;
  }

  .il-details-alertContainer {
    top: 4rem;
    width: 0;
    margin: 0;
  }

  .il-details-descriptionButton {
    margin-top: 2rem;
    background-color: transparent;
    border: none;
    color: $primary;
    text-transform: uppercase;
    font-size: 12px;
  }

  .il-details-showMore {
    overflow: hidden;
    word-wrap: break-word;
    // height: auto;
    width: 98%;
  }

  .il-details-showLess {
    padding-bottom: 1rem;
    max-height: 10rem;
    overflow: hidden;
    word-wrap: break-word;
    -webkit-mask-image: linear-gradient(#fff, #fff, rgba(255, 255, 255, 0.2));
    position: relative;
    width: 98%;
  }

  .il-details-contentPadding a {
    text-decoration: underline !important;
    color: $primary !important;
  }

  .il-details-breadCrumbStyle {
    padding: 3.5rem;
  }

  .il-details-rowSize {
    height: 200px;
  }

  .il-details-description {
    top: 0px;
    padding-top: 3rem;
  }

  .il-details-description1 {
    overflow: visible;
    height: auto;
    padding-left: 25px;
    padding-top: 0rem;
    margin-left: 0;
  }

  .il-details-courseObjectives {
    margin-top: 3rem;
    width: 30rem;
  }

  .il-details-enrollContainer {
    position: fixed;
    height: 65px;
    width: 100%;
    bottom: 0px;
    background-color: white;
    overflow: hidden;
    z-index: 1000;
  }

  .il-detail-outlineButton {
    margin-top: 0px;
    margin-left: 5px;
  }

  .il-details-courseImageBelowPanel {
    background-color: transparent;
    overflow: visible;
    width: 0;
    height: auto;
  }

  .il-details-accordionContent {
    overflow: visible;
    height: auto;
    top: 1rem;
    right: 0rem;
    bottom: 0px;
    left: 0px;
    border: none;
    box-shadow: none;
  }

  .il-details-accordion {
    // height: auto;
    overflow: none;
    z-index: 0;
  }
}
