@media screen {
  .Myapproval-container {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Myapproval-pageTitle {
    font-family: "RakutenRoundSemiBold";
    font-size: 2.5rem;

    .ant-typography {
      word-break: none;
    }
  }

  .myapproval-filterPage {
    padding-top: 1.5rem;
    padding-left: 5rem;
  }

  .myapproval-alert {
    font-family: "RakutenRoundRegular";
    margin-top: 1.3rem;
  }

  .myapproval-Table {
    width: 83rem;
    margin-right: auto;
    margin-left: auto;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .Myapproval-container1 {
    width: 60rem;
    overflow: scroll;
    padding-left: 25rem
  }

  .Myapproval-container {
    margin-top: 0rem;
  }
}