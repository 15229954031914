
.react-dual-listbox{
    width:57.313rem;
    height: 17.625rem;
}

.react-dual-listbox select{
    background-color: #FFFFFF;
    border-radius: 8px;
    border-color: #FFFFFF;
}

.react-dual-listbox button{
    border-radius: 50%;
    background-color: #d1d1d1;
    border-color: #d1d1d1;
    width: 2.375rem; 
    height: 2.375rem;
    margin-left: 2.156rem;
    margin-right: 2.156rem;
    
}

.rdl-move-all{
    display: none;
}

.rdl-control-container{
    font-family: "RakutenRoundRegular";
    font-size: 0.875rem;
    line-height: 1.063rem;

}
  
.centreIcon{
    align-content: center;
    margin-left:-0.25rem;
    margin-top:0.25rem;
}

option {
    font-weight: normal;
    display: block;
    white-space: nowrap;
    min-height: 1.2rem;
    padding: 0.5rem 0.125rem 0.125rem 0.5rem;
    color: black;
}

.rdl-control {
    display: block;
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 0.5rem 0.75rem;
    width: 100%;
    color: #0000;
    font-size: 0.875rem;
}

.rdl-actions-right{
    margin-bottom: 1.063rem;
}