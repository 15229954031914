.alertText {
  font-family: "RakutenRoundRegular";
  font-size: 0.813rem;
  text-align: center;
  color: tomato;
  margin-top: 1rem;
}

.modal {
  margin-left: -18.75rem;
  min-width: 66.313rem;
  min-height: 37.125rem;
  /* max-height: 594px; */
  align-content: center;
  border-radius: 0.5rem;
}

.functionalBox {
  width: 61.938rem;
  min-height: 22.813rem;
  position: relative;
  margin-left: 1.25rem;
  display: inline-block;
  background-color: #f7f7f7;
  white-space: initial;
  border-radius: 0.5rem;
}

.boldTitle {
  font-family: "RakutenRoundSemiBold";
  font-size: 1.5rem;
  color: black;
  line-height: 3.063rem;
}

.boldSubtitle {
  font-family: "RakutenRoundSemiBold";
  font-size: 1rem;
  line-height: 1.25rem;
}

.title {
  font-family: "RakutenRoundRegular";
  color: black;
  font-size: 1.5rem;
  margin-left: 1.25rem;
  line-height: 3.063rem;
  margin-bottom: -0.625rem;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  float: left;
}

.body {
  border-top: none;
}

.cancelButton {
  margin-right: 1.5rem;
}

.footer {
  border-top: none;
}

.header {
  border-bottom: none;
}

.footerButton {
  margin-top: 2.125rem;
  margin-left: 45%;
  margin-bottom: 2.125rem;
}

.textBox {
  padding-top: 0.813rem;
  text-align: center;
}

.leftBox {
  min-width: 25.25rem;
  min-height: 15.938rem;
  max-width: 25.25rem;
  margin-top: 0.5rem;
  display: inline-block;
  background-color: white;
  white-space: initial;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
}

.rightBox {
  position: relative;
  min-width: 25.25rem;
  min-height: 15.938rem;
  max-width: 25.25rem;
  margin-top: 0.5rem;
  display: inline-block;
  background-color: white;
  white-space: initial;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
}

.innerBox {
  width: 56.875rem;
  min-height: 17.5rem;
  margin-left: 2.438rem;
  margin-top: 1.625rem;
  margin-bottom: 0.5rem;
}

.changeButton {
  font-family: "RakutenRoundSemiBold";
  font-size: 1rem;
  line-height: 0.5rem;
  color: #00a0f0;
  background-color: white;
  border: 1px solid #00a0f0;
  text-transform: uppercase;
  border-radius: 3px;
  padding-top: 1rem;
  position: relative;
  bottom: 1rem;
  right: 2rem;
  padding-bottom: 1rem;
  width: 312px;
  height: 54px;
}

.switchModal {
  margin-left: 17.094rem;
}

.circleBox {
  border-radius: 50%;
  background-color: white;
  margin-left: 1.563rem;
  background: white;
  width: 2.75rem;
  height: 2.75rem;
  margin-right: 1.875rem;
  margin-top: 6.625rem;
  text-align: center;
  padding-top: 0.563rem;
}

.buttonBox {
  box-sizing: border-box;
  height: 6.563rem;
  width: 22.188rem;
  border: 1px dashed #9c9c9c;
  border-radius: 4px;
  background-color: #f7f7f7;
  margin-left: 1.531rem;
  margin-top: 2.219rem;
}

.footText {
  font-family: "RakutenRoundRegular";
  font-size: 0.75rem;
  text-align: center;
}

.fileBox {
  margin-left: 1.434rem;
  margin-top: 1.219rem;
  width: 22.125rem;
  min-height: 2.75rem;
  border-radius: 4px;
  background-color: #ebebeb;
}

.fileText {
  height: 1.063rem;
  width: 16.938rem;
  color: #000000;
  font-family: "RakutenRoundRegular";
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.063rem;
  margin-left: 2.188rem;
  margin-top: 0.875rem;
  position: absolute;
}

.textIcon {
  height: 1.25rem;
  width: 1.25rem;
  margin-top: 0.75rem;
  margin-left: 0.375rem;
  position: absolute;
}

.leftTitle {
  font-family: "RakutenRoundSemiBold";
  color: #000000;
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.063rem;
  margin-bottom: 0.75rem;
  padding-left: 0;
}

.rightTitle {
  font-family: "RakutenRoundSemiBold";
  color: #000000;
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.063rem;
  padding-left: 7.875rem;
}

.infoUser1 {
  font-size: x-small;
  margin-left: 82%;
}

.infoUser2 {
  font-size: x-small;
  margin-left: 1rem;
}