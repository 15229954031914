.courseCard {
  border-radius: 0.5rem;
  border-color: #f7f7f7;
  background-color: #f7f7f7;
  max-width: 19.5rem;
  min-height: 22.25rem;
  max-height: 22.25rem;
  min-width: 19.5rem;
  box-shadow: 0 0.125rem 0.25rem 0 #d1d1d1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card:hover {
  box-shadow: 0 0.125rem 0.75rem 0 #d1d1d1;
}

.link {
  border-radius: 50%;
  background-color: #00a0f0;
  width: 8.375rem;
  height: 8.375rem;
  color: white;
}

.text {
  margin-top: 3.25rem;
  color: white;
  margin-left: 0.625rem;
}

.arrow {
  margin-top: 0.313rem;
  margin-left: 3.375rem;
  align-self: center;
}
