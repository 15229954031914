.ant-card-body {
  padding: 0;
  height: 105px;
  width: 100%;
}

@media screen {
  .learningpath-card {
    max-width: 100%;
    height: 105px;
    display: flex;
    background: #f7f7f7;
    padding: 0;
    border: none;
    margin-left: 2%;
    margin-right: 2%;
  }

  .learningpath-cardDetails {
    max-width: 348px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    padding: 24px 0 24px 0;
    justify-content: space-between;
  }

  .learningpath-cardImage {
    padding: 20px 24px 20px 24px;
  }

  .learningpath-cardTitle {
    font-family: "RakutenRoundRegular";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    padding: 0;
    margin: 0;
  }

  .learningpath-detailItem {
    font-family: "RakutenRoundRegular";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    margin-right: 8px;
  }

  .learningpath-detailText {
    color: #686868;
    margin-right: 24px;
  }

  .learningpath-bookMark {
    margin-left: auto;
    padding-top: 24px;
    padding-right: 32px;
  }
}

@media screen and (max-width: 425px) {
  .learningpath-card {
    /* max-width: 648px; */
    width: 21rem;
    height: 105px;
    display: flex;
    background: #f7f7f7;
    padding: 0;
  }

  .learningpath-cardDetails {
    max-width: 14rem;
    min-width: 8rem;
    display: flex;
    flex-direction: column;
    padding: 1rem 0px 2rem 1rem;
    justify-content: space-between;
  }

  .learningpath-cardImage {
    padding: 16px 0px 0px 8px;
  }

  .learningpath-cardTitle {
    font-family: "RakutenRoundSemibold";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 30px;
    padding: 0;
    margin: 0;
  }

  .learningpath-detailItem {
    font-family: "RakutenRoundRegular";
    font-style: normal;
    font-weight: 600;
    font-size: 0.7rem;
    line-height: 22px;
    margin-right: 4px;
  }

  .learningpath-detailText {
    color: #686868;
    margin-right: 10px;
  }

  .learningpath-bookMark {
    margin-left: auto;
    padding-top: 1rem;
    padding-right: 8px;
  }
}

.grey {
  background: #f7f7f7;
}

.white {
  background-color: #ffffff;
}

.learningpath-cardContainer {
  display: flex;
  background-color: #dcdbdb70;
  border-radius: 0.5rem;
  border-color: #f7f7f7;
  /* border: 0.063rem solid #ced4da, */
}

.learningpath-card-image {
  border-radius: 50%;
  height: 64px;
  width: 64px;
}

.learningpath-courseDetailItems {
  display: flex;
  margin-top: 6px;
}

.learningpath-detailCount {
  color: #000000;
}

.learningpath-bookmark-icon {
  font-size: 24px;
}