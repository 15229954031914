@import "../../../variables.scss";
.enrolledusers-header {
  width: 83rem;
  margin-left: auto;
  margin-right: auto;
}

.enrolledusers-breadcrumb {
  width: 83rem;
}

.sessionuserlist-container {
  margin-top: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.enrolledusers-breadcrumb {
  font-family: "RakutenRoundRegular";
}

.enrolledusers-pageCount {
  font-family: "RakutenRoundRegular";
  font-size: 0.875rem;
  margin: 0 0.5rem;
}

.enrolledusers-togglefilter {
  margin-right: 1.4rem;
}

.enrolledusers-table {
  width: 83rem;
  margin-bottom: 3rem;
}

.enrolledusers-page-title {
  font-family: "RakutenRoundSemiBold";
  font-size: 2.5rem;
  margin-top: 1rem;
  margin-bottom: 3rem;
  width: max-content;
}

.enrolledusers-pageCount {
  margin-left: 0.5rem;
  margin-top: -4.5rem;
  font-size: 0.87rem;
  font-family: "RakutenRoundRegular";
}

.user-button {
  width: 8rem;
  font-family: "RakutenRoundSemiBold";
  font-size: 1rem;
  line-height: 0.5rem;
  color: $primary;
  background-color: white;
  border: 1px solid $primary;
  text-transform: uppercase;
  border-radius: 3px;
  height: 2.7rem;
}

.addNewCourseButton {
  background-color: $primary;
  font-family: "RakutenRoundSemiBold";
  color: #ffffff;
  border-radius: 0.25rem;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
  text-transform: uppercase;
  height: 2.85rem;
  min-width: 11rem;
}

.sessionUserList-tabs {
  width: 15rem;

  .ant-tabs-tab-btn {
    font-family: "RakutenRoundRegular";
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  .ant-tabs-nav .ant-tabs-nav-wrap {
    overflow: visible;
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $primary !important;
  }

  .ant-tabs-tab .ant-tabs-tab-btn:hover {
    color: $primary !important;
  }

  .ant-tabs-nav-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .ant-tabs-tab {
    width: 45%;
    justify-content: center;
  }

  .ant-tabs-ink-bar {
    width: 5rem;
    border-bottom: 3px solid $primary;
    border-radius: 5px;
  }
}

@media screen and (max-width: 1400px) {
  .enrolledusers-header {
    width: 71rem;
  }

  .enrolledusers-breadcrumb {
    width: 72rem;
  }

  .enrolledusers-table {
    width: 71rem;
  }

  .enrolledusers-remdel-buttons {
    margin-left: 2.3rem;
  }
}
