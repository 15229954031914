.schedule-details-title {
  font-family: "RakutenRoundSemiBold";
}

.schedule-details-value {
  font-family: "RakutenRoundRegular";
}

.trainer-details {
  margin-bottom: 8px;
  font-family: "RakutenRoundRegular";
}
