@import "../../variables.scss";

.tab-label {
  font-family: "RakutenRoundRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  margin-right: 10px;
}

.tabbed-filter-container {
  .ant-tabs-tab-btn {
    font-family: "RakutenRoundRegular";
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  .ant-tabs-tab:hover {
    color: $primary !important;
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $primary !important;
  }

  .ant-tabs-tab .ant-tabs-tab-btn:hover {
    color: $primary !important;
  }

  .ant-tabs-nav-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .ant-tabs-tab {
    width: 25%;
    justify-content: center;
  }

  .ant-tabs-ink-bar {
    border-bottom: 3px solid $primary;
    border-radius: 5px;
  }
}