@import "../../variables.scss";
.btn-link {
  color: #000000;
  border: none;
  box-shadow: none;
  font-size: 0.875rem;
}

.btn-link:hover {
  text-decoration: none;
  color: #000000;
}

.admin-toggle-filter-icon-color {
  color: $primary;
}

.btn-link.active {
  color: $primary;
  text-decoration: none;
  background-color: transparent;
}
.btn.focus {
  box-shadow: none;
}
