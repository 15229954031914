.baseLine {
	width: 3em;
	height: 0.25em;
	background-color: #ebebeb;
	border-radius: 2px;
}

.progressLine {
	height: 100%;
}

.countValue {
	font-family: "RakutenRoundSemiBold";
	font-size: 1.125rem;
}

.currentCount {
	font-family: "RakutenRoundRegular";
}

.minCountValue {
	font-family: "RakutenRoundRegular";
	font-size: 0.8125rem;
}
.outerContainer {
	cursor: pointer;
}
.iconStyle {
	padding: 0.75em 0.25rem;
}
.container {
	display: flex;
	justify-content: center;
}
