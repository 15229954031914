.header {
	border-bottom: none;
}

.modalTitle {
	font-family: "RakutenRoundRegular";
	font-size: 1.5rem;
	color: black;
}

.modalTitleBold {
	font-family: "RakutenRoundSemiBold";
	font-size: 1.5rem;
	color: black;
}

.modalHeader {
	display: flex;
	justify-content: space-between;
	margin-left: 1rem;
}
.sessionTitle {
	display: flex;
	justify-content: center;
}
.boldText {
	font-family: "RakutenRoundSemiBold";
}
.outerContainer {
	background: #f7f7f7;
	padding: 1rem;
}
