@import "../../../variables.scss";

@media screen {
  .selectcourses-container {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .selectcourses-pagetitle {
    font-family: "RakutenRoundSemiBold";
    font-size: 2.5rem;

    .ant-typography {
      word-break: none;
    }
  }

  .selectcourse-toggle {
    display: flex;
    flex-direction: row;
    gap: 7rem;
    margin-left: 28rem;
  }

  .selectcourses-crumbs {
    margin-top: 2rem;
    width: 82rem;
    margin-bottom: 1rem;
    color: $primary;
    font-family: "RakutenRoundRegular";
  }

  .selectcourses-tabs {

    .ant-tabs-tab-btn {
      font-family: "RakutenRoundRegular";
      font-size: 1rem;
      font-weight: 600;
      display: flex;
      align-items: center;
    }

    .ant-tabs-tab:hover {
      color: $primary !important;
    }

    .ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $primary !important;
    }

    .ant-tabs-tab .ant-tabs-tab-btn:hover {
      color: $primary !important;
    }

    .ant-tabs-tab {
      margin-right: 16px !important;
    }

    .ant-tabs-tab:last-child {
      margin-right: 0px !important;
    }

    .ant-tabs-nav-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .ant-tabs-tab {
      width: 50%;
      justify-content: center;
    }

    .ant-tabs-ink-bar {
      border-bottom: 3px solid $primary;
      border-radius: 5px;
      width: 7rem !important;
    }
  }

  .recommendto-buttonstyle {
    margin-left: 70rem;
  }

  .recommendto-button {
    background-color: $primary;
    border-radius: 0rem;
    font-family: "RakutenRoundSemiBold";
    font-size: 1rem;
    line-height: 1.25rem;
    color: #ffffff;
    padding: 0.7rem 1.5rem;
    border: none;
    text-transform: uppercase;
    min-width: 11rem;
    height: 48px;
    margin-top: 0;
  }

  .selectCourses-infinitescroll {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: unset;
  }

  .course-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .selectcourses-crumbs {
    margin-top: 2rem;
    width: 23rem;
  }

  .selectcourses-container {
    margin-top: -2rem;
    width: 23rem;
    overflow: hidden;
  }

  .recommendto-buttonstyle {
    margin-left: 0rem;
  }

  .course-list {
    width: 23rem;
    overflow: hidden;
  }
  .selectcourse-toggle {
    display: flex;
    flex-direction: column;
    gap: 0rem;
    margin-left: 0rem;
  }

  .selectcourses-search {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-left: 1.5rem;
  }

  .lp-filter-container {
    width: 30rem;
    overflow: scroll;
    margin-left: 32rem;
  }
}