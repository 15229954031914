.header {
  border-bottom: none;
}

.modalTitle {
  font-family: "RakutenRoundRegular";
  font-size: 1.5rem;
  color: black;
}

.modalTitleBold {
  font-family: "RakutenRoundSemiBold";
  font-size: 1.5rem;
  color: black;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  margin-left: 1rem;
}

.searchIcon {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  z-index: 9999;
  background: url("./../../assets/icons/searchIcon.svg") no-repeat;
  width: 2rem;
  height: 2rem;
}

.searchInput {
  font-family: "RakutenRoundRegular";
  font-size: 0.8125rem;
  width: 14rem;
  height: 2.75rem;
  border-radius: 0.25rem;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
  display: inline-flex;
}

.modalWidth {
  width: 90%;
  max-width: 66.3125rem;
  margin-top: 100px;
}

.imageContainer {
  margin-top: 1.5rem;
  margin-left: 1rem;
  min-height: 25rem;
}

.left {
  float: left;
}

.right {
  float: right;
}

.closeButton {
  margin-left: 1.25rem;
}

.footer {
  margin-top: 1.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3.125rem;
}

.variable-width .slick-slide p {
  background: blue;
  height: 6.25rem;
  color: #fff;
  margin: 5px;
  line-height: 6.25rem;
  text-align: center;
}
.center .slick-center h3 {
  color: #e67e22;
  opacity: 1;
  transform: scale(1.08);
}
.center h3 {
  opacity: 0.8;
  transition: all 300ms ease;
}
.content {
  padding: 1.25rem;
  margin: auto;
  width: 90%;
}
.slick-slide .image {
  padding: 0.625rem;
}
.slick-slide img {
  border: 0.3125rem solid #fff;
  display: block;
  margin: auto;
}
.slick-slide img.slick-loading {
  border: 0;
}
.slick-slider {
  margin: 1.875rem auto 3.125rem;
}
.slick-dots {
  margin-left: 0;
}
.slick-thumb {
  bottom: -2.8125rem;
}
.slick-thumb li {
  width: 3.75rem;
  height: 2.8125rem;
}
.slick-thumb li img {
  filter: grayscale(100%);
}
.slick-thumb li.slick-active img {
  filter: grayscale(0%);
}
.slick-prev {
  z-index: 999;
  margin-left: 3.125rem;
}
.slick-next {
  z-index: 999;
  margin-right: 3.125rem;
}
@media (max-width: 48rem) {
  h3 {
    font-size: 1.5rem;
  }
  .center {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }
  .center .slick-center h3 {
    color: #e67e22;
    opacity: 1;
    transform: scale(1);
  }
  .center h3 {
    opacity: 0.8;
    transform: scale(0.95);
    transition: all 300ms ease;
  }
}
.slick-vertical .slick-slide {
  height: 11.25rem;
}
.slick-arrow {
  background-color: grey;
}
.slick-arrow:hover {
  background-color: grey;
}
.slick-dots li.slick-active div:before {
  color: orange !important;
}
