.addNewCourse {
  position: relative;
  left: -1rem;
  top: 3rem;
}
.legendStyle {
  position: relative;
  top: 3rem;
}
.searchBar {
  top: 3rem;
  position: relative;
}

.pageFilter {
  position: relative;
  top: 3.5rem;
}
.tableSearch {
  position: absolute;
  top: 7.6rem;
  left: 70rem;
  width: 12.5rem;
}
.downloadButtonStyle {
  position: absolute;
  top: 7.6rem;
  left: 66rem;
}
.numberButton {
  border: 2px solid black;
  border-radius: 4px;
  padding: 2px;
}
.wrapText {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-height: 1.58rem;
}
.subTitle {
  margin: 0;
}
.rowPadding {
  padding: 0.5rem 1rem;
}
.header {
  border-bottom: none;
}

.modalTitle {
  font-family: "RakutenRoundRegular";
  font-size: 1.5rem;
  color: black;
}

.modalTitleBold {
  font-family: "RakutenRoundSemiBold";
  font-size: 1.5rem;
  color: black;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  margin-left: 1rem;
}

.searchIcon {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  z-index: 9999;
  background: url("../../../assets/icons/searchIcon.svg") no-repeat;
  width: 2rem;
  height: 2rem;
}

.searchInput {
  font-family: "RakutenRoundRegular";
  font-size: 0.8125rem;
  width: 37rem;
  height: 2.75rem;
  border-radius: 0.25rem;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
  display: inline-flex;
  margin-left: 0.5rem;
}
.emptyContainer {
  height: 14rem;
  background: #f7f7f7;
  padding: 1rem 2rem;
  margin: 1rem 1rem;
  border-radius: 0.25rem;
  width: 60rem;
}
.footer {
  justify-content: center;
  padding: 0rem 0.75rem 1.75rem 0.75rem;
  border-top: none;
}
.errorMessage {
  font-size: 0.875rem;
  color: #ff0000;
}
.note {
  font-family: "RakutenRoundRegular";
  font-size: 0.8rem;
  color: black;
}
.note2 {
  font-family: "RakutenRoundRegular";
  font-size: 0.8rem;
  color: black;
}
.modalWidth {
  z-index: 9999;
  margin-top: 4rem;
}
