@import "../../../variables.scss";

@media screen {
  .recommendation-container {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .my-Recommendations-pagetitle {
    font-family: "RakutenRoundSemiBold";
    font-size: 2rem;

    .ant-typography {
      word-break: none;
    }
  }
  .recommendation-table {
    width: 81.5rem;
    margin-right: 1rem;
  }

  .recommendations-crumbs {
    margin-top: 2rem;
    width: 82rem;
    margin-bottom: 1rem;
    color: $primary;
    font-family: "RakutenRoundRegular";
  }

  .recommend-addnewbutton {
    font-size: 1rem;
    background-color: $primary;
    font-family: "RakutenRoundSemiBold";
    color: #ffffff;
    border-radius: 0.25rem;
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
    text-transform: uppercase;
    height: 2.2rem;
    min-width: 11rem;
  }
}

@media screen and (max-width: 768px) {
  .recommendation-container {
    margin-top: 1rem;
    width: 23rem;
    overflow: hidden;
  }

  .recommendation-table {
    width: 23rem;
    overflow: scroll;
  }

  .recommendation-header {
    width: 43rem;
    overflow-x: scroll;
    padding-left: 12rem;
  }

  .my-Recommendations-pagetitle {
    font-family: "RakutenRoundSemiBold";
    font-size: 1.5rem;
  }

  .recommendation-header1 {
    width: 55rem;
  }

  .recommendations-crumbs {
    margin-top: 0rem;
    width: 45rem;
    padding-left: 12rem;
  }
}
