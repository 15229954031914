.custom_toggle {
  margin-top: 48px;
  /* changed max-width to width -- this can be improved*/
  width: 130px;
  height: 42px;
  background-color: #d2eaf6;
  border-radius: 6px;
  margin-right: 23.57px;
  font-family: "RakutenRoundRegular";
  cursor: pointer;
}

.icon_box {
  max-width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  margin-left: 8px;
  border-radius: 2px;
}

.toggle_title {
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 0.875rem;
  letter-spacing: 0;
  margin-top: 0.375rem;
  margin-left: 0.589375rem;
  padding: 0;
  max-width: 4.375rem;
  color: #019ff0;
}
