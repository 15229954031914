@import "../../variables.scss";
.avtarDropdown .dropdown-menu {
  right: 0.125rem !important;
  top: -0.1rem !important;
  left: unset;
  width: 14.063rem;
  background-color: #ffffff;
  border-radius: 0 !important;
  border-top-width: 0.3rem;
  border-top-color: $primary;
  margin-top: 1rem !important;
}
.avtarDropdown {
  padding: 0rem;
  width: 0;
  height: 0;
}
.avtarDropdownItem .dropdown-item {
  color: #000000;
  font-family: "RakutenRoundRegular";
  font-size: 0.913rem;
  letter-spacing: 0;
  line-height: 0.75rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.eLearningDropdown .dropdown-menu {
  left: 1.6rem !important;
  background-color: #ffffff;
  border-radius: 0 !important;
  min-height: 18.75rem;
  margin-top: 1rem !important;
  height: 21.75rem;
  min-width: 15rem;
}

.instructorLedDropdown .dropdown-menu {
  left: 1.6rem !important;
  background-color: #ffffff;
  border-radius: 0 !important;
  min-height: 18.75rem;
  margin-top: 1rem !important;
  height: 21.75rem;
  min-width: 15rem;
}

.eLearningDropdownItem .dropdown-item {
  color: #000000;
  font-family: "RakutenRoundRegular";
  font-size: 0.813rem;
  letter-spacing: 0.014rem;
  line-height: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  white-space: initial;
  vertical-align: center;
}

.instructorLedDropdownItem .dropdown-item {
  color: #000000;
  font-family: "RakutenRoundRegular";
  font-size: 0.813rem;
  letter-spacing: 0.014rem;
  line-height: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  white-space: initial;
  vertical-align: center;
}

.eLearningDropdownItem > .dropdown-menu.show::before {
  border-top: 0.3rem solid $primary;
  position: absolute;
  width: 8rem;
  margin-top: -0.75rem;
  content: " ";
}

.instructorLedDropdownItem > .dropdown-menu.show::before {
  border-top: 0.3rem solid $primary;
  position: absolute;
  width: 8rem;
  margin-top: -0.75rem;
  content: " ";
}

.instructorLedDropdownItem a:visited,
.instructorLedDropdownItem a:hover {
  color: #000000;
  font-family: RakutenRoundSemiBold;
}

.eLearningDropdownItem a:visited,
.eLearningDropdownItem a:hover {
  color: #000000;
  font-family: RakutenRoundSemiBold;
}

.learningPathStyle a:visited,
.learningPathStyle a:hover {
  color: #000000;
  font-family: RakutenRoundSemiBold;
}

.submenu .dropdown-menu {
  margin-top: -21.6rem !important;
  margin-left: 12rem;
  background-color: #f7f7f7;
  min-height: 18.75rem;
  border: none;
  padding-top: 1rem;
  padding-bottom: 1rem;
  height: 21.63rem;
  opacity: 0.95;
}

.dropdown-submenu:hover,
.dropdown-submenu-container.show > a {
  background-image: url("../../assets/icons/rightArrowBlack.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-position: right 1.5rem bottom 1.5rem;
  font-family: "RakutenRoundSemiBold";
  background-color: rgb(52.9%, 80.8%, 98%, 0.3);
}

.instructorLedDropdown .dropdown-toggle::after {
  display: none !important;
}

.eLearningDropdown .dropdown-toggle::after {
  display: none !important;
}

.globalSearchDropdown .dropdown-toggle::after {
  display: none !important;
}

.avtarDropdown .dropdown-toggle::after {
  display: none !important;
}

.instructorLedDropdown .dropdown-item:focus,
.dropdown-item:hover {
  font-family: "RakutenRoundSemiBold";
  background-color: rgb(52.9%, 80.8%, 98%, 0.3);
}

.eLearningDropdown .dropdown-item:focus,
.dropdown-item:hover {
  font-family: "RakutenRoundSemiBold";
  background-color: rgb(52.9%, 80.8%, 98%, 0.3);
}

.avtarDropdown .dropdown-item:focus,
.dropdown-item:hover {
  font-family: "RakutenRoundSemiBold";
  background-color: rgb(52.9%, 80.8%, 98%, 0.3);
}

.bg-light {
  background-color: transparent !important;
}

.form-control::-webkit-input-placeholder {
  color: #9c9c9c;
}

.navDropDownLink {
  color: "#000000";
  margin-top: "10rem";
}
