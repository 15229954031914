.pageHeader {
  position: fixed;
  z-index: 999;
  height: 5rem;
  width: 100%;
}

.pageHeaderScrolled {
  background-color: white;
  position: fixed;
  z-index: 999;
  height: 5rem;
  width: 100%;
}

.growLogo {
  height: 3.125rem;
  width: 6.625rem;
  margin-left: -1.9rem;
  z-index: 9999;
}

.discourseLogo {
  cursor: pointer;
  width: 5.563rem;
  height: 1.5rem;
}
.KBLogo {
  cursor: pointer;
  width: auto;
  height: 1rem;
  margin-top: 0.3rem;
}
.menutitles {
  color: #000000;
  font-family: "RakutenRoundRegular";
  letter-spacing: 0.016rem;
  margin-left: 1.563rem;
  height: 100%;
  padding-bottom: 0.9375rem;
  width: 100%;
}

.menutitles:hover {
  letter-spacing: 0rem;
}

.menutitlesHomePage {
  color: white !important;
  font-family: "RakutenRoundRegular";
  letter-spacing: 0.016rem;
  margin-left: 1.563rem;
  height: 100%;
  padding-bottom: 1rem;
  width: 100%;
}

.menutitlesHomePage:hover {
  letter-spacing: 0rem;
}

.headermenutitle a:visited,
.headermenutitle a:hover {
  color: #000000;
  font-family: RakutenRoundSemiBold;
}
.headermenutitleHome {
  display: block;
}

.headermenutitleHome a:visited,
.headermenutitleHome a:hover {
  color: white;
  font-family: RakutenRoundSemiBold;
}

.searchIconHome {
  position: absolute;
  right: 0rem;
  top: 0.75rem;
  z-index: 9999;
  background: url("../../assets/icons/searchIconWhite.svg") no-repeat;
  width: 2rem;
  height: 2rem;
}
.searchIcon {
  position: absolute;
  right: 0rem;
  top: 0.75rem;
  z-index: 9999;
  background: url("../../assets/icons/searchIcon.svg") no-repeat;
  width: 2rem;
  height: 2rem;
}

.clearIconHome {
  position: absolute;
  right: 0rem;
  top: 0.75rem;
  z-index: 9999;
  background: url("../../assets/icons/xWhite.svg") no-repeat;
  width: 2rem;
  height: 2rem;
}
.clearIcon {
  position: absolute;
  right: 0rem;
  top: 0.75rem;
  z-index: 9999;
  background: url("../../assets/icons/x.svg") no-repeat;
  width: 2rem;
  height: 2rem;
}

.searchInput {
  height: 2.5rem;
  width: 5.938rem;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
  box-sizing: border-box;
  border: 0.063rem solid #000000;
  border-radius: 0.25rem;
  color: #000000;
  font-family: "RakutenRoundRegular";
  letter-spacing: 0.013rem;
  line-height: 0.75rem;
  font-size: 0.75rem;
}

.searchInputHome {
  height: 2.5rem;
  width: 5.938rem;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
  box-sizing: border-box;
  border: 0.063rem solid white;
  border-radius: 0.25rem;
  color: white;
  text-decoration-color: white;
  font-family: "RakutenRoundRegular";
  letter-spacing: 0.013rem;
  line-height: 0.75rem;
  font-size: 0.75rem;
}

.profileAvtar {
  cursor: pointer;
  font-family: "RakutenRoundRegular";
  font-size: 1.375rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.688rem;
}
.avatrar {
  text-align: right;
}
.iconSet {
  margin-top: 0.65rem;
  align-items: center;
}
.wrapText {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.75rem;
}

.lableInDropdown {
  height: 2.5rem;
  color: #000000;
  font-family: "RakutenRoundSemiBold";
  font-size: 0.8rem;
  font-weight: 600;
  letter-spacing: 0.014rem;
  line-height: 1.25rem;
  padding-top: 0.5rem;
  margin-left: 1.5rem;
}

.allCourses {
  color: #000000;
  font-family: "RakutenRoundSemiBold";
  font-size: 0.813rem;
  font-weight: 600;
  letter-spacing: 0.014rem;
  line-height: 1.25rem;
  padding-top: 1rem;
  margin-left: 1.5rem;
  cursor: pointer;
}

.submenuPanel {
  min-width: 15rem;
}
.submenuPanel p {
  white-space: nowrap;
}

.submenuPanelContainer {
  display: flex;
  flex-direction: row;
}

.dropdown-menu label {
  width: auto !important;
}

.submenuPanel a {
  padding: 0.25rem 1.5rem !important;
  line-height: 1.5rem !important;
}
.submenuPanel a:hover {
  text-decoration: underline;
  background-image: none !important;
  background-color: unset !important;
}
.dropdown-submenu img {
  height: 0.75rem;
  float: right;
  margin-top: 0.5rem;
}

.navbarStyle {
  margin-left: -0.8rem;
}

.growLogoStyle {
  display: flex;
  width: 100%;
  margin: 0rem;
  color: black;
}

.rowStyle {
  margin-left: -10;
  margin-top: 5;
}

.dcLogoStyle {
  margin-left: 1.4rem;
  z-index: 9999;
  cursor: pointer;
  display: inline-block;
}

.myRakutenLogo {
  margin-left: 0.5rem;
  z-index: 9999;
  cursor: pointer;
  display: inline-block;
}

.calendarStyle {
  z-index: 9999;
  width: 10rem;
  margin-top: 0.85rem;
  display: block;
  font-family: "RakutenRoundRegular";
}

.learningPathStyle {
  z-index: 9999;
  width: 10rem;
  margin-top: 0.85rem;
  display: block;
  font-family: "RakutenRoundRegular";
}

.containerStyle {
  max-width: 82.5rem;
  height: 4.5rem;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 1300px) and (max-width: 1400px) {
  .containerStyle {
    max-width: 72.5rem;
    height: 4.5rem;
    display: flex;
    align-items: center;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .containerStyle {
    max-width: 72.5rem;
    height: 4.5rem;
    display: flex;
    align-items: center;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1200px) {
  .containerStyle {
    max-width: 72.5rem;
    height: 4.5rem;
    display: flex;
    align-items: center;
  }
}

.marginTopSubmenu {
  overflow: scroll;
  width: 40rem;
}
