@media screen {
  .learning-skills-title {
    height: 2.25rem;
   width:78.5625rem;
    color: #000000;
    font-family: "RakutenRoundRegular";
    font-size: 2rem;
    letter-spacing: 0;
    line-height: 2.125rem;
    margin-bottom: 2.5rem;
    margin-left: auto;
    margin-right: auto;
}

  .learning-skills-container {
    margin-bottom: 7.5rem;
    margin-top: 2rem;
  }

  .child {
    display: flex;
  }
}

@media screen and (min-width: 2160px) and (max-width: 2880px) {
  .learning-skills-title {
    height: 2.25rem;
    color: #000000;
    font-family: "RakutenRoundRegular";
    font-size: 2rem;
    letter-spacing: 0;
    line-height: 2.125rem;
    margin-bottom: 2.5rem;
  }
  .child {
    display: flex;
    justify-content: space-evenly;
    margin-left: 20%;
    margin-right: 20%;
  }
}

@media screen and (min-width: 1920px) and (max-width: 2160px) {
  .learning-skills-title {
    height: 2.25rem;
    color: #000000;
    font-family: "RakutenRoundRegular";
    font-size: 2rem;
    letter-spacing: 0;
    line-height: 2.125rem;
    margin-bottom: 2.5rem;
  }
  .child {
    display: flex;
    justify-content: space-evenly;
    margin-left: 20%;
    margin-right: 20%;
  }
}

@media screen and (min-width: 1800px) and (max-width: 1920px) {
  .learning-skills-title {
    height: 2.25rem;
    color: #000000;
    font-family: "RakutenRoundRegular";
    font-size: 2rem;
    letter-spacing: 0;
    line-height: 2.125rem;
    margin-bottom: 2.5rem;
  }
  .child {
    display: flex;
    justify-content: space-evenly;
    margin-left: 20%;
    margin-right: 20%;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1800px) {
  .learning-skills-title {
    height: 2.25rem;
    color: #000000;
    font-family: "RakutenRoundRegular";
    font-size: 2rem;
    letter-spacing: 0;
    line-height: 2.125rem;
    margin-bottom: 2.5rem;
  }
  .child {
    display: flex;
    justify-content: space-evenly;
    margin-left: 15%;
    margin-right: 15%;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1600px) {
  .learning-skills-title {
    height: 2.25rem;
    color: #000000;
    font-family: "RakutenRoundRegular";
    font-size: 2rem;
    letter-spacing: 0;
    line-height: 2.125rem;
    margin-bottom: 2.5rem;
  }
  .child {
    display: flex;
    justify-content: space-evenly;
    margin-left: 15%;
    margin-right: 15%;
  }
}

@media screen and (min-width: 1340px) and (max-width: 1440px) {
  .learning-skills-title {
    height: 2.25rem;
    color: #000000;
    font-family: "RakutenRoundRegular";
    font-size: 2rem;
    letter-spacing: 0;
    line-height: 2.125rem;
    margin-bottom: 2.5rem;
  }
  .child {
    display: flex;
    justify-content: space-evenly;
    margin-left: 15%;
    margin-right: 15%;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1340px) {
  .learning-skills-title {
    height: 2.25rem;
    color: #000000;
    font-family: "RakutenRoundRegular";
    font-size: 2rem;
    letter-spacing: 0;
    line-height: 2.125rem;
    margin-bottom: 2.5rem;
  }
  .child {
    display: flex;
    // width: 23rem;
    justify-content: center;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .learning-skills-title {
    height: 2.25rem;
    color: #000000;
    font-family: "RakutenRoundRegular";
    font-size: 2rem;
    letter-spacing: 0;
    line-height: 2.125rem;
    margin-bottom: 2.5rem;
    padding-left:3.5rem;
  }
  .child {
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: 1150px) and (max-width: 1200px) {
  .learning-skills-title {
    height: 2.25rem;
    color: #000000;
    font-family: "RakutenRoundRegular";
    font-size: 2rem;
    letter-spacing: 0;
    line-height: 2.125rem;
    margin-bottom: 2.5rem;
    padding-left:2rem;
  }
  .child {
    display: flex;
    // width: 23rem;
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .learning-skills-title {
    height: 2.25rem;
    width: 20.5625rem;
    color: #000000;
    font-family: "RakutenRoundSemiBold";
    font-size: 1.375rem;
    letter-spacing: 0;
    line-height: 2.125rem;
    margin-bottom: 1rem;
    // margin-left: 0.5rem;
    margin-top: 1.5rem;
  }

  .learning-skills-container {
    width: 20.63625rem;
    margin-bottom: 2.5rem;
    margin-left: 0.5rem;
  }

  .child {
    display: flex;
    width: 23rem;
    overflow: hidden;
    overflow-x: scroll;
  }
}
