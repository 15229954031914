
.searchInput {
	font-family: "RakutenRoundRegular";
	width: 12.5rem;
	height: 2.75rem;
	border-radius: 0.25rem;
}

.fields {
  box-sizing: border-box;
  height: 2.875rem;
  width: 17.229rem;
  border: 0.063rem solid #9C9C9C;
  border-radius: 0.375rem;
  background-color: #FFFFFF;
}

.zoomField {
  box-sizing: border-box;
  height: 2.875rem;
  width: 35.938rem;
  border: 0.063rem solid #9C9C9C;
  border-radius: 0.375rem;
  background-color: #FFFFFF;
}

.zoomIcon {
	position: absolute;
	right: 0rem;
	top: 0.50rem;
	z-index: 9999;
	background: url("../../../assets/icons/zoom_icon.svg") no-repeat;
	height: 2.188rem;
  width: 2.188rem;
}

.trainerDetailsLabel {
    height: 1.5rem;
    width: 14.563rem;
    color: #000000;
    font-family: "RakutenRoundSemiBold";
    font-size: 1.125rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.375rem;
  }

.addTrainerButton {
    height: 1.766rem;
    width: 7.625rem;
    border-radius: 0.25rem;
    background-color: #00A0F0;
    color: #FFFFFF;
    font-family: "RakutenRoundBold";
    font-size: 0.875rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.063rem;
    text-transform: none;
  }

.iconStyle {
	height: 0.875rem;
	width: 0.875rem;
	fill: #FFFFFF;
  }

.trainerDetailsBox {
    width: 26.375rem;
    border-radius: 0.375rem;
    background-color: #FFFFFF;
    padding: 1.563rem;
  }

.submit {
    height: 2.871rem;
    width: 10.875rem;
    border-radius: 0.25rem;
    background-color: #00A0F0;
  }

.delete {
      height: 2.871rem;
      width: 10.875rem;
  }
  