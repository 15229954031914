.countryIcon {
	width: 2rem;
	height: 1.25rem;
}
.globeIcon {
	width: 2rem;
	height: 2rem;
}
.legendStyle {
	position: relative;
	top: 3rem;
}
.pageFilter {
	position: relative;
	top: 3.5rem;
	/* left: 15rem; */
}
.subTitle {
	margin: 0;
}