/* Course Stats css */
.StatsTileContainer {
  height: 8.5rem;
  background-color: #ffffff;
  margin-bottom: 2rem;
  padding: 1rem;
  text-transform: uppercase;
  color: #303030;
  font-family: "RakutenRoundSemiBold";
  width: 90%;
}

/*** Category based Stats css *****/

.categoryStatsContainer {
  background-color: #ffffff;
  /* height: 30rem; */
  height: 34rem;
}

.chartLabel {
  text-align: left;
  font-family: "RakutenRoundSemiBold";
  font-size: large;
  color: #303030;
  margin: 0;
  padding: 1rem;
}

.courseFilter {
  height: 75%;
  font-size: small;
  font-weight: 400;
}

.categoryStatsHeaderContainer {
  align-items: center !important;
  border-bottom: 1px solid #e4e7eb;
  height: 100%;
}

.donutWrapper {
  position: absolute;
  top: 43%;
  left: 29.5%;
  width: 7rem;
  text-align: center;
}

/* attendence stats css */
.tileContainer {
  width: 15.3rem;
  height: 8.5rem;
  background-color: #ffffff;
  margin-left: 0.8rem;
  margin-top: 0.7rem;
  padding: 1rem;
  text-transform: uppercase;
  color: #303030;
  font-family: "RakutenRoundSemiBold";
  border-radius: 0.25rem;
}

.tileLabel {
  font-size: 0.875rem;
  line-height: 1rem;
  width: 100%;
}

.tileValue {
  font-size: 1.75rem;
  width: 100%;
}

.progressBarStyle {
  height: 0.25rem;
  width: 70%;
  margin-top: 1rem;
  margin-right: 1rem;
}

.progressBarContent {
  font-family: "RakutenRoundRegular";
  font-weight: 500;
  font-size: 0.875rem;
  margin-top: 0.8rem;
}

.primryButton {
  margin-right: 1rem;
}

.scheduleTime {
  font-size: 0.875rem;
  margin-right: 1rem;
}

.scheduleReportBox {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  align-content: center;
}

.teamreports-pagetitle {
  font-family: "RakutenRoundSemiBold";
  font-size: 2.5rem;
  margin-top: 1rem;
  margin-bottom: 3rem;
}
