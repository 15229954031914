.title {
	font-family: "RakutenRoundRegular";
	font-size: 1.5rem;
	margin-left: 0.25rem;
	margin-bottom: 0.25rem;
}

.modal {
	margin-left: -18.75rem;
	min-width: 66.313rem;
	min-height: 12.813rem;
}

.titleBold {
	font-family: "RakutenRoundSemiBold";
	font-size: 1.5rem;
	margin-bottom: 0.25rem;
}

.header {
	border-bottom: none;
}

.leftButton {
	margin-right: 3.438rem;
	margin-left: 2.25rem;
}

.buttonStyle {
	min-width: 10rem;
}

.modalContent {
	margin: 0rem 1rem 1rem 0.25rem;
	font-family: "RakutenRoundRegular";
}

.orLabel {
	position: relative;
	left: -1.75rem;
	font-family: "RakutenRoundSemiBold";
}