.outer {
	margin: 0rem;
	width: 9.375rem;
}

.text {
	width: 62.5rem;
	margin: 0rem;
	padding: 0rem;
	margin-left: 0.313rem;
	overflow: hidden;
}

.privateIcon {
	max-width: 1.25rem;
	margin: 0rem;
	padding: 0rem;
	margin-left: 0.313rem;
}

.date {
	padding: 0rem;
	margin-left: 0.313rem;
}

.imageContainer {
	max-width: 1.25rem;
	margin: 0rem;
	padding: 0rem;
}
.image {
	height: 0.875rem;
}

.line {
	margin: 0;
	padding: 0;
	width: 9.2rem;
	background-color: #7d00be;
	height: 0.125rem;
}

/* checkboxes */

.enrolledCheckBox + label,
.publicCheckBox + label,
.privateSession + label,
.lockedSession + label {
	display: block;
	margin: 0.2rem;
	cursor: pointer;
	padding: 0.2rem;
}

.enrolledCheckBox,
.publicCheckBox,
.privateSession,
.lockedSession {
	display: none;
}

.enrolledCheckBox + label:before {
	content: "\2713";
	border: 0.125rem solid #7d00be;
	display: inline-block;
	border-radius: 0.2rem;
	width: 0rem;
	height: 0rem;
	padding-bottom: 1.3rem;
	padding-right: 1.1rem;
	margin-right: 0.2rem;
	vertical-align: bottom;
	color: transparent;
	transition: 0.2s;
	padding-left: 0.215rem;
}

.enrolledCheckBox + label:active:before {
	transform: scale(0);
}

.enrolledCheckBox:checked + label:before {
	background-color: #7d00be;
	color: #fff;
}

.publicCheckBox + label:before {
	content: "\2713";
	border: 0.125rem solid #019ff0;
	display: inline-block;
	border-radius: 0.2rem;
	width: 0rem;
	height: 0rem;
	padding-bottom: 1.3rem;
	padding-right: 1.1rem;
	margin-right: 0.2rem;
	vertical-align: bottom;
	color: transparent;
	transition: 0.2s;
	padding-left: 0.215rem;
}

.publicCheckBox + label:active:before {
	transform: scale(0);
}

.publicCheckBox:checked + label:before {
	background-color: #019ff0;
	color: #fff;
}

.privateSession + label:before {
	content: "\2713";
	border: 0.125rem solid #f59600;
	display: inline-block;
	border-radius: 0.2rem;
	width: 0rem;
	height: 0rem;
	padding-bottom: 1.3rem;
	padding-right: 1.1rem;
	margin-right: 0.2rem;
	vertical-align: bottom;
	color: transparent;
	transition: 0.2s;
	padding-left: 0.215rem;
}

.privateSession + label:active:before {
	transform: scale(0);
}

.privateSession:checked + label:before {
	background-color: #f59600;

	color: #fff;
}

.lockedSession + label:before {
	content: "\2713";
	border: 0.125rem solid #333333;
	display: inline-block;
	border-radius: 0.2rem;
	width: 0rem;
	height: 0rem;
	padding-bottom: 1.3rem;
	padding-right: 1.1rem;
	margin-right: 0.2rem;
	vertical-align: bottom;
	color: transparent;
	transition: 0.2s;
	padding-left: 0.215rem;
}

.lockedSession + label:active:before {
	transform: scale(0);
}

.lockedSession:checked + label:before {
	background-color: #333333;

	color: #fff;
}

.sideBarText {
	color: #b20c0c;
	font-size: 0.75rem;
	line-height: 0.9375rem;
}
