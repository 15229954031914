.cardTitle {
  margin-top: 1.5rem;
  margin-left: -4.2rem;
  font-size: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  letter-spacing: 0;
  line-height: 1.875rem;
  font-family: "RakutenRoundSemiBold";
  color: #000000 !important;
}

.courseTypeIndicator {
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
}
.overlayText {
  margin-left: 1.75rem;
  font-family: "RakutenRoundRegular";
  font-size: 0.75rem;
  letter-spacing: 0;
 margin-top: 0.275rem;
}


.textData {
 margin-top: 0.75rem;
  margin-left: 1rem;
  padding-left: 1rem;
  padding-top: 0rem;
  font-family: "RakutenRoundRegular";
  font-size: 0.875rem;
  line-height: 1.438rem;
}

.duration {
  margin-left: 1rem;
  padding-left: 1rem;
  font-family: "RakutenRoundRegular";
  font-size: 0.875rem;
  line-height: 1.438rem;
  overflow: hidden;
  white-space: nowrap;
}


.draggableList {
  width: 34rem;
  margin: 0 auto;
}
.courseItemWrapper {
  border-radius: .5rem;
  height: auto;
}
.courseItem{
  border-radius: .5rem;
  margin: 1rem 0;
  background-color:#fff;
  width: 32rem;
  height: 7.8rem;
}

.courseProviderIcon {
  height: 0.875rem;
  width: 0.813rem;
  margin-right: 0.125rem;
  margin-top: -0.13rem;
}

.courseProvider {
  margin-left: 1rem;
  padding-left: 1rem;
  margin-top: 1.5rem;
  margin-right: 0rem;
  padding-right: 0rem;
  font-family: "RakutenRoundRegular";
  color: #000000 !important;
  font-size: 0.75rem;
  line-height: 1.063rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.courseTitle {
  display: inline-block;
}

.courseHeading {
  font-size: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  letter-spacing: 0;
  line-height: 2rem;
  font-family: "RakutenRoundSemiBold";
  color: #000000 !important;
  white-space: nowrap;
  width: 20rem;
  height: 2rem;
  display: block;
}

.courseDetails {
  font-family: "RakutenRoundRegular";
  font-size: 0.875rem;
  line-height: 1.438rem;
}
.courseDuration {
  display: inline-block;
  font-family: "RakutenRoundSemiBold";
  font-size: 0.875rem;
  /* width: 11rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
}
.courseGear {
  display: inline-block;
  /* margin-left: 2rem; */
  text-transform: capitalize;
}
.DraggableItemWrapper {
  margin-left:2rem;
}
.deleteButton {
  float: right;
  margin-right: 1rem;
  cursor: pointer;
}