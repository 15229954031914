*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  
}

.container {
  width: 100%
}

@media (min-width: 640px) {
  .container {
    max-width: 640px
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px
  }
}

.visible {
  visibility: visible
}

.collapse {
  visibility: collapse
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.bottom-0 {
  bottom: 0px
}

.left-0 {
  left: 0px
}

.right-0 {
  right: 0px
}

.top-0 {
  top: 0px
}

.mb-10 {
  margin-bottom: 2.5rem
}

.mb-12 {
  margin-bottom: 3rem
}

.mb-3 {
  margin-bottom: 0.75rem
}

.mt-24 {
  margin-top: 6rem
}

.mt-3 {
  margin-top: 0.75rem
}

.mt-4 {
  margin-top: 1rem
}

.mt-5 {
  margin-top: 1.25rem
}

.mt-8 {
  margin-top: 2rem
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.table {
  display: table
}

.grid {
  display: grid
}

.hidden {
  display: none
}

.h-full {
  height: 100%
}

.w-full {
  width: 100%
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.resize {
  resize: both
}

.flex-row {
  flex-direction: row
}

.items-center {
  align-items: center
}

.justify-start {
  justify-content: flex-start
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse))
}

.overflow-hidden {
  overflow: hidden
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.break-all {
  word-break: break-all
}

.border {
  border-width: 1px
}

.border-t-2 {
  border-top-width: 2px
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1))
}

.bg-cover {
  background-size: cover
}

.bg-fixed {
  background-attachment: fixed
}

.bg-no-repeat {
  background-repeat: no-repeat
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem
}

.pb-16 {
  padding-bottom: 4rem
}

.pt-36 {
  padding-top: 9rem
}

.pt-4 {
  padding-top: 1rem
}

.text-center {
  text-align: center
}

.text-start {
  text-align: start
}

.font-rakutenRoundSemiBold {
  font-family: RakutenRoundSemiBold
}

.uppercase {
  text-transform: uppercase
}

.capitalize {
  text-transform: capitalize
}

.italic {
  font-style: italic
}

.tracking-tight {
  letter-spacing: -0.025em
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1))
}

.underline {
  text-decoration-line: underline
}

.\!filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.duration-500 {
  transition-duration: 500ms
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

@media (min-width: 640px) {
  .sm\:ml-28 {
    margin-left: 7rem
  }

  .sm\:not-italic {
    font-style: normal
  }
}

@media (min-width: 768px) {
  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem
  }

  .md\:text-6xl {
    font-size: 3.75rem;
    line-height: 1
  }
}

@media (min-width: 1024px) {
  .lg\:ml-28 {
    margin-left: 7rem
  }
}