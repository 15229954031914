.elearning-card {
  width: 19.5rem;
}

.card {
  border-radius: 0.5rem;
  border-color: #f7f7f7;
  background-color: #f7f7f7;
  width: 19.5rem;
  min-height: 22.25rem;
  max-height: 22.25rem;
  box-shadow: 0 0.125rem 0.25rem 0 #d1d1d1;
  margin-right: 0.3125rem;
  z-index: 0;
}
.cardLP {
  border-radius: 0.5rem;
  border-color: #f7f7f7;
  background-color: #f7f7f7;
  width: 19.5rem;
  min-height: 22.25rem;
  max-height: 22.25rem;
  box-shadow: 0 0.125rem 0.25rem 0 #d1d1d1;
  margin: 1rem;
}
.card:hover {
  box-shadow: 0 0.125rem 0.75rem 0 #d1d1d1;
}

.spanData {
  width: 13.75rem;
}

.cardDiv {
  margin-right: 0.625rem;
  padding-right: 0rem;
}

.instructorledIcon {
  height: 1.25rem;
  width: 1.0625rem;
  margin-left: 0.6875rem;
  margin-top: 1.125rem;
  display: inline-block !important;
}

.elearningOverlay {
  padding: 0;
  .elearningCardOverlayText {
    display: flex;
    margin-top: 0.5rem;
    margin-left: 1rem;
  }
  .elearningOverlayText {
    margin-left: 0rem;
    font-family: "RakutenRoundRegular";
    font-size: 0.75rem;
    letter-spacing: 0;
    color: #ffffff;
    margin-top: 0.275rem;
    line-height: 0rem;
  }
  .elearningOverlayText img {
    display: inline-block;
  }
  .wishlistImageEL {
    padding-top: 0.2rem;
    z-index: 2;
    margin-left: 0.5rem;
  }

  .wishlistImageEL > img {
    height: 20px;
    width: 20px;
    display: inline !important;
  }
}

.body {
  padding-left: 0;
  padding-top: 0.5rem;
  margin-bottom: 1.5rem;
  color: #333333;
}
.textData {
  width: 13.5rem;
  margin-top: 0.75rem;
  margin-left: 1rem;
  padding-left: 1rem;
  padding-top: 0rem;
  font-family: "RakutenRoundRegular";
  font-size: 0.875rem;
  line-height: 1.438rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media screen {
  .courseProvider {
    display: flex;
    margin-left: 1rem;
    padding-left: 1rem;
    margin-top: 0.2rem;
    margin-right: 0rem;
    padding-right: 0rem;
    font-family: "RakutenRoundRegular";
    color: #000000 !important;
    font-size: 0.75rem;
    line-height: 1.063rem;
    overflow: hidden;
    text-overflow: ellipsis;
    // display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .courseProviderIcon {
    height: 0.875rem;
    width: 0.813rem;
    margin-right: 0.125rem;
    margin-top: -0.13rem;
  }

  .el-course-provider-icon {
    margin-top: 0rem;
    margin-right: 0.2rem;
  }

  .cardTitle {
    width: 15.5rem;
    height: 3.75rem;
    font-size: 1.5rem !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-left: 2rem;
    letter-spacing: 0;
    line-height: 1.875rem;
    font-family: "RakutenRoundSemiBold";
    color: #000000 !important;
  }
}

@media screen and (max-width: 768px) {
  .courseProvider {
    display: flex;
    flex-direction: row;
    margin-left: 2rem;
    padding-left: 0rem;
    margin-top: 0.5rem;
    margin-right: 0rem;
    padding-right: 0rem;
    font-family: "RakutenRoundRegular";
    color: #000000 !important;
    font-size: 0.75rem;
    line-height: 1.063rem;
    overflow: hidden;
    text-overflow: ellipsis;
    // display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    align-items: flex-start;
  }

  .courseProviderIcon {
    height: 0.875rem;
    width: 0.813rem;
    margin-right: 0.5rem;
    margin-top: 0rem;
  }

  .el-course-provider-icon {
    margin-top: 0rem;
  }

  .cardTitle {
    width: 15.5rem;
    height: 3.75rem;
    font-size: 1.5rem !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-left: 2rem;
    letter-spacing: 0;
    line-height: 1.875rem;
    font-family: "RakutenRoundSemiBold";
    color: #000000 !important;
  }
}

.cardFooter {
  margin-left: 1rem;
  padding-left: 1rem;
  margin-top: 2.5rem;
  margin-right: 0rem;
  padding-right: 0rem;
  font-family: "RakutenRoundRegular";
  color: #333333;
  font-size: 0.875rem;
  line-height: 1.4375rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.imageDiv {
  background-color: black;
  border-radius: 0.5rem 0.5rem 0 0;
}
.image {
  border-radius: 0.5rem 0.5rem 0 0;
  opacity: 0.7;
}
.duration {
  margin-left: 1rem;
  padding-left: 1rem;
  font-family: "RakutenRoundRegular";
  font-size: 0.875rem;
  line-height: 1.438rem;
  overflow: hidden;
  white-space: nowrap;
}
.roundProgressBar {
  margin-top: -1.75rem;
  padding-top: 0rem;
}

.rightContent {
  margin-left: 11.5rem;
}

.assignedStatus {
  border-radius: 0.25rem;
  background-color: #bf0000;
  color: #ffffff;
  text-align: center;
  padding: 0.25rem 0.75rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-family: "RakutenRoundRegular";
}

.circleProgressBar {
  width: 5rem;
  height: 5rem;
  cursor: pointer;
  float: right;
  margin-left: 0.3rem;
  margin-top: 1.15rem;
}
.textInside {
  color: #000000;
  font-family: "RakutenRoundRegular";
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.063rem;
  max-width: 4.5rem;
  text-align: center;
}

.courseDetailsStyle {
  margin-top: -0.35rem;
}

.plusCircleStyle {
  height: 3rem;
  width: 3rem;
  margin-top: 1.75rem;
  margin-left: 1.75rem;
  position: absolute;
}

.addSelectCourse {
  cursor: pointer;
}

.cardLearningPath {
  border-radius: 0.5rem;
  border-color: #f7f7f7;
  background-color: #f7f7f7;
  /* width: 19.5rem; */
  min-height: 22.25rem;
  max-height: 22.25rem;
  box-shadow: 0 0.125rem 0.25rem 0 #d1d1d1;
  margin-right: 0.3125rem;
}

.favouriteIcon {
  height: 20rem;
  width: 20rem;
}

.extraMargin {
  margin-left: 5.8rem;
}
