@media screen {
  .image-container {
    height: 118px;
    clip-path: circle();
    border-radius: 50%;
  }

  .Itemwidth {
    width: 1232px;
  }

  .dot-component {
    border-radius: 50%;
    background: #ebebeb;
    color: black;
    padding: 8px;
    width: 32px;
    margin-top: 112px;
  }

  .custom-timeline-item .ant-timeline-item-tail {
    margin-top: 80px !important;
    border-bottom: black !important;
    border-radius: 10px;
    z-index: 100;
    height: calc(100% - 56px) !important;
  }
  .course-name {
    font-family: "RakutenRoundSemiBold";
    font-size: 32px;
    margin-top: 16px;
    line-height: 32px;
    margin-bottom: 8px;
  }
  .courses-list-text {
    font-size: 32px;
    font-family: "RakutenRoundRegular";
    margin-bottom: 32px;
    z-index: 10;
  }

  .collapse-container {
    width: 88%;
  }
}

@media screen and (max-width: 768px) {
  .Itemwidth {
    width: unset;
  }

  .courses-list-text {
    font-size: 1.5rem;
    font-family: "RakutenRoundSemiBold";
    margin-bottom: 16px;
    z-index: 10;
  }

  .collapse-container {
    width: 19rem;
    height: auto;
    overflow: hidden;
  }

  .image-container {
    height: 5rem;
    clip-path: circle();
    border-radius: 50%;
  }

  .dot-component {
    border-radius: 50%;
    background: #ebebeb;
    color: black;
    padding: 4px;
    width: 24px;
    margin-top: 100px;
  }

  .custom-timeline-item .ant-timeline-item-tail {
    margin-top: 80px !important;
    border-bottom: black !important;
    border-radius: 10px;
    z-index: 100;
    height: calc(100% - 56px) !important;
  }
  .course-name {
    font-family: "RakutenRoundSemiBold";
    font-size: 1.5rem;
    margin-top: 8px;
    line-height: 20px;
    margin-bottom: 8px;
  }

  .ant-timeline-item-tail {
    z-index: 9999;
  }
}

.course-index-container {
  font-family: "RakutenRoundLight";
  font-size: 16px;
  color: #333333;
}

.course-icon {
  color: #333333;
  font-size: 14px;
}

.accordion-icon {
  height: 16px;
  width: 16px;
  margin-right: 8px;
}

.course-launch-button {
  color: white;
  border-radius: 0px;
  box-shadow: none;
}

.accoridon-hours-container {
  font-family: "RakutenRoundSemiBold";
  font-size: 14px;
}

.course-provider {
  align-items: center;
  justify-content: center;
  margin-left: 16px;
}

.course-provider-text {
  margin-left: 4px;
}

.courses-list-container {
  margin: 16px;
  margin-left: 0;
}

.accordion-header-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
