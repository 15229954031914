.containerCoursesTaking {
  max-width: 82.5rem;
  padding: 0;
  position: relative;
  margin-top: 3rem;
}

.containerIntructorLed {
  max-width: 84.5rem;
  padding: 0;
  position: relative;
  overflow: hidden;
}

.containerElearning {
  max-width: 84.5rem;
  padding: 0;
  position: relative;
  padding-top: 2.5rem;
  overflow: hidden;
}

.containerLearningPath {
  max-width: 84.5rem;
  padding: 0;
  position: relative;
  padding-top: 2.5rem;
  overflow: hidden;
}

.containerWhatToLearnNext {
  max-width: 84.5rem;
  padding: 0;
  position: relative;
  padding-left: 1rem;
  padding-top: 78px;
  padding-bottom: 78px;
  overflow: hidden;
}

.containerLearningBySkills {
  max-width: 86rem;
  padding-top: 120px;
  position: relative;
}

/* .fillingFast {
  display: inline-block;
  margin-left: 2.9375rem;
  vertical-align: top;
  width: 21.1875rem;
  margin-top: 1.25rem;
} */

/* .coursesInProgress {
  display: inline-block;
  width: 58.125rem;
  height: 22.5rem;
} */

.secondContainer {
  width: 82.63625rem !important;
  margin: 0rem;
  padding: 0rem;
  padding-bottom: 7.5rem;
}

.learningBySkills {
  margin-top: -7rem;
}

.nonPromotionalBannerTextLine {
  color: #ffffff;
  font-family: "RakutenRoundRegular";
  font-size: 3rem;
  letter-spacing: 0;
  line-height: 4.125rem;
  text-align: start;
  margin-top: -17.5rem;
  margin-bottom: 12.5rem;
}

.lineOverText {
  height: 0.25rem;
  width: 4.375rem;
  border-radius: 0.124rem;
  background-color: #00a0f0;
  margin-bottom: -0.5rem;
}
