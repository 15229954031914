@import "../../../variables.scss";

.my-learnings-parent-container {
  width: 83rem;
  padding-top: 5rem;
  background-color: #f7f7f7;
  margin-right: auto;
  margin-left: auto;
}

.my-learnings-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 81.5rem;
  margin-bottom: 3.063rem;
  z-index: 997;
}

.my-learnings-top-container {
  z-index: 997;
  height: 13rem;
  width: 83rem;
  background-color: #f7f7f7;
}

.my-learnings-leftpane {
  z-index: 997;
}

.my-learnings-breadcrumb {
  color: $primary;
  font-family: "RakutenRoundRegular";
  margin-bottom: 1.188rem;
  padding-top: 3rem;
  background-color: #f7f7f7;
}

.my-learnings-breadcrumb a {
  color: $primary;
}

.my-learnings-checkbox {
  font-size: 0.8rem;
  font-family: "RakutenRoundRegular";
}

.my-learnings-checkbox .ant-checkbox {
  margin: -3px;
}

.my-learnings-checkbox .ant-checkbox-inner {
  background-color: #f5f5f5;
  border-color: $primary;
  height: 1.5rem;
  width: 1.5rem;
}

.my-learnings-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary;
  border-color: $primary;
  height: 1.5rem;
  width: 1.5rem;
  padding-bottom: 3px;
}

.my-learnings-checkbox:hover .ant-checkbox-inner {
  border-color: $primary;
}

.my-learnings-checkbox .ant-checkbox-inner::after {
  border-color: white;
  width: 7px;
  height: 13px;
}

.my-learnings-title {
  font-family: "RakutenRoundSemiBold";
  font-size: 2rem;
  margin-right: 3rem;
}

.my-learnings-courseCard {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-height: 33rem;
  width: 80rem;
  overflow: scroll;
  scroll-behavior: smooth;
  margin-left: 2rem;
  margin-top: -1rem;
}

.my-learnings-icon {
  padding-right: 4px;
  padding-left: 4px;
  padding-bottom: 5px;
}

.my-learnings-ilIcon {
  padding-right: 4px;
  padding-left: 4px;
  padding-bottom: 5px;
}

.my-learnings-card-icon {
  margin-left: 0.8rem;
}

.myLearnings-courseTile {
  width: 18.5625rem;
  height: 11rem;
  background-color: #ffffff;
  box-shadow: 0 0.125rem 0.25rem 0 #d1d1d1;
  border-radius: 0.375rem;
  position: relative;
  display: inline-block;
  // margin-right: 5.3125rem;
  margin-bottom: 2rem;
}

.myLearnings-subheading {
  margin-left: 1rem;
  padding-top: 1.25rem;
  display: flex;
  justify-content: space-between;
  width: 13.7rem;
}

.myLearnings-subheadingText {
  font-family: "RakutenRoundRegular";
  font-size: 0.75rem;
  margin-left: 0.3125rem;
  color: #333333;
}

.myLearnings-seesionStart {
  display: inline-block;
  width: 11.375rem;
  margin-left: 1.5rem;
  margin-top: 0.875rem;
  font-family: "RakutenRoundRegular";
  font-size: 0.875rem;
  line-height: 1.3125rem;
}

.myLearnings-courseImage {
  position: absolute;
  left: 15.4675rem;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 9.5rem;
  height: 7.3rem;
  width: 6.188rem;
  top: 2.125rem;
  border-radius: 0.375rem;
}

.myLearnings-courseName {
  font-family: "RakutenRoundSemiBold";
  color: #000000;
  font-size: 1.438rem;
  margin-left: 1rem;
  margin-top: 0.5rem;
  width: 12rem;
  line-height: 1.2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
  height: 3.2rem;
}

.myLearnings-duration {
  font-family: "RakutenRoundRegular";
  font-size: 0.9rem;
  position: absolute;
  bottom: 1.2rem;
  margin-left: 1.1125rem;
  color: #333333;
  width: 14rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.myLearnings-completed {
  display: flex;
  font-family: "RakutenRoundRegular";
  font-size: 0.9rem;
  position: absolute;
  bottom: 1.2rem;
  margin-left: 1.1125rem;
  color: #333333;
  width: 14rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.myLearnings-sessionStart {
  font-family: "RakutenRoundRegular";
  font-size: 0.9rem;
  position: absolute;
  bottom: 2.6rem;
  margin-left: 1.1125rem;
  color: #333333;
}

.myLearnings-deadline {
  display: flex;
  flex-direction: row;
  font-family: "RakutenRoundRegular";
  font-size: 0.9rem;
  position: absolute;
  bottom: 2.6rem;
  margin-left: 1.1125rem;
  color: #333333;
}

.myLearnings-waitlist {
  font-family: "RakutenRoundSemiBold";
  font-size: 0.9rem;
  position: absolute;
  bottom: 2.6rem;
  margin-left: 1.1125rem;
  color: #333333;
}

.myLearnings-deadlineDate {
  font-family: "RakutenRoundSemiBold";
  margin-left: 0.125rem;
}

.myLearnings-bookmark {
  width: 1rem;
}

.myLearnings--alert {
  width: 83rem;
  margin-bottom: -2.5rem;
  font-family: "RakutenRoundRegular";
}

.my-learnings-description {
  margin-top: 4rem;
  width: 11rem;
  font-family: "RakutenRoundRegular";
  font-size: 0.9rem;
  line-height: 1rem;
}

.my-learnings-noCourses {
  display: flex;
  flex-direction: column;
  width: 70rem;
  justify-content: center;
  align-items: center;
}

.my-learnings-noCourses-image {
  width: 25rem;
  height: 25rem;
  margin-top: -10rem;
}

.my-learnings-noCourses-text {
  font-family: "RakutenRoundSemiBold";
  font-size: 1.8rem;
  margin-top: -3rem;
}

.my-learnings-noCourses-message {
  font-family: "RakutenRoundRegular";
  width: 34.8rem;
  font-size: 1rem;
  text-align: center;
  margin-top: 1rem;
}

.my-learnings-browse-button {
  margin-top: 2.5rem;
  width: 10rem;
  height: 3rem;
  font-size: 1.2rem;
  font-family: "RakutenRoundSemiBold";
  background-color: $primary;
  color: #ffffff;
  border-radius: 7px;
}

.myLearnings-lp-ilcount {
  font-family: "RakutenRoundSemiBold";
  margin-right: 0.2rem;
}

.myLearnings-lp-elcount {
  font-family: "RakutenRoundSemiBold";
  margin-right: 0.2rem;
  margin-left: 0.2rem;
}

.my-learnings-legend {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.my-learnings-legend-public {
  width: 12px;
  height: 12px;
  background: $primary;
  border-radius: 50%;
  display: block;
  margin-right: 0.5rem;
}

.my-learnings-legend-private {
  width: 12px;
  height: 12px;
  background: $secondary;
  border-radius: 50%;
  display: block;
  margin-right: 0.5rem;
}

.my-learnings-filter-content {
  cursor: pointer;
  padding: 8px;
  height: 2.5rem;
  width: 11.125rem;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.my-learnings-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.my-learnings-filter-name {
  margin-left: 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    color: $primary;
    font-family: "RakutenRoundRegular";
  }
}

.my-learnings-count-tag {
  font-family: "RakutenRoundRegular";
  font-size: 1rem;
  margin-left: 0.4rem;
}
