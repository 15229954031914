@import "../../../../variables.scss";

@media screen and (min-width: 1300px) and (max-width: 1400px) {
  .learningPath-header {
    width: 81rem;
    padding-left: 3rem;
  }

  .learningPath-crumbs {
    margin-left: 3rem;
  }

  .learningPath-table {
    margin-left: 3rem;
  }
}

.pageFilter {
  position: relative;
  top: 3.5rem;
}

.addPathStyle {
  position: relative;
  margin-top: 2.5rem;
  text-align: end;
}

.titleStyle {
  top: 0.5rem;
}

.pageFilter {
  position: relative;
  top: 3.5rem;
  /* left: 15rem; */
}

.expandRowContainer {
  max-width: 20rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.expandRowTable {
  border: 0.0625rem solid #333333;
  margin-top: 0.8125rem;
  margin-right: 5%;
  margin-bottom: 1.5rem;
  margin-left: 5%;
  width: 90%;
}

.cardCountContainer {
  height: 9rem;
  width: 13.625rem;
  border-radius: 0.375rem;
  background-color: #f7f7f7;
  margin-top: 0.625rem;
  margin-bottom: 1.5rem;
  padding-top: 1.125rem;
}

.cardCountInnerContainer {
  height: 2.125rem;
  width: 9.375rem;
  text-align: center;
  color: #979797;
  font-family: RakutenRoundRegular;
  font-size: 0.875rem;
  font-weight: 600;
  margin: 0 auto;
  margin-top: 0.5625rem;
}

.courseName {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.courseIdName {
  border: 0.0625rem solid !important;
  background: none !important;
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
  padding-left: 2rem !important;
}

.expandTableTh {
  border: 0.0625rem solid #333333 !important;
  padding-left: 2rem !important;
  background: none !important;
  width: 12rem !important;
}

.expandTableThStatus {
  border: 0.0625rem solid #333333 !important;
  padding-left: 2rem !important;
  background: none !important;
  width: 12rem !important;
  text-transform: capitalize;
}

.courseIdTh {
  width: 20.75rem !important;
}

.courseNameTh {
  width: 20.75rem !important;
}

.expandRowContainer {
  max-width: 20rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.headerStyle {
  background-color: #ebebeb;
}

.toggleExpandButton {
  cursor: pointer;
}

.learningPathTableTopTextContainer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0 5rem;
}

.courseTitle {
  font-family: "RakutenRoundSemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.courseId {
  font-family: "RakutenRoundRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.boldText {
  font-family: "RakutenRoundBold";
}

.elSession {
  color: #333333;
  text-overflow: ellipsis;
  width: 7rem;
}

.privateSession {
  color: $secondary;
  text-overflow: ellipsis;
  width: 7rem;
}

.publicSession {
  color: $primary;
  text-overflow: ellipsis;
  width: 7rem;
}

.infoCard {
  height: 132px;
  background-color: #f7f7f7;
  text-align: center;
}

.infoCardTitle {
  padding-top: 26px;
  font-size: 14px;
  font-family: "RakutenRoundSemiBold";
}

.infoCardData {
  padding-top: 16px;
  font-size: 36px;
  font-family: "RakutenRoundSemiBold";
  line-height: 45px;
}

.elBg {
  background-color: #f7f7f7 !important;
}

.ilBg {
  background-color: #e5f5fd !important;
}

.ilBg:hover td {
  background-color: #eff9fe !important;
}

.noSessionBg {
  background-color: #ffe6e6 !important;
}

.noSessionBg:hover td {
  background-color: #fff2f2 !important;
}

.ilSessionbg {
  background-color: $secondary-with-opacity !important;
}

.ilSessionbg:hover td {
  background-color: #fef8ef !important;
}

.rowSpacing table {
  border-spacing: 0 8px;
}

.rowSpacing tbody:before {
  line-height: 0px;
  display: none;
}

.p32 {
  padding: 32px 0px;
}

.centerAlign {
  align-self: center;
}
