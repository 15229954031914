@media screen {
  .quote-title {
    // height: 5.75rem;
    width: 40.4375rem;
    color: #000000;
    font-family: "RakutenRoundRegular";
    font-size: 2.25rem;
    letter-spacing: 0;
    line-height: 2.875rem;
    text-align: center;
  }

  .quote-author {
    height: 1.375rem;
    width: 20.1875rem;
    color: #000000;
    font-family: "RakutenRoundRegular";
    font-size: 0.875rem;
    letter-spacing: 0;
    line-height: 1.375rem;
    text-align: center;
  }
}
.mobile-quotation {
  display: flex;
  justify-content: center;
  margin-bottom: 2.5rem;
  padding: 1rem;
}

@media screen and (max-width: 767px) {
  .quote-title {
    height: 3.75rem;
    width: 21.4375rem;
    color: #000000;
    font-family: "RakutenRoundSemiBold";
    font-size: 1.2rem;
    letter-spacing: 0;
    line-height: 1.875rem;
    text-align: center;
  }

  .quote-author {
    height: 1.375rem;
    width: 23.1875rem;
    color: #000000;
    font-family: "RakutenRoundRegular";
    font-size: 0.875rem;
    letter-spacing: 0;
    line-height: 1.375rem;
    text-align: center;
    // margin-top: 2rem;
    margin-left: 3rem;
  }

  .mobile-quotation {
    width: 23rem;
    display: flex;
    justify-content: centre;
    margin-bottom: 0rem;
    padding: 1rem;
  }
  .quotation-row {
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 22rem;
    width: 21rem;
    margin-left: 1rem;
  }
  .author-container {
    width: 20rem;
  }
}
