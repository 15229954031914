.legendContainer {
	background-color: #ebebeb;
	padding: 0.5rem 0;
	font-size: 0.875rem;
	border-radius: 0.25rem;
}
.publicIcon {
	height: 0.75rem;
	width: 0.75rem;
	border-radius: 50%;
	background-color: #00a0f0;
	position: relative;
	top: 0.3rem;
	left: 0.5rem;
}
