@import "../../../variables.scss";

@media screen {
  .sessionList_addNewCourse {
    position: relative;
    background-color: $primary;
    left: 2rem;
    width: 10rem;
    font-size: 1rem;
    color: #ffffff;
    font-family: "RakutenRoundSemiBold";
    border-radius: 0.25rem;
    height: 2.5rem;
    text-transform: uppercase;
  }

  .searchBar {
    top: 3rem;
    /* left: 12rem; */
    position: relative;
  }

  .pageFilter {
    position: relative;
    top: 3.5rem;
    /* left: 15rem; */
  }

  .tableSearch {
    position: absolute;
    top: 7.6rem;
    left: 70rem;
    width: 12.5rem;
  }

  .downloadButtonStyle {
    position: absolute;
    top: 7.6rem;
    left: 66rem;
  }

  .numberButton {
    border: 2px solid black;
    border-radius: 4px;
    padding: 2px;
  }

  .wrapText {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.58rem;
  }

  .countryIcon {
    width: 2rem;
    height: 1.25rem;
  }

  .globeIcon {
    width: 2rem;
    height: 2rem;
  }

  .subTitle {
    margin: 0;
  }

  .waitlistLabel {
    word-break: break-word;
    font-size: 0.8125rem;
    display: block;
    width: 5rem;
    float: left;
    font-family: "RakutenRoundRegular";
    padding-left: 2rem;
    position: relative;
  }

  .waitlistCircle {
    position: relative;
    right: 1rem;
    display: flex;
  }

  .plusIconStyle {
    transform: scale(0.65);
  }

  .primaryButtonRed {
    background-color: red;
    border-radius: 0.25rem;
    font-family: "RakutenRoundSemiBold";
    font-size: 1rem;
    width: 10rem;
    align-items: center;
    line-height: 1.25rem;
    color: #ffffff;
    border: none;
    text-transform: uppercase;
    position: relative;
    left: 2rem;
    height: 2.5rem;
  }

  /* for modal */
  .alertText {
    font-family: "RakutenRoundBold";
    font-size: 0.813rem;
    text-align: center;
    color: tomato;
  }

  .disclaimerMessage {
    font-family: "RakutenRoundRegular";
    font-size: 0.813rem;
    text-align: left;
    color: #333333;
    margin-left: 1.25rem;
    margin-top: 1.063rem;
  }

  .modal {
    margin-left: -18.75rem;
    min-width: 66.313rem;
    min-height: 37.125rem;
    align-content: center;
    border-radius: 0.5rem;
  }

  .functionalBox {
    width: 61.938rem;
    min-height: 22.813rem;
    position: relative;
    margin-left: 1.25rem;
    display: inline-block;
    background-color: #f7f7f7;
    white-space: initial;
    border-radius: 0.5rem;
  }

  .boldTitle {
    font-family: "RakutenRoundSemiBold";
    font-size: 1.5rem;
    line-height: 3.063rem;
  }

  .boldSubtitle {
    font-family: "RakutenRoundSemiBold";
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .title {
    font-family: "RakutenRoundRegular";
    font-size: 1.5rem;
    margin-left: 1.25rem;
    line-height: 3.063rem;
    margin-bottom: -0.625rem;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .body {
    border-top: none;
  }

  .footer {
    border-top: none;
  }

  .header {
    border-bottom: none;
  }

  .footerButton {
    margin-top: 2.125rem;
    margin-left: 28.875rem;
    margin-bottom: 2.125rem;
  }

  .importExcel {
    display: flex;
    justify-content: center;
    margin-top: 1.563rem;
    height: 1.0625rem;
    color: #000000;
    font-family: "RakutenRoundRegular";
    font-size: 0.875rem;
    letter-spacing: 0;
    line-height: 1.0625rem;
    text-align: "center";
  }

  .sessionList-tabs {
    width: 15rem;
    position: relative;
    left: 0rem;

    .ant-tabs-tab-btn {
      font-family: "RakutenRoundRegular";
      font-size: 1rem;
      font-weight: 600;
      display: flex;
      align-items: center;
    }

    .ant-tabs-nav .ant-tabs-nav-wrap {
      overflow: visible;
    }

    .ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $primary !important;
    }

    .ant-tabs-tab .ant-tabs-tab-btn:hover {
      color: $primary !important;
    }

    .ant-tabs-nav-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .ant-tabs-tab {
      width: 45%;
      justify-content: center;
    }

    .ant-tabs-ink-bar {
      width: 5rem;
      border-bottom: 3px solid $primary;
      border-radius: 5px;
    }
  }

  .sessionList_header {
    width: 84rem;
    margin-top: 2rem;
  }

  .sessionList-table {
    width: 83rem;
    margin-right: 2rem;
  }

  .sessionList-crumbs {
    width: 83rem;
    font-family: "RakutenRoundRegular";
  }

  .sessionEmail-submitButton {
    width: 8rem;
    height: 2.3rem;
    font-family: "RakutenRoundRegular";
    font-size: 1.2rem;
    border-radius: 2px;
    background-color: $primary;
    border: none;
    color: #ffffff;
  }

  .sessionEmail-cancelButton {
    width: 8rem;
    margin-right: 7rem;
    height: 2.3rem;
    font-family: "RakutenRoundRegular";
    font-size: 1.2rem;
    border-radius: 2px;
    background-color: #adb0b1;
    border: none;
    color: #ffffff;
  }

  .mailToUserTitle {
    color: #000000;
    font-family: "RakutenRoundSemiBold";
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 3.063rem;
  }

  .textFields {
    box-sizing: border-box;
    height: 3.125rem;
    width: 56rem;
    border: 0.063rem solid #9c9c9c;
    border-radius: 0.375rem;
  }

  .modal {
    min-width: 60rem;
    min-height: 37.125rem;
    align-content: center;
    border-radius: 0.5rem;
  }

  .cancelButton {
    height: 2.871rem;
    width: 10rem;
    border-radius: 0.25rem;
  }

  .submitButton {
    margin-left: 0rem !important;
    margin-top: 0rem !important;
    height: 2.871rem;
    width: 10rem;
    border-radius: 0.25rem;
    background-color: $primary;
    font-family: "RakutenRoundRegular";
  }

  .note {
    color: #000000;
    font-family: "RakutenRoundRegular";
    font-size: 0.875rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.063rem;
  }

  .custom_toggle {
    width: 14.125rem;
    height: 2.625rem;
    background-color: #d2eaf6;
    border-radius: 0.375rem;
    margin-right: 1.473rem;
    font-family: "RakutenRoundRegular";
  }

  .icon_box {
    background-color: $primary;
    max-width: 2rem;
    height: 2rem;
    margin-top: 0.3125rem;
    margin-left: 0.3125rem;
    border-radius: 0.25rem;
    padding-left: 0.375rem;
  }

  .toggle_title {
    font-family: "RakutenRoundSemiBold";
    font-size: 0.8125rem;
    font-weight: 600;
    line-height: 0.875rem;
    letter-spacing: 0;
    margin-top: 0.8rem;
    margin-left: 0.589375rem;
    padding: 0;
    max-width: 10.375rem;
    color: #019ff0;
  }

  .quillEmail {
    width: 56.25rem;
    box-sizing: border-box;
    background-color: white;
    height: 21.375rem;
  }

  .sessionList-numberButton {
    background-color: none;
    border-radius: 4px;
    font-size: 1.1rem;
    font-weight: 900;
    padding: 2px;
  }

  .sessionList-iconButton {
    border: none;
    background-color: none;
    color: #000000;
    border-radius: 4px;
    font-size: 1.3rem;
    font-weight: 900;
    padding: 2px;
  }
}

@media screen and (max-width: 1400px) {
  .sessionList-table {
    width: 73rem;
    padding-left: 2rem;
  }

  .sessionList_header {
    width: 72rem;
  }

  .sessionList-crumbs {
    width: 71rem;
  }
}
