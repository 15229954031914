.secButton {
	width: 10.875rem;
}

.primryButton {
	width: 10.875rem;
}

.topColumn {
	padding: 0;
	margin-right: 1.4375rem;
}

.textField {
	height: 2.875rem;
	max-width: 26.5625rem;
	font-family: "RakutenRoundSemiBold";
	font-size: 1rem;
	font-weight: 600;
	margin-bottom: 2.46875rem;
}
