@import "../../../variables.scss";

.ant-table-cell {
  width: 16rem;
}

.table-row-IL {
  background-color: #e5f5fd;
}

.table-row-EL {
  background-color: #e8e8e8;
}

.table-row-UDEMY {
  background-color: #EA52521A;
}

.table-row-private-IL {
  background-color: $secondary-with-opacity;
}

.inner-table-content {
  .ant-table table {
    border-spacing: 0 8px;
  }
}

.record-title {
  color: $primary;
  font-size: 14px;
  font-family: "RakutenRoundSemiBold";
}

.el-course-name {
  color: black;
}

.il-course-name {
  color: $primary;
  font-size: 14px;
}

.il-private-course-name {
  color: $secondary;
  font-size: 14px;
}

.ant-table-cell {
  .ant-table-wrapper {
    .ant-spin-nested-loading {
      .ant-spin-container {
        .ant-table {
          margin-left: 0px !important;
          padding-right: 16px;
          padding-top: 16px;
        }
      }
    }
  }
}

.learner-search {
  .ant-input-group-wrapper {
    .ant-input-wrapper {
      .ant-input {
        border-radius: 0px;
      }

      .ant-input-group-addon {

        .ant-input-search-button,
        .ant-btn-icon-only,
        .ant-btn-default {
          border-radius: 0px;
        }
      }
    }
  }
}

.expand-icon {
  padding: 12px;
  height: 100%;
  cursor: pointer;
}

.global-search {
  height: 100%;
  border-radius: 4px;
}