.searchActive {
  position: relative;

  height: 2.7rem;
  background-color: white;
  border-radius: 2px;
  transition: 0.5s;
  z-index: 999;
  box-shadow: 0 0 0 1px black;
  overflow: hidden;
  font-family: "RakutenRoundRegular";
}

.searchInactive {
  position: relative;
  width: 2rem;
  height: 2.7rem;
  border-radius: 2px;
  transition: 0.5s;
  z-index: 999;
  float: right;
  overflow: hidden;
}

.searchActiveTransparent {
  position: relative;
  height: 2.7rem;
  border-radius: 2px;
  transition: 0.5s;
  z-index: 1000;
  box-shadow: 0 0 0 1px white;
  overflow: hidden;
  background-color: white;
}

.searchInactiveTransparent {
  position: relative;
  width: 2rem;
  height: 2.7rem;
  border-radius: 2px;
  transition: 0.5s;
  z-index: 999;
  float: right;
  overflow: hidden;
}

.searchIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 2rem;
  height: 2.7rem;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
}

.searchInput {
  position: relative;
  width: 16rem;
  height: 2.7rem;
  display: flex;
}
.searchInputText {
  position: absolute;
  top: 0;
  width: 80%;
  height: 100%;
  border: none;
  outline: navajowhite;
  font-size: .8125rem;
  background: transparent;
  margin-left: 2.25rem;
}
.clearInput {
  position: absolute;
  top: 25%;
  right: 2%;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

.searchButtonIcon {
  height: 60%;
  width: 60%;
}

.pathLinkStyle {
  color: #000000 !important;
  z-index: 999;
}

.closeButton {
  display: "inline-table"
}

.cursorStyle {
  cursor: "pointer";
}

.cursorDefault {
  cursor: "default";
}
