.pageSubTitle{
    margin: 0 0 1em 0;
    font-size: 16px;
}

.courseId, .sessionId {
    font-family: "RakutenRoundSemiBold";
}

.courseName, .sessionName {
    font-family: "RakutenRoundRegular";
    word-wrap: break-word;
}