.baseLine {
	min-width: 3em;
	height: 0.25em;
	background-color: #ebebeb;
	border-radius: 0.125rem;
}

.progressLine {
	height: 100%;
}

.countValue {
	font-family: "RakutenRoundSemiBold";
	font-size: 1.125rem;
	display: flex;
	justify-content: center;
}

.currentCount {
	font-family: "RakutenRoundRegular";
}

.minCountValue {
	font-family: "RakutenRoundRegular";
	font-size: 0.8125rem;
}

.outerContainer {
	cursor: pointer;
}

.iconStyle {
	padding: 0.3rem 0.5rem 0rem;
	text-align: center;
}

.container {
	display: flex;
	flex-direction: column;
	background-color: #fff;
	padding: 0.5rem;
	border-radius: 0.5rem;
}