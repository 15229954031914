.rowStyle {
  margin-top: 0rem;
  margin-left: 0rem;
}

.textField {
  height: 3rem;
}

.secButton {
  width: 10.5rem;
  border: 0.0625rem solid #019ff0;
  color: #019ff0;
  background-color: white;
}

.primrySubButton {
  width: 10.5rem;
}

.countryRow {
  margin-top: -7.5rem;
  margin-left: 0rem;
}

.biggerSvgBox {
  width: 14.875rem;
  height: 14.875rem;
  border-radius: 50%;
  background: #d8d8d8;
  border: 1px solid #979797;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  clip-path: circle();
}

.biggerSvg {
  cursor: pointer;
}

.imageText {
  color: #000000;
  font-family: "RakutenRoundRegular";
  font-size: 14px;
  text-align: center;
  margin: 8px;
  margin-top: 24px;
}

.image {
  max-width: 26.5rem;
  height: 17.1875rem;
  margin-top: 2rem;
  border-radius: 0.3125rem;
}

.pathCoursesBox {
  overflow: scroll;
  width: 100%;
  border: 1px solid rgb(211, 202, 202);
  border-radius: 2px;
  background-color: white;
}

.pathCoursesInternalBox {
  height: 7rem;
  width: 85%;
  margin: 4rem 3rem;
  border: 1px dashed rgb(211, 202, 202);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
}

.editButton {
  height: 2.0625rem;
  width: 2.0625rem;
  margin-left: 14.125rem;
  position: absolute;
  border: 0.125rem solid white;
  border-radius: 50%;
  background-color: white;
  top: 2.875rem;
  cursor: pointer;
}

.addCourse {
  cursor: pointer;
}
.textBoxWrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.containerSmall {
  height: 83rem;
}
.containerBig {
  min-height: 85rem;
  height: 92rem;
}

.textBoxWrapper :nth-child(1) {
  order: 1;
}
.textBoxWrapper :nth-child(2) {
  order: 5;
}
.textBoxWrapper :nth-child(3) {
  order: 2;
}
.textBoxWrapper :nth-child(4) {
  order: 6;
}
.textBoxWrapper :nth-child(5) {
  order: 3;
}
.textBoxWrapper :nth-child(6) {
  order: 7;
}
.textBoxWrapper :nth-child(7) {
  order: 4;
}

.textBoxContent {
  width: 100%;
  padding: 1rem;
}
.addPathCourses {
  align-self: flex-start;
  margin-left: 3rem;
  margin-bottom: 3rem;
}
.addCourseText {
  margin-left: 1rem;
}
.customToggleCss {
  background-color: #d2eaf6;

  border-radius: 6px;
  margin-right: 23.57px;
  font-family: "RakutenRoundRegular";
  cursor: pointer;
  float: right;
}

.titleRow {
  margin-left: 0rem;
}

.infoImg {
  width: 3.5rem;
  height: 3.5rem;
  position: absolute;
  margin-left: 0.25rem;
  margin-top: 0.25rem;
}

.sequential {
  display: flex;
  align-items: center;
}

.toggleTitle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
