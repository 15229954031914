.secondaryButton {
	background-color: #828282;
	border-radius: 0.25rem;
	font-family: "RakutenRoundSemiBold";
	font-size: 1rem;
	line-height: 1.25rem;
	color: #ffffff;
	padding: 0.7rem 1.5rem;
	border: none;
	text-transform: uppercase;
}

.iconStyle {
	padding: 0.25em;
}
@media screen and (max-width: 476px) {
	.secondaryButton{
	font-size: 14px;
	margin-top: 1rem;
	margin-bottom: 0.7rem;
	}
}