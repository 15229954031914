@import "../../variables.scss";

@media screen {
  .primaryFilledOutlineButtonButton {
    background-color: white;
    border-radius: 0rem;
    font-family: "RakutenRoundSemiBold";
    font-size: 1rem;
    line-height: 1.25rem;
    color: $primary;
    padding: 0.7rem 1.5rem;
    border: 1px solid $primary;
    border-radius: 7px;
    text-transform: uppercase;
    /* width: 200px; */
    height: 48px;
    margin-top: 15px;
    display: flex;
  }

  .primaryFilledOutlineiconStyle {
    padding-left: 0.25em;
    display: flex;
    top: 5px;
  }
}

@media screen and (max-width: 476px) {
  .primaryFilledOutlineButtonButton {
    background-color: #e6f6fe;
    border-radius: 4px;
    font-family: "RakutenRoundSemiBold";
    padding: 0.7rem 0.8rem;
    font-size: 1rem;
    line-height: 1.25rem;
    color: $primary;
    text-transform: uppercase;
    height: 40px;
    border: none;
    margin-top: 15px;
    margin-left: 16px;
    display: flex;
    text-indent: -13rem;
    overflow: hidden;
  }

  .primaryFilledOutlineiconStyle {
    padding-left: 0.25em;
    position: relative;
    display: flex;
    top: 0px;
    right: 2px;
  }
}
