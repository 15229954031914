.draggable-list-box {
  width: 34rem;
  height: 7.9rem;
  margin-bottom: 1.5rem;
  border-radius: 0.5rem;
  background-color: #e5f5fd;
}

.udemy {
  background-color: #EA52521A;
}
.draggable-box-shadow {
  box-shadow: 0 0 32rem #666;
}
