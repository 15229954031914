.topColumn {
	padding: 0;
	margin-right: 1.4375rem;
}

.innerColumn {
	padding: 0;
	margin: 0;
}

.right {
	margin: 0 !important;
	padding: 0 !important;
}

.textField {
	height: 2.875rem;
	max-width: 26.5625rem;
	font-family: "RakutenRoundSemiBold";
	font-size: 1rem;
	font-weight: 600;
	margin-bottom: 2.46875rem;
}

.image {
	max-width: 26.5rem;
	height: 17.1875rem;
	margin-top: 2rem;
	border-radius: 0.3125rem;
}

.url {
	max-width: 23.375rem;
	margin-top: 0.90625rem;
	margin-left: -10rem;
}

.box_image {
	height: 5.5rem;
	width: 5.3125rem;
	/* margin-top: 4.3125rem;
	margin-left: 4.4375rem; */
	background-color: "cornflowerblue";
	border-radius: 0.375rem;
}

/* .primryButton {
	width: 10.875rem;
} */
.adminelUpdateButton{ 
	background-color: #00a0f0;
    font-family: "RakutenRoundSemiBold";
    color: #ffffff;
    border-radius: 0.25rem;
	border-top-right-radius: 0.25rem !important;
	border-bottom-right-radius: 0.25rem !important;
    text-transform: uppercase;
}

.secButton {
	width: 10.875rem;
	border: 0.0625rem solid #019ff0;
	color: #019ff0;
	background-color: white;
}

.editButton {
	height: 2.0625rem;
	width: 2.0625rem;
	margin-left: 15.125rem;
	position: absolute;
	border: 0.125rem solid white;
	border-radius: 50%;
	background-color: white;
	top: 2.875rem;
	right: 1.0625rem;
	cursor: pointer;
}

.biggerSvgBox {
	background-color: #cdced0;
	top: 2.875rem;
	right: 1.0625rem;
	margin-top: -0.75rem;
	height: 17.625rem;
	border-radius: 0.3125rem;
}

.biggerSvg {
	margin-top: 6.375rem;
	margin-left: 9.25rem;
	cursor: pointer;
}

.imageText {
	color: #000000;
	font-family: "RakutenRoundRegular";
	font-size: 0.875rem;
	letter-spacing: 0;
	line-height: 1.0625rem;
	text-align: center;
	margin-top: 5.3125rem;
}

.courseOriginTitle {
	color: #000000;
	font-family: "RakutenRoundSemiBold";
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.25rem;
}

.courseOriginTitleOuterBox {
	margin-left: 2.375rem;
	max-width: 13.125rem;
	padding: 0;
}

.adminNotes {
	max-width: 82.5rem;
	margin-bottom: 2rem;
}
.languageBox {
	/* height: 5.275rem; */
	margin-bottom: 2.369rem;
	/* height: auto; */
}
.languageBox  > div:first-of-type {
	min-height: 2.875rem;
}