@import "variables";

body {
  .ant-btn {
    .ant-btn-primary:hover {
      background-color: $primary !important;
      .primary-button-learning-course-list:hover {
        background-color: $primary !important;
      }
    }
  }
}

:global(.ant-btn-primary) {
  background-color: $primary;
  &:hover {
    background-color: $primary !important;
    border-color: $primary !important;
  }
}
