.footer {
  margin-top: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 0.625rem;
}

.header-row {
  margin-left: -0.938rem;
  display: flex;
  justify-content: space-between;
}

.slick-list {
  padding: 1rem 1rem 1rem 0.2rem;
}

.card {
  margin-bottom: 0.625rem;
}

.slider {
  width: 100%;
  height: 100%;
  margin-left: 1rem;
}

.customSelectILHome {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  flex-wrap: wrap;
  margin: 0rem;
  padding: 0rem;
  width: 19.5rem;
}

.customSelectILHome__control {
  border-radius: 0px !important;
  width: 19.5rem !important;
  height: 0.0625rem !important;
  border: 0.0625rem solid #000000 !important;
  flex-wrap: wrap;
}

.customSelectILHome__value-container {
  color: black !important;
  font-family: "RakutenRoundRegular";
  font-size: 13px;
}

.last-slider {
  position: absolute;
  // top: 0;
}

@media screen {
  .slider-card {
    width: 19.5rem !important;
    margin-right: 1.5rem;
  }

  .view-all {
    margin-right: 0.7rem;
    font-family: "RakutenRoundRegular";
    font-size: 14px;
    line-height: 24px;
  }

  .instructorLed {
    background-color: #ffffff;
  }

  .heading {
    color: #000000;
    font-family: "RakutenRoundRegular";
    font-size: 2.25rem;
    letter-spacing: 0;
    line-height: 2.125rem;
    padding-left: 0rem;
    margin-left: 1rem;
    margin-bottom: 2rem;
  }

  .column {
    margin-right: 0.2rem;
    display: flex;
    justify-content: flex-end;
  }

  .il-courses {
    display: flex;
    width: 220rem;
  }
}

@media screen and (min-width: 1790px) {
  .slider-card {
    width: 19.1rem !important;
  }
}

@media screen and (max-width: 476px) {
  .slider-card {
    margin-right: 1.5rem;
  }

  .mobile-card {
    margin-bottom: 0.625rem;
    margin-right: 0.3rem;
    margin-left: 0.5rem;
  }

  .instructorLed {
    background-color: #ffffff;
  }

  .heading {
    color: #000000;
    font-family: "RakutenRoundSemiBold";
    font-size: 1.375rem;
    letter-spacing: 0;
    line-height: 2.125rem;
    padding-left: 0.5rem;
    margin: 1rem;
  }

  .column {
    margin-right: 0.2rem;
    display: flex;
    justify-content: flex-end;
    padding-right: 0px;
    margin-top: 1.3rem;
  }

  .il-courses-mobile {
    display: flex;
    overflow: hidden;
    overflow-x: scroll;
  }
}
