.alertText {
  font-family: "RakutenRoundBold";
  font-size: 0.813rem;
  text-align: center;
  color: tomato;
}
.alertTextHide {
  font-family: "RakutenRoundBold";
  font-size: 0.813rem;
  text-align: center;
  color: white;
}

.disclaimerMessage {
  font-family: "RakutenRoundRegular";
  font-size: 0.813rem;
  text-align: left;
  color: #333333;
  margin-left: 1.25rem;
  margin-top: 1.063rem;
}
.modal {
  margin-left: -18.75rem;
  min-width: 66.313rem;
  max-height: 38.125rem;
  /* max-height: 594px; */
  align-content: center;
  border-radius: 0.5rem;
}

.functionalBox {
  width: 61.938rem;
  min-height: 25.813rem;
  position: relative;
  margin-left: 1.25rem;
  display: inline-block;
  background-color: #f7f7f7;
  white-space: initial;
  border-radius: 0.5rem;
  font-family: "RakutenRoundRegular";
}

.boldTitle {
  font-family: "RakutenRoundSemiBold";
  font-size: 1.5rem;
  line-height: 3.063rem;
}
.boldSubtitle {
  font-family: "RakutenRoundSemiBold";
  font-size: 1rem;
  line-height: 1.25rem;
}
.title {
  font-family: "RakutenRoundRegular";
  font-size: 1.5rem;
  margin-left: 1.25rem;
  line-height: 3.063rem;
  margin-bottom: -0.625rem;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.body {
  border-top: none;
}

.cancelButton {
  margin-right: 1.5rem;
}
.assignButton {
  background-color: #00a0f0;
  font-family: "RakutenRoundSemiBold";
  color: #ffffff;
  border-radius: 0.25rem;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
  text-transform: uppercase;
}

.footer {
  border-top: none;
}

.header {
  border-bottom: none;
}

.footerButton {
  margin-top: 1rem;
  margin-left: 28.875rem;
  margin-bottom: 2.125rem;
}

.textBox {
  padding-top: 0.813rem;
  text-align: center;
}

.leftBox {
  min-width: 25.25rem;
  min-height: 13.938rem;
  margin-top: 0.5rem;
  display: inline-block;
  background-color: white;
  white-space: initial;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
}

.rightBox {
  position: relative;
  min-width: 25.25rem;
  min-height: 13.938rem;
  max-width: 25.25rem;
  max-height: 13.938rem;
  margin-top: 0.5rem;
  display: inline-block;
  background-color: white;
  white-space: initial;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
}
.innerBox {
  min-width: 56.875rem;
  min-height: 17.5rem;
  max-width: 56.875rem;
  max-height: 17.5rem;
  margin-left: 2.438rem;
  margin-top: 1.625rem;
  margin-bottom: 0.5rem;
}

.circleBox {
  border-radius: 50%;
  background-color: white;
  margin-left: 1.563rem;
  background: white;
  width: 2.75rem;
  height: 2.75rem;
  margin-right: 1.875rem;
  margin-top: 6.625rem;
  text-align: center;
  padding-top: 0.563rem;
}

.buttonBox {
  box-sizing: border-box;
  height: 6.563rem;
  width: 22.188rem;
  border: 1px dashed #9c9c9c;
  border-radius: 4px;
  background-color: #f7f7f7;
  margin-left: 1.531rem;
  margin-top: 2.219rem;
}

.browseButton {
  margin-left: 6.848rem;
  margin-top: 1.406rem;
  margin-bottom: 0.938rem;
}

.footText {
  font-family: "RakutenRoundRegular";
  font-size: 0.75rem;
  text-align: center;
}

.fileBox {
  margin-left: 1.434rem;
  margin-top: 1.219rem;
  width: 22.125rem;
  min-height: 2.75rem;
  border-radius: 4px;
  background-color: #ebebeb;
}

.fileText {
  height: 1.063rem;
  width: 16.938rem;
  color: #000000;
  font-family: "RakutenRoundRegular";
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.063rem;
  margin-left: 2.188rem;
  margin-top: 0.875rem;
  position: absolute;
}

.textIcon {
  height: 1.25rem;
  width: 1.25rem;
  margin-top: 0.75rem;
  margin-left: 0.375rem;
  position: absolute;
}

.leftTitle {
  color: #000000;
  font-family: "RakutenRoundRegular";
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.063rem;
}

.form {
  margin-top: 1.281rem;
  max-width: 56.875rem;
  max-height: 11.5rem;
  resize: none;
  border-style: none;
  font-family: "RakutenRoundRegular";
}

.expectedCompletion {
  margin-left: 20%;
  margin-top: 1rem;
  position: absolute;
  display: flex;
  justify-content: center;
}
