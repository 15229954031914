@import "../../../variables.scss";
.fillingFast {
  display: inline-block;
  margin-left: 0rem;
  vertical-align: top;
  width: 21.1875rem;
  margin-top: 1.25rem;
}

.coursesInProgress {
  display: flex;
  width: 70%;
  // width: 58.125rem;
  height: 22.5rem;
  // margin-left: 2.7rem;
}

@media screen {
  .enrollment-course-container {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .instructor-led-container,
  .elearning-container,
  .lp-container {
    margin: 0rem;
    padding: 3rem;
    background-color: white;
    padding-bottom: 0rem;
  }

  .home-secondContainer {
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
  }
}

@media screen and (min-width: 4320px) and (max-width: 5760px) {
  .fillingFast {
    margin-left: 5.7rem;
  }

  .instructor-led-container,
  .elearning-container,
  .lp-container,
  .enrollment-course-container {
    padding-left: 35%;
    padding-right: 35%;
  }
}

@media screen and (min-width: 3000px) and (max-width: 4320px) {
  .fillingFast {
    margin-left: 5.7rem;
  }

  .instructor-led-container,
  .elearning-container,
  .lp-container,
  .enrollment-course-container {
    padding-left: 30%;
    padding-right: 30%;
  }
}

@media screen and (min-width: 3000px) and (max-width: 3300px) {
  .fillingFast {
    margin-left: 5.7rem;
  }

  .instructor-led-container,
  .elearning-container,
  .lp-container,
  .enrollment-course-container {
    padding-left: 27%;
    padding-right: 27%;
  }
}

@media screen and (min-width: 2880px) and (max-width: 3000px) {
  .fillingFast {
    margin-left: 5.7rem;
  }

  .instructor-led-container,
  .elearning-container,
  .lp-container,
  .enrollment-course-container {
    padding-left: 25%;
    padding-right: 25%;
  }
}

@media screen and (min-width: 2160px) and (max-width: 2880px) {
  .fillingFast {
    margin-left: 5.7rem;
  }

  .instructor-led-container,
  .elearning-container,
  .lp-container,
  .enrollment-course-container {
    padding-left: 20%;
    padding-right: 20%;
  }
}

@media screen and (min-width: 1800px) and (max-width: 2160px) {
  .fillingFast {
    margin-left: 5.7rem;
  }

  .instructor-led-container,
  .elearning-container,
  .lp-container,
  .enrollment-course-container {
    padding-left: 15%;
    padding-right: 15%;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1800px) {
  .fillingFast {
    margin-left: 5.7rem;
  }

  .instructor-led-container,
  .elearning-container,
  .lp-container,
  .enrollment-course-container {
    padding-left: 10%;
    padding-right: 10%;
  }
}

@media screen and (min-width: 1309px) and (max-width: 1439px) {
  .fillingFast {
    margin-left: 0.7rem;
  }

  .instructor-led-container,
  .elearning-container,
  .lp-container {
    padding-left: 12%;
    padding-right: 12%;
  }

  .enrollment-course-container {
    padding-left: 0%;
    padding-right: 0%;
  }

  .home-secondContainer {
    // margin-left: 3rem;
  }
}

@media screen and (min-width: 1250px) and (max-width: 1300px) {
  .coursesInProgress {
    display: flex;
    width: 68%;
    height: 22.5rem;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1250px) {
  .coursesInProgress {
    display: flex;
    width: 67%;
    height: 22.5rem;
  }
}

@media screen and (max-width: 476px) {
  .fillingFast {
    display: inline-block;
    margin-left: 0rem;
    vertical-align: top;
    width: 21.1875rem;
    margin-top: -1rem;
  }

  .coursesInProgress {
    display: inline-block;
    width: 23.625rem;
    height: 13.5rem;
    margin-left: 2rem;
  }

  .enrollment-course-container {
    display: block;
    margin-top: 0rem;
  }

  .instructor-led-container {
    margin: 1rem;
    width: 23rem;
    overflow: hidden;
  }

  .instructor-led-container,
  .elearning-container,
  .lp-container {
    margin: 0rem;
    padding: 0.5rem;
    background-color: white;
    width: 24rem;
  }

  .home-secondContainer {
    width: 23.5rem !important;
    margin: 0rem;
    padding: 0rem;
    padding-bottom: 0.5rem;
    background-color: white;
  }
}

.common-button-background-color {
  background: $primary;
}

.common-button-background-color:hover {
  background: $primary !important;
}
