@media screen {
  .card {
    border-radius: 0.5rem;
    border-color: #f7f7f7;
    background-color: #f7f7f7;
    width: 19.5rem;
    min-height: 22.25rem;
    max-height: 22.25rem;
    box-shadow: 0 0.125rem 0.25rem 0 #d1d1d1;
    margin-right: 0.3125rem;
    z-index: 0;
  }

  .duration {
    margin-left: 1rem;
    padding-left: 1rem;
    font-family: "RakutenRoundRegular";
    font-size: 0.875rem;
    line-height: 1.438rem;
    overflow: hidden;
    white-space: nowrap;
  }

  .cardTitle {
    width: 15.5rem;
    height: 3.75rem;
    font-size: 1.5rem !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-left: 1rem;
    letter-spacing: 0;
    line-height: 1.875rem;
    font-family: "RakutenRoundSemiBold";
    color: #000000 !important;
  }

  .il-courseProvider {
    margin-left: 0rem;
    padding-left: 1rem;
    margin-top: 0.438rem;
    margin-right: 0rem;
    padding-right: 0rem;
    font-family: "RakutenRoundRegular";
    color: #000000 !important;
    font-size: 0.75rem;
    line-height: 1.063rem;
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
   
    
    // -webkit-line-clamp: 1;
    // -webkit-box-orient: vertical;
  }

  .il-courseProviderIcon {
    height: 0.875rem;
    width: 0.813rem;
    margin-right: 0.125rem;
    margin-top: -0.13rem;
    display: flex;
    flex-direction: row;
  }

  .il-courseProviderIcon-container {
    margin-top: 0.3rem;
    margin-right: 0.2rem;
  }
}

@media screen and (max-width: 476px) {
  .card {
    border-radius: 0.5rem;
    border-color: #f7f7f7;
    background-color: #f7f7f7;
    width: 21.5rem;
    width: 20.5rem;
    min-height: 22.25rem;
    max-height: 22.25rem;
    box-shadow: 0 0.125rem 0.25rem 0 #d1d1d1;
    margin-right: 0.3125rem;
    z-index: 0;
  }

  .duration {
    margin-left: 1rem;
    padding-left: 1rem;
    font-family: "RakutenRoundRegular";
    font-size: 0.875rem;
    line-height: 1.438rem;
    overflow: hidden;
    white-space: nowrap;
  }

  .cardTitle {
    width: 15.5rem;
    height: 3.75rem;
    font-size: 1.5rem !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-left: 1rem;
    letter-spacing: 0;
    line-height: 1.875rem;
    font-family: "RakutenRoundSemiBold";
    color: #000000 !important;
  }

  .il-courseProvider {
    display: flex;
    flex-direction: row;
    margin-left: 1rem;
    padding-left: 0rem;
    margin-top: 0.938rem;
    margin-right: 0rem;
    padding-right: 0rem;
    font-family: "RakutenRoundRegular";
    color: #000000 !important;
    font-size: 0.75rem;
    line-height: 1.063rem;
    overflow: hidden;
    text-overflow: ellipsis;
    // display: flex;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .il-courseProviderIcon {
    height: 0.875rem;
    width: 0.813rem;
    margin-right: 0.5rem;
    margin-top: 0.2rem;
    display: flex;
    flex-direction: row;
  }

  .el-courseProviderIcon-container {
    margin-top: 0.3rem;
    margin-right: 0.2rem;
  }
}

.cardLP {
  border-radius: 0.5rem;
  border-color: #f7f7f7;
  background-color: #f7f7f7;
  width: 19.5rem;
  min-height: 22.25rem;
  max-height: 22.25rem;
  box-shadow: 0 0.125rem 0.25rem 0 #d1d1d1;
  margin: 1rem;
  z-index: 0;
}

.card:hover {
  box-shadow: 0 0.125rem 0.75rem 0 #d1d1d1;
}

.cardTitle {
  width: 15.5rem;
  height: 3.75rem;
  font-size: 1.5rem !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-left: 1rem;
  letter-spacing: 0;
  line-height: 1.875rem;
  font-family: "RakutenRoundSemiBold";
  color: #000000 !important;
}

.spanData {
  width: 13.75rem;
}

.cardDiv {
  margin-right: 0.625rem;
  padding-right: 0rem;
}

.instructorledIcon {
  height: 0.875rem;
  width: 1.438rem;
  margin-left: 1rem;
  margin-top: 1.519rem;
}

.il-courseProviderIcon {
  height: 0.875rem;
  width: 0.813rem;
  margin-right: 0.125rem;
  margin-top: 0rem;
  display: flex;
  flex-direction: row;
}
.il-courseProviderIcon-container {
  margin-top: 0.1rem;
  margin-right: 0.2rem;
}
.overlay {
  padding: 0;
  display: flex;
  flex-flow: row;
}

.body {
  padding-left: 0;
  padding-top: 0.5rem;
  margin-bottom: 1.5rem;
  color: #333333;
}
.textData {
  width: 12.5rem;
  margin-top: 0.75rem;
  margin-left: 1rem;
  padding-left: 0rem;
  padding-top: 0rem;
  font-family: "RakutenRoundRegular";
  font-size: 0.875rem;
  line-height: 1.438rem;
}

.courseTileOverlayText {
  margin-top: 0.3rem;
  margin-left: 0.6rem;
  font-family: "RakutenRoundRegular";
  font-size: 0.75rem;
  line-height: 2.5rem;
  letter-spacing: 0;
  color: #ffffff;
}

.imageDiv {
  background-color: black;
  border-radius: 0.5rem 0.5rem 0 0;
}
.image {
  border-radius: 0.5rem 0.5rem 0 0;
  opacity: 0.7;
}

.roundProgressBar {
  margin-top: -1.75rem;
  margin-left: 0.2rem;
  padding-top: 0rem;
  height: 5rem;
  width: 5rem;
}
.assignedStatus {
  border-radius: 0.25rem;
  background-color: #bf0000;
  color: #ffffff;
  text-align: center;
  padding: 0.25rem 0.75rem;
  font-size: 0.75rem;
  margin-left: -5rem;
  
  text-transform: uppercase;
  font-family: "RakutenRoundRegular";
}
.cardOverlayText {
  margin-top: 1.25rem;
}

.circleProgressBar {
  width: 5rem;
  height: 5rem;
  cursor: pointer;
  float: right;
  margin-left: 0.3rem;
  margin-top: 1.15rem;
}

.addSelectCourse {
  cursor: pointer;
}

.wishlistImage {
  padding-top: 0.2rem;
  z-index: 2;
  right: 0;
  margin-top: 1rem;
  position: absolute;
  margin-right: 0.7rem;
}

.wishlistImage > img {
  width: 20px;
  height: 20px;
  display: inline !important;
}

.plusCircleStyle {
  position: absolute;
}
