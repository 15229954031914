.hero-row {
  margin-top: 5rem;
}

.hero-column {
  max-width: 1320px;
  margin: 0 auto;
}

.description-row {
  margin-top: 24px;
}

.breadcrumb-title {
  color: #ffffff;
}

.breadcrumb-title a,
.breadcrumb-title a:hover {
  color: #ffffff;
  text-decoration: none;
  background-color: transparent;
}

@media screen {
  .title {
    color: #ffffff;
    font-family: "RakutenRoundRegular";
    height: 64px;
    top: calc(50% - 64px / 2 - 39px);
    font-style: normal;
    font-weight: 600;
    font-size: 56px;
    line-height: 64px;
    margin-left: 0rem;
  }

  .title-row {
    margin-top: 120px;
    margin-bottom: 10px;
  }

  .description {
    color: #ffffff;
    font-family: "RakutenRoundRegular";
    height: 68px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .breadCrumb {
    margin-top: 40px;
    height: 18px;
    font-family: "RakutenRoundRegular";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
}

@media screen and (min-width: 1340px) and (max-width: 1440px) {
  .hero-column {
    max-width: 1320px;
    margin: 0 auto;
    margin-left: 3rem;
  }
}

@media screen and (min-width: 1301px) and (max-width: 1340px) {
  .hero-column {
    max-width: 1320px;
    margin: 0 auto;
    margin-left: 3rem;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .hero-column {
    max-width: 1320px;
    margin: 0 auto;
    margin-left: 3rem;
  }
}

@media screen and (min-width: 1150px) and (max-width: 1199px) {
  .hero-column {
    max-width: 1320px;
    margin: 0 auto;
    margin-left: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .title {
    color: #ffffff;
    font-family: "RakutenRoundRegular";
    height: 64px;
    top: calc(50% - 64px / 2 - 39px);
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    line-height: 64px;
    padding-left: 1rem;
  }

  .title-row {
    margin-top: 3rem;
    margin-bottom: 10px;
  }

  .hero-row {
    margin-top: 0rem;
  }

  .description {
    color: #ffffff;
    font-family: "RakutenRoundRegular";
    height: 68px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    flex-basis: 23rem;
    /* margin-left: 0.5rem; */
  }

  .breadCrumb {
    margin-top: 40px;
    height: 18px;
    font-family: "RakutenRoundRegular";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin-left: 1rem;
  }
}