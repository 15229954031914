@import "../../../variables.scss";

/* .quill {
  max-width: 60rem !important;
  box-sizing: border-box;
  height: 14.25rem;
  border: 0.063rem solid #9c9c9c;
  border-radius: 0.375rem;
  background-color: white;
}

.ql-container {
  box-sizing: border-box;
  font-family: "RakutenRoundRegular";
  font-size: 0.813rem;
  height: 11.5rem;
  position: relative;
} */
.moduleAddEdit-submit {
  background-color: $primary !important;
}
.moduleAddEdit-addTrainerButton {
  background-color: $primary !important;
}

.crumb-color {
  color: $primary !important;
}
.moduleAddEdit-crumbs {
  font-family: "RakutenRoundRegular";
  width: 83rem;
  color: $primary;
}

.moduleAddEdi-pageTitle {
  font-family: "RakutenRoundSemiBold";
  font-size: 2.5rem;
  margin-top: 1rem;
  margin-bottom: 3rem;
  width: 83rem;
}

.moduleAddEdit-addTrainerButton {
  background-color: #00a0f0;
  color: #ffffff;
  font-family: "RakutenRoundRegular";
}

.moduleAddEdit-fields {
  box-sizing: border-box;
  height: 2.875rem;
  width: 17.229rem;
  border: 0.063rem solid #9c9c9c;
  border-radius: 0.375rem;
  background-color: #ffffff;
}

.moduleAddEdit-form {
  margin-left: auto;
  margin-right: auto;
  width: 85rem;
}

.moduleAddEdit-submit {
  height: 2.871rem;
  width: 10.875rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  font-family: "RakutenRoundSemiBold";
  background-color: #00a0f0;
  color: #ffffff;
  text-transform: uppercase;
}

.moduleAddEdit-delete {
  height: 2.871rem;
  width: 10.875rem;
  background-color: #828282;
  border-radius: 0.25rem;
  font-family: "RakutenRoundSemiBold";
  font-size: 1rem;
  line-height: 1.25rem;
  color: #ffffff;
  padding: 0.7rem 1.5rem;
  border: none;
  text-transform: uppercase;
}

.moduleAddEdit-input {
  font-family: "RakutenRoundRegular";
  height: 2.9rem;
  font-size: 1rem;
  margin-bottom: 2.5rem;
  border: 0.063rem solid #9c9c9c;
}

.moduleAddEdit-zoomInput {
  box-sizing: border-box;
  height: 2.875rem;
  border: 0.063rem solid #9c9c9c;
  border-radius: 0.375rem;
  background-color: #ffffff;
}

.moduleAddEdit-subtitle {
  width: 83rem;
}

@media screen and (max-width: 1400px) {
  .moduleAddEdit-form {
    width: 71rem;
  }

  .moduleAddEdit-crumbs {
    width: 71rem;
    color: $primary;
  }

  .moduleAddEdi-pageTitle {
    width: 71rem;
  }

  .moduleAddEdit-subtitle {
    width: 71rem;
  }
}
