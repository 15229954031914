.spinner {
	width: 10rem;
	height: 10rem;
}
.loaderContainer {
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	/* background-color: #f8f8f8ad; */
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1499;
}
.loader {
	left: 50%;
	top: 40%;
	z-index: 1500;
	position: absolute;
}

@media screen and (max-width: 476px) { 
	.spinner {
		width: 10rem;
		height: 10rem;
		margin-left: -5rem;
	}
	.loaderContainer {
		position: fixed;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		/* background-color: #f8f8f8ad; */
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 1000;
	}
	.loader {
		left: 50%;
		top: 40%;
		z-index: 1500;
		position: absolute;
	}
}