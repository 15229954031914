.course-additional-details-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding-bottom: 3rem;
}

.course-additional-details {
  width: 83%;
}

.classroom-schedules-footer {
  width: 100%;
}

.course-description-banner {
  margin-top: 80px;
  background-position: center;
  background-size: 100% 100%;
}

.private-course-banner {
  margin-top: -34px;
  background-position: center;
  /* background: url("../../../../assets/images/private.svg") no-repeat; */
  background-size: 100% 100%;
}

.accordion-header-content {
  padding-left: 16px;
}

@media screen {
  .path-courses {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 10%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .accordion-header-image {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .public-course-banner {
    margin-top: -32px;
    background-position: center;
    /* background: url("../../../../assets/images/public.svg") no-repeat; */
    background-size: 100% 100%;
  }
}

@media screen and (min-width: 4320px) {
  .course-additional-details {
    width: 22%;
  }
}

@media screen and (min-width: 3400px) and (max-width: 4319px) {
  .course-additional-details {
    width: 28%;
  }
}

@media screen and (min-width: 3200px) and (max-width: 3400px) {
  .course-additional-details {
    width: 30%;
  }
}

@media screen and (min-width: 3000px) and (max-width: 3200px) {
  .course-additional-details {
    width: 33%;
  }
}

@media screen and (min-width: 2880px) and (max-width: 3000px) {
  .course-additional-details {
    width: 34%;
  }
  .classroom-schedules-footer {
    width: 89%;
  }
}

@media screen and (min-width: 2160px) and (max-width: 2880px) {
  .course-additional-details {
    width: 36%;
  }
  .classroom-schedules-footer {
    width: 89%;
  }
}

@media screen and (min-width: 1920px) and (max-width: 2160px) {
  .course-additional-details {
    width: 52%;
  }
  .classroom-schedules-footer {
    width: 89%;
  }
}

@media screen and (min-width: 1800px) and (max-width: 1919px) {
  .course-additional-details {
    width: 59%;
  }
  .classroom-schedules-footer {
    width: 89%;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1799px) {
  .public-course-banner {
    margin-top: -2rem;
    padding-left: 4.5rem;
  }

  .secondContainer {
    margin-left: 15rem;
  }

  .course-additional-details {
    width: 64%;
  }
  .classroom-schedules-footer {
    width: 72%;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1600px) {
  .course-additional-details {
    width: 74%;
  }

  .classroom-schedules-footer {
    width: 72%;
  }
}

@media screen and (max-width: 1440px) {
  .course-additional-details {
    width: 82%;
  }
  .public-course-banner {
    padding-left: 0rem;
  }
}

@media screen and (max-width: 768px) {
  .path-courses {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 16px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .accordion-header-image {
    display: flex;
    align-items: center;
    justify-content: right;
    margin-left: 15px;
  }

  .public-course-banner {
    margin-top: -2rem;
    background-position: center;
    background: url("../../../../assets/images/public.svg") no-repeat;
    background-size: 100% 100%;
  }
}
@media screen and (max-width: 476px) {
  .public-course-banner {
    margin-top: -7rem;
    padding-left: 0rem;
  }
}
