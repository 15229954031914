.fc-button {
	border: none !important;
	background-color: #f7f7f7 !important;
	color: #019ff0 !important;
}

.fc-toolbar-chunk {
	display: flex;
}

tr {
	border: none;
}

.fc-list-event-title > .calendarOuter {
	width: 800px !important;
}

.fc-event-main > .calendarOuter {
	width: 100% !important;
}

.calendarLine {
	width: 100% !important;
}
