.secondContainer {
	width: 82.63625rem !important;
	margin: 0rem;
	padding: 0rem;
	padding-bottom: 7.5rem;
}

/* .searchResultInnerContainer {
	padding: 0;
	position: relative;
	background-color: transparent;
	padding-left: 0px;
	margin: auto;
	width: 81rem;
	margin-top: 7rem;
} */

.container {
	max-width: 82.5rem;
	background-color: white;
	padding: 0;
	position: relative;
	margin-top: 7rem;
}

.countText {
	height: 2.125rem;
	width: 54.4375rem;
	color: #000000;
	font-family: "RakutenRoundRegular";
	font-size: 0.875rem;
	letter-spacing: 0;
	line-height: 1.0625rem;
}

.ilGrid {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
}