.searchResult-innerContainer {
    padding: 0;
    position: relative;
    background-color: transparent;
    padding-left: 0px;
    margin: auto;
    width: 81rem;
    margin-top: 5rem;
}

@media screen and (max-width: 1400px) {
    .searchResult-innerContainer {
        width: 71rem;
    }
}