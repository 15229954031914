@import "../../../variables.scss";

.parent-container {
  margin: 3rem;
  margin-top: 5rem;
}

.em-heading {
  font-family: "RakutenRoundSemiBold";
  font-size: 2.5rem;
}

.em-tab-menu {
  margin: 1rem;
  cursor: pointer;
}

.em-tab-menu-container {
  display: flex;
  justify-content: center;
}

.em-selected-tab-menu {
  color: $primary;
  text-decoration: underline;
  text-decoration-color: $primary;
}

.em-tab-button {
  background-color: $primary;
}

.em-tab-button:hover {
  background-color: $primary !important;
}
