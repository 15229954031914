.react-bootstrap-table {
  font-family: "RakutenRoundRegular";
  font-size: 0.875rem;
  margin-top: -5rem;
}
.react-bootstrap-table *:focus,
.react-bootstrap-table-pagination *:focus {
  outline: none;
}
.table {
  border-collapse: separate;
  border-spacing: 0 0.5rem;
}
.table th {
  text-transform: uppercase;
  padding: 1.5rem 0.5rem;
  background-color: #ebebeb;
}

tr {
  border: 0;
  margin: 0.3125rem;
  border: 0.125rem solid #000;
  border-radius: 0.625rem;
  background-color: #fff;
}
.table thead th {
  vertical-align: middle;
  border-bottom: none;
}

.table td,
.table th {
  border-top: none;
  padding: 0.5rem;
  vertical-align: middle;
  word-break: break-word;
}
.tableSearch {
  position: absolute;
  top: 7.5em;
  left: 70em;
}
th.expand-cell-header {
  width: 3rem !important;
}
td.expand-cell {
  text-align: center;
}
.react-bootstrap-table td > .selection-input-4,
.react-bootstrap-table th > .selection-input-4 {
  margin: 0.5rem 0;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.125rem solid #000;
  border-radius: 0.25rem;
  font-family: "RakutenRoundSemiBold";
}
.selection-cell {
  text-align: center;
}
.page-item.active .page-link {
  z-index: 3;
  color: #000;
  background-color: #fff;
  border-color: #fff;
  border-radius: 50%;
  font-family: "RakutenRoundSemiBold";
  font-size: 0.875rem;
  border: none;
  outline: none;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -0.0625rem;
  line-height: 1.25;
  color: #000;
  background-color: transparent;
  border: none;
  font-family: "RakutenRoundRegular";
  font-size: 0.875rem;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: transparent;
  border-color: transparent;
  opacity: 0.4;
}
.react-bootstrap-table th .order-4:before,
.react-bootstrap-table th .caret-4-asc:before,
.react-bootstrap-table th .caret-4-desc:before {
  opacity: 0.4;
  margin-left: 0.25rem;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0.21875rem 0.375rem 0.21875rem;
  border-color: transparent transparent #000000 transparent;
  position: relative;
  top: -1rem;
  left: 0.0625rem;
}

.react-bootstrap-table th .order-4:after,
.react-bootstrap-table th .caret-4-asc:after,
.react-bootstrap-table th .caret-4-desc:after {
  opacity: 0.4;
  margin-left: 0.25rem;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.375rem 0.21875rem 0 0.21875rem;
  border-color: #000000 transparent transparent transparent;
  position: relative;
  top: 0.9375rem;
  left: -0.625rem;
}

.react-bootstrap-table th .caret-4-asc:before,
.react-bootstrap-table th .caret-4-desc:before,
.react-bootstrap-table th .caret-4-asc:after,
.react-bootstrap-table th .caret-4-desc:after {
  opacity: 1;
}

.row .react-bootstrap-table-pagination-list {
  position: relative;
  text-align: center;
}
