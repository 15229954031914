.custom_toggle {
  max-width: 12.5625rem;
  height: 2.875rem;
  background-color: white;
  border-radius: 0.375rem;
  border: 0.03125rem solid #545b622b;
  font-family: "RakutenRoundSemiBold";
  color: #495057;
  cursor: pointer;
}

.numberContainer {
  margin-top: 0.5625rem;
}

.firstColumn {
  margin-left: -0.4rem;
}

.counter {
  font-family: "RakutenRoundSemiBold" !important;
  color: #495057;
  margin-left: 0.75rem;
}

.text_field {
  padding: 0;
  margin: 0;
  border: none;
  width: 3.75rem;
  margin-top: -0.375rem;
  text-align: center;
}
