@import "../../variables.scss";

.title {
  font-family: "RakutenRoundRegular";
  font-size: 1.5rem;
  /* margin-left: 2.25rem;  */
  margin-bottom: 0.25rem;
}

.secondaryButtonStyle {
  width: 21rem;
  height: 2.7rem;
}

.modal {
  margin-left: -18.75rem;
  min-width: 66.313rem;
  min-height: 12.813rem;
}

.modal-titleBold {
  font-family: "RakutenRoundSemiBold";
  font-size: 1.5rem;
  margin-bottom: 1.25rem;
}

.header {
  border-bottom: none;
}

.buttonStyle {
  background-color: $primary;
  border-radius: 0.25rem;
  font-family: "RakutenRoundSemiBold";
  font-size: 1rem;
  line-height: 1.25rem;
  color: #ffffff;
  padding: 0.7rem 1.5rem;
  border: none;
  text-transform: uppercase;
  width: 21rem;
  height: 2.7rem;
  margin-top: 0rem;
  //   min-width: 10rem;
}

.secondaryButtonStyle {
  background-color: #9fa1a1;
  border-radius: 0.25rem;
  font-family: "RakutenRoundSemiBold";
  font-size: 1rem;
  line-height: 1.25rem;
  color: #ffffff;
  padding: 0.7rem 1.5rem;
  border: none;
  text-transform: uppercase;
  width: 21rem;
  height: 2.7rem;
  margin-top: 0rem;
  //   min-width: 10rem;
}

.leftButton {
  margin-right: 3.438rem;
  margin-top: 2rem;
  margin-left: 5.25rem;
}

.modalContent {
  margin: -1rem 1rem 1rem 0.25rem;
  font-family: "RakutenRoundRegular";
}

.orLabel {
  position: relative;
  left: -1.75rem;
  font-family: "RakutenRoundSemiBold";
}

@media screen and (max-width: 476px) {
  .modal {
    min-width: 20.5rem;
    min-height: 13.875rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 6rem;
  }

  .buttonStyle {
    width: 18.5rem;
  }

  .orLabel {
    text-align: center;
    position: relative;
    left: 8.4rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .header {
    margin-bottom: -1rem;
  }

  .titleBold {
    font-family: "RakutenRoundSemiBold";
    font-size: 1.125rem;
    margin-bottom: 0.25rem;
  }

  .secondaryButtonStyle {
    background-color: #828282;
    border-radius: 0.25rem;
    font-family: "RakutenRoundSemiBold";
    font-size: 1rem;
    line-height: 1.25rem;
    color: #ffffff;
    padding: 0.7rem 1.5rem;
    border: none;
    text-transform: uppercase;
    width: -webkit-fill-available;
  }
}
