@import "../../variables.scss";

@media screen {
  .MyapprovalPublicButton {
    background-color: $primary;
    border-radius: 0rem;
    font-family: "RakutenRoundSemiBold";
    font-size: 1rem;
    line-height: 1.25rem;
    color: #ffffff;
    padding: 0.7rem 1.5rem;
    border: none;
    text-transform: uppercase;
    height: 48px;
    margin-top: 15px;
  }
  .MyapprovalPrivateButton {
    background-color: $secondary;
    border-radius: 0rem;
    font-family: "RakutenRoundSemiBold";
    font-size: 1rem;
    line-height: 1.25rem;
    color: #ffffff;
    padding: 0.7rem 1.5rem;
    border: none;
    text-transform: uppercase;
    height: 48px;
    margin-top: 15px;
  }

  .MyapprovaliconStyle {
    padding: 0.25em;
  }
}

@media screen and (max-width: 476px) {
  .MyapprovalPublicButton {
    background-color: $primary;
    border-radius: 0rem;
    font-family: "RakutenRoundSemiBold";
    font-size: 1rem;
    line-height: 1.25rem;
    color: #ffffff;
    padding: 0.7rem 1.5rem;
    border: none;
    border-radius: 4px;
    text-transform: uppercase;
    height: 40px;
    margin-top: 15px;
    margin-left: 16px;
    width: 16rem;
    margin-left: 0rem;
  }
  .MyapprovalPrivateButton {
    background-color: $secondary;
    border-radius: 0rem;
    font-family: "RakutenRoundSemiBold";
    font-size: 1rem;
    line-height: 1.25rem;
    color: #ffffff;
    padding: 0.7rem 1.5rem;
    border: none;
    border-radius: 4px;
    text-transform: uppercase;
    height: 40px;
    margin-top: 15px;
    margin-left: 16px;
  }

  .MyapprovaliconStyle {
    padding: 0.25em;
  }
}
