.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.classroom-accordion-text {
  font-size: 15px;
  font-family: "RakutenRoundRegular";
}

.lp-accSessionName {
  font-size: 16px;
  font-family: "RakutenRoundRegular";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 14rem;
}

.seats-container {
  background: white;
  border-radius: 8px;
  padding-top: 8px;
}

.classroom-collapse {
  margin-bottom: 24px;
}

.view-button {
  color: #00a0f0;
  box-shadow: none;
}

.upoutlined {
  font-size: 10px;
  color: #00a0f0;
}

.down-outlined {
  font-size: 12px;
  color: #00a0f0;
}

.classroom-schedule {
  font-family: "RakutenRoundSemiBold";
  color: #00a0f0;
  font-size: 24px;
  margin-bottom: 16px;
}

.locked-session {
  padding-left: 8px;
}