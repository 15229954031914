.pageTitle {
  font-family: "RakutenRoundSemiBold";
  font-size: 2.5rem;
}

.pageCount {
  font-family: "RakutenRoundRegular";
  font-size: 0.875rem;
  margin: 0 0.5rem;
}

.extraMargin {
  margin: 2rem 0;
}

.antOverriddenFont {
  font-size: 32px;
}

@media screen and (min-width: 1800px) {
  .pageTitle {
    margin-left: 0rem;
  }
}