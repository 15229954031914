@import "../../../variables.scss";

@media screen {
  .addNewCourse {
    position: relative;
    left: 0.5rem;
    top: 3rem;
  }

  .legendStyle {
    position: relative;
    top: 3rem;
  }

  .searchBar {
    top: 3rem;
    /* left: 12rem; */
    position: relative;
  }

  .tableSearch {
    position: absolute;
    top: 7.6rem;
    left: 70rem;
    width: 12.5rem;
  }

  .downloadButtonStyle {
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: 7.6rem;
    left: 66rem;
  }

  .wrapText {
    overflow: hidden;
    /* text-overflow: ellipsis; */
    word-break: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.58rem;
  }

  .globeIcon {
    width: 2rem;
    height: 2rem;
  }

  .plusIconStyle {
    transform: scale(0.65);
  }

  .buttonMargin {
    margin-right: 0.1rem;
  }

  .declineButton {
    transform: rotate(45deg);
  }

  .toggleExpandButton {
    cursor: pointer;
  }

  .toggleCollapseButton {
    cursor: pointer;
    transform: rotateZ(180deg);
  }

  .headerAlign {
    text-align: center;
  }

  .notificationTable {
    width: 83rem;
    margin-right: auto;
    margin-left: auto;
    margin-top: 1rem;
  }

  .notification-pageTitle {
    font-family: "RakutenRoundSemiBold";
    font-size: 2.5rem;

    .ant-typography {
      word-break: none;
    }
  }

  .pageCount {
    font-family: "RakutenRoundRegular";
    font-size: 0.875rem;
    margin: 0 0.5rem;
  }

  .filterPage {
    padding-top: 1.5rem;
  }

  .notification-tabs {
    width: 35rem;

    .ant-tabs-tab-btn {
      font-family: "RakutenRoundRegular";
      font-size: 1rem;
      font-weight: 600;
      display: flex;
      align-items: center;
    }

    .ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $primary !important;
    }

    .ant-tabs-tab .ant-tabs-tab-btn:hover {
      color: $primary !important;
    }

    .ant-tabs-nav-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .ant-tabs-tab {
      width: 25%;
      justify-content: center;
    }

    .ant-tabs-ink-bar {
      border-bottom: 3px solid $primary;
      border-radius: 5px;
    }
  }

  .notification-crumbs {
    margin-top: 2rem;
    width: 82rem;
    margin-bottom: 1rem;
    color: $primary;
    font-family: "RakutenRoundRegular";
  }

  .buttonMargin {
    color: "red";
  }

  .notification_header {
    width: 83rem;
    margin-left: auto;
    margin-right: auto;
  }

  .title {
    font-family: "RakutenRoundRegular";
    font-size: 1.5rem;
    /* margin-left: 2.25rem;  */
    margin-bottom: 0.25rem;
  }

  .secondaryButtonStyle {
    width: 21rem;
    height: 2.7rem;
  }

  .modal {
    margin-left: -18.75rem;
    min-width: 66.313rem;
    min-height: 12.813rem;
  }

  .titleBold {
    font-family: "RakutenRoundSemiBold";
    font-size: 1.5rem;
    margin-bottom: 0.25rem;
  }

  .header {
    border-bottom: none;
  }

  .leftButton {
    margin-right: 3.438rem;
    margin-top: 2rem;
    margin-left: 5.25rem;
  }

  .notification-buttonStyle {
    min-width: 18rem;
    height: 3rem;
    background-color: $primary;
    color: #ffffff;
    font-family: "RakutenRoundRegular";
    font-size: 1rem;
    font-weight: 600;
  }

  .notification-rightButtonStyle {
    min-width: 18rem;
    height: 3rem;
    background-color: #abb2b6;
    color: #ffffff;
    font-family: "RakutenRoundRegular";
    font-size: 1rem;
    font-weight: 600;
  }

  .notification-acceptButtonStyle {
    width: 7rem;
    height: 2.2rem;
    background-color: #047205;
    color: #ffffff;
    font-family: "RakutenRoundRegular";
    font-size: 1rem;
    font-weight: 600;
    margin-right: 0.4rem;
  }

  .notification-declineButtonStyle {
    width: 7rem;
    height: 2.2rem;
    background-color: #ff0000;
    color: #ffffff;
    font-family: "RakutenRoundRegular";
    font-size: 1rem;
    font-weight: 600;
  }

  .notification-iconStyle {
    padding-right: 0.5rem;
  }

  .notification-alert {
    font-family: "RakutenRoundRegular";
    margin-top: 1.3rem;
  }

  .modalContent {
    margin: -1rem 1rem 1rem 2.25rem;
  }

  .orLabel {
    position: relative;
    left: -1.75rem;
    font-family: "RakutenRoundSemiBold";
  }

  .notificationSearch {
    font-family: "RakutenRoundRegular";
  }
}

@media screen and (max-width: 1400px) {
  .notificationTable {
    width: 71rem;
    margin-right: auto;
    margin-left: auto;
    margin-top: 1rem;
  }

  .notification-crumbs {
    width: 70rem;
  }

  .notification_header {
    width: 70rem;
  }

  .notificationSearch {
    margin-left: -1rem;
    font-family: "RakutenRoundRegular";
  }

  .notification-tabs {
    width: 33rem;
  }
}

@media screen and (max-width: 476px) {
  .modal {
    min-width: 20.5rem;
    min-height: 13.875rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 6rem;
  }

  .buttonStyle {
    width: 18.5rem;
  }

  .orLabel {
    text-align: center;
    position: relative;
    left: 8.4rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .header {
    margin-bottom: -1rem;
  }

  .titleBold {
    font-family: "RakutenRoundSemiBold";
    font-size: 1.125rem;
    margin-bottom: 0.25rem;
  }

  .secondaryButtonStyle {
    width: -webkit-fill-available;
  }
}
