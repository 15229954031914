.modalbody {
  position: absolute;
  padding: unset;
  width: 100%;
}
.selectCourseHeader {
  display: flex;
  align-items: center;
  padding-top: 38px;
  padding-bottom: 40px;
}
