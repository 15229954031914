.mobile-grow-logo {
  height: 3.125rem;
  width: 5.625rem;
  //   margin-left: -1.9rem;
  z-index: 9999;
}

.allCourses {
  color: #000000;
  font-family: "RakutenRoundSemiBold";
  font-size: 0.813rem;
  font-weight: 600;
  letter-spacing: 0.014rem;
  line-height: 1.25rem;
  padding-top: 1rem;
  margin-left: 1.5rem;
  cursor: pointer;
}

.custom-anchor-tag {
  color: #000000;
  font-family: "RakutenRoundRegular";
  font-size: 0.813rem;
  font-weight: 600;
  letter-spacing: 0.014rem;
  line-height: 1.25rem;
  padding-top: 1rem;
  margin-left: 1.5rem;
  cursor: pointer;
}

.my-account {
  color: #000000;
  font-family: "RakutenRoundRegular";
  font-size: 0.813rem;
  font-weight: 600;
  letter-spacing: 0.014rem;
  line-height: 1.25rem;
  padding-top: 1rem;
  margin-left: 1.5rem;
  cursor: pointer;
}
