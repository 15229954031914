.tableSearch {
	position: absolute;
	top: 7.6rem;
	left: 70rem;
	width: 12.5rem;
}
.acceptedButton {
	margin-left: 4rem;
	width: min-content;
	height: fit-content;
}
.declinedButton {
	max-width: max-content;
	max-height: max-content;
}