.trainerDetailsInputBox {
  margin-top: 0.625rem;
  height: 23.938rem;
  width: 23.125rem;
  border-radius: 0.375rem;
  background-color: #F7F7F7
}

.trainer-validation {
  margin-left: 0.375rem;
  font-size: small;
  margin-bottom: 1.063rem;
  color: #FF0000;
  font-family: "RakutenRoundRegular";
}

.closeIcon {
  height: 1rem;
  width: 1rem;
}

.textField {
  box-sizing: border-box;
  height: 2.875rem;
  width: 20.875rem;
  border: 0.063rem solid #9C9C9C;
  border-radius: 0.375rem;
  background-color: #FFFFFF;
}