.courseFilterOuter {
  display: "flex";
  flex-wrap: "wrap";

  margin-bottom: 0.538rem;
  margin-top: 2.375rem;
}

.courseFilterInner {
  display: "flex";
  flex-wrap: "wrap";
  margin-bottom: 3.938rem;
}

.customSelectStyle {
  border-radius: 2px;
  border-color: #000;
}

.titleStyle {
  font-family: "RakutenRoundRegular";
  font-size: 0.8125rem;
  font-weight: 300;
  text-transform: uppercase;
}

.filtersRow {
  margin-top: 16px;
  margin-bottom: 56px;
}

@media screen {
  .optionsStyle {
    font-size: 0.8125rem !important;
    text-transform: uppercase !important;
    color: #000 !important;
    font-weight: lighter !important;
    font-family: "RakutenRoundRegular";
    min-width: 170px;
    border-color: red;
  }

  .courseFilterOuter {
    display: "flex";
    flex-wrap: "wrap";

    margin-bottom: 0.538rem;
    margin-top: 2.375rem;
    margin-left: 13rem;
  }
}

@media screen and (max-width: 1440px) {
  .courseFilterOuter {
    display: "flex";
    flex-wrap: "wrap";
    margin-bottom: 0.538rem;
    margin-top: 2.375rem;
  }
}

@media screen and (max-width: 1380px) {
  .optionsStyle {
    min-width: 150px;
  }
}

@media screen and (max-width: 1280px) {
  .optionsStyle {
    min-width: 140px;
  }
}

@media screen and (max-width: 768px) {
  .optionsStyle {
    font-size: 0.8125rem !important;
    text-transform: uppercase !important;
    color: #000 !important;
    font-weight: lighter !important;
    font-family: "RakutenRoundRegular";
    min-width: 170px;
    margin-right: 0.3rem;
  }
}
