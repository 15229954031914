a.itemContainer {
	width: 21.1875rem;
	height: 6.5625rem;
	background-color: #ffffff;
	border-radius: 0.25rem;
	margin-bottom: 1rem;
	padding-left: 0.8125rem;
	padding-right: 0.6875rem;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	display: flex;
	justify-content: space-between;
	color: inherit;
}

a.itemContainer:hover {
	box-shadow: 0 0.125rem 0.75rem 0 #d1d1d1;
}

.rightContent {
	margin-top: -1rem;
	width: 13.625rem;
}

.date {
	font-family: RakutenRoundRegular;
	font-size: 0.75rem;
	color: #00a0f0;
}

.courseName {
	margin-top: 1rem;
	font-family: RakutenRoundSemiBold;
	font-size: 0.75rem;
	line-height: 0.9375rem;
	height: 2rem;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
	display: -webkit-box;
	-webkit-box-orient: vertical;
}

.sessionStartTime {
	margin-top: 0.2rem;
	font-family: RakutenRoundRegular;
	font-size: 0.75rem;
	line-height: 0.9375rem;
}

.circle {
	margin-top: 0rem;
	background: #ffffff;
	border-radius: 50%;
	width: 4.5625rem;
	height: 4.5625rem;
	display: inline-block;
	margin-left: 24px;
	text-align: center;
	z-index: 800;
	border-color: #00a0f0;
	border-width: 0.125rem;
	border-style: solid;
	padding-top: 0.3125rem;
}

.remainingSeats {
	font-family: RakutenRoundSemiBold;
	font-size: 1.75rem;
	line-height: 1.875rem;
	color: #00a0f0;
}

.seatsLeft {
	width: 1.9375rem;
	margin-left: 1.3125rem;
	font-family: RakutenRoundRegular;
	font-size: 0.75rem;
	line-height: 0.8125rem;
}