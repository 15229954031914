.container {
  border-right: 0.0625rem solid #00a0f0;
}

a.courseTile {
  width: 22.5625rem;
  height: 12.5rem;
  background-color: #ffffff;
  box-shadow: 0 0.125rem 0.25rem 0 #d1d1d1;
  border-radius: 0.375rem;
  position: relative;
  display: inline-block;
  margin-right: 5.3125rem;
  color: inherit;
}

a.courseTile:hover {
  box-shadow: 0 0.125rem 0.75rem 0 #d1d1d1;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header {
  font-family: RakutenRoundRegular;
  font-size: 2rem;
  margin-bottom: 2.6875rem;
}

.myLearning:link,
.myLearning:hover,
.myLearning:visited {
  font-family: RakutenRoundRegular;
  font-size: 0.875rem;
  margin-bottom: 1.625rem;
  margin-right: 3.875rem;

  color: #00a0f0;
  letter-spacing: 0.0675rem;
}

.courseName {
  margin-top: 1.4rem;
  font-family: RakutenRoundSemiBold;
  font-size: 1.4rem;
  color: #00a0f0;
  margin-left: 1.5rem;
  display: inline-block;
  width: 14.9375rem;
  height: 3.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.courseImage {
  position: absolute;
  right: -3.9375rem;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 9.5rem;
  height: 9.5rem;
  width: 7.9375rem;
  top: 1.5625rem;
  border-radius: 0.375rem;
}

.subheading {
  margin-left: 1.5rem;
  padding-top: 1.25rem;
}

.subheadingImage {
  width: 1.4375rem;
}

.subheadingText {
  font-family: RakutenRoundRegular;
  font-size: 0.75rem;
  margin-left: 0.3125rem;
  color: #333333;
}

.seesionStart {
  display: inline-block;
  width: 11.375rem;
  margin-left: 1.5rem;
  margin-top: 0.875rem;
  font-family: RakutenRoundRegular;
  font-size: 0.875rem;
  line-height: 1.3125rem;
}

.placeholderHeader {
  font-family: RakutenRoundRegular;
  font-size: 2.25rem;
  margin-bottom: 1rem;
}

.placeholderText {
  font-family: RakutenRoundRegular;
  font-size: 1.125rem;
  margin-bottom: 2.7813rem;
  line-height: 1.625rem;
  margin-right: 1.25rem;
}

.placeholderFlowContainer {
  display: flex;
  position: relative;
}

.placeholderFlowFirstCircle {
  background: #ffffff;
  border-radius: 50%;
  width: 6.625rem;
  height: 6.625rem;
  box-shadow: 0 0.125rem 0.25rem 0 #d1d1d1;
  display: inline-block;
  margin-left: 1.5rem;
  text-align: center;
  padding-top: 2.25rem;
  z-index: 900;
}

.placeholderFlowSecondCircle {
  background: #ffffff;
  border-radius: 50%;
  width: 6.625rem;
  height: 6.625rem;
  box-shadow: 0 0.125rem 0.25rem 0 #d1d1d1;
  display: inline-block;
  margin-left: 2.25rem;
  text-align: center;
  padding-top: 1.6875rem;
  z-index: 900;
}

.placeholderFlowFirstText {
  font-family: RakutenRoundSemiBold;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  width: 3.5rem;
  color: #00a0f0;
  display: inline-block;
  width: 3.5rem;
}

.placeholderFlowSecondText {
  font-family: RakutenRoundSemiBold;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  width: 3.5rem;
  color: #00a0f0;
  display: inline-block;
  width: 5.625rem;
}

.placeholderFlowThirdText {
  font-family: RakutenRoundSemiBold;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  width: 3.5rem;
  color: #00a0f0;
  display: inline-block;
  width: 5rem;
}

.placeholderLineContainer {
  position: absolute;
  top: 2.125rem;
  display: flex;
  align-items: center;
}

.placeholderLineHr {
  width: 34.375rem;
  border-top: solid 0.0625rem;
  display: inline-block;
}

.placeholderLineText {
  margin-left: 1.25rem;
  font-family: RakutenRoundSemiBold;
  font-size: 0.875rem;
  line-height: 1.0625rem;
}

@media screen and (max-width: 476px) {
  .header {
    font-family: "RakutenRoundRegular";
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0.8rem;
    margin-left: -1rem;
  }

  .myLearning:link,
  .myLearning:hover,
  .myLearning:visited {
    font-family: "RakutenRoundRegular";
    font-size: 0.875rem;
    font-weight: 900;
    margin-bottom: 1rem;
    margin-right: 3rem;
    text-decoration: underline;
    color: #00a0f0;
    letter-spacing: 0.0675rem;
  }
  a.courseTile {
    width: 278px;
    height: 158px;
    background-color: #ffffff;
    box-shadow: 0 0.125rem 0.25rem 0 #d1d1d1;
    border-radius: 0.375rem;
    position: relative;
    display: inline-block;
    padding-right: 5rem;
    margin-right: 5rem;
    color: inherit;
  }
  .courseImage {
    position: absolute;
    right: -3rem;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 9.5rem;
    height: 103px;
    width: 87px;
    top: 1.5625rem;
    border-radius: 0.375rem;
    margin-right: 1rem;
  }
  .seesionStart {
    display: inline-block;
    width: 11.375rem;
    margin-left: 1.5rem;
    margin-top: 0rem;
    font-family: "RakutenRoundRegular";
    font-size: 0.875rem;
    line-height: 1.3125rem;
  }
  .courseName {
    margin-top: 0.6rem;
    margin-left: 1.5rem;
    width: 14.9375rem;
    height: 3rem;
  }
  .mobileScroll {
    display: flex;
    overflow: hidden;
    overflow-x: scroll;
    margin-left: -1rem;
    height: 158px;
  }
}
