@import "../../../variables.scss";

.crumb-title {
  color: $primary;
  a,
  a:hover,
  a:visited {
    color: $primary;
  }
}
.dashboard {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.admin-dashboard-card {
  margin-right: 1rem;
  margin-bottom: 3rem;
  height: 10rem;
}

.admin-dashboard-card-title {
  color: $primary;
  font-family: "RakutenRoundSemiBold";
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.563rem;
  padding-top: 2.875rem;
  text-align: center;
}

.admin-dashboard-card-description {
  color: #1f1f1f;
  font-family: "RakutenRoundRegular";
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.063rem;
  text-align: center;
  padding: 1rem;
  padding-top: 1.125rem;
}

.admin-page-title {
  font-family: "RakutenRoundSemiBold";
  font-size: 2.5rem;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.admin-dashboard-card-notificiation-number {
  height: 1.875rem;
  width: 1.875rem;
  margin-left: 15.125rem;
  position: absolute;
  border: 0.125rem solid;
  border-radius: 50%;
  background-color: $primary;
  top: -0.75rem;
  right: -0.75rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 4320px) {
  .dashboard-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 34%;
    margin-right: 34%;
  }
}

@media screen and (min-width: 3400px) and (max-width: 4319px) {
  .dashboard-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 29.5%;
    margin-right: 29.5%;
  }
}

@media screen and (min-width: 3200px) and (max-width: 3400px) {
  .dashboard-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 29.5%;
    margin-right: 29.5%;
  }
}

@media screen and (min-width: 3000px) and (max-width: 3200px) {
  .dashboard-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 26.5%;
    margin-right: 26.5%;
  }
}

@media screen and (min-width: 2880px) and (max-width: 3000px) {
  .dashboard-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 26.5%;
    margin-right: 26.5%;
  }
}

@media screen and (min-width: 2160px) and (max-width: 2880px) {
  .dashboard-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 25%;
    margin-right: 25%;
  }
}

@media screen and (min-width: 1920px) and (max-width: 2160px) {
  .dashboard-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 17.5%;
    margin-right: 17.5%;
  }
}

@media screen and (min-width: 1800px) and (max-width: 1920px) {
  .dashboard-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 13.5%;
    margin-right: 12.5%;
  }
}

@media screen and (min-width: 1680px) and (max-width: 1800px) {
  .dashboard-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 11%;
    margin-right: 10%;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1679px) {
  .dashboard-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 6%;
    margin-right: 6%;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1599px) {
  .dashboard-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media screen and (min-width: 1350px) and (max-width: 1440px) {
  .dashboard-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 5%;
    margin-right: 5%;
  }

  .dashboard {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 1300px) and (max-width: 1350px) {
  .dashboard-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 5%;
    margin-right: 5%;
  }
  .dashboard {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .dashboard-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 5%;
    margin-right: 5%;
  }
  .dashboard {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1150px) and (max-width: 1200px) {
  .dashboard-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 5%;
    margin-right: 5%;
  }
  .dashboard {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
