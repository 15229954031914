@import "../../../../variables.scss";

.antd-assigned-user-delete-button {
  background-color: $primary;
  border-radius: 0.25rem;
  font-family: "RakutenRoundSemiBold";
  font-size: 1rem;
  line-height: 1.25rem;
  color: #ffffff;
  padding: 0.7rem 1.5rem;
  border: none;
  text-transform: uppercase;
  padding: 0.7rem 1.5rem;
  height: 2.7rem;
  width: 6.7rem;
  margin-left: 1rem;
}

.yetToStart-color-change:hover {
  color: red;
}

.inProgress-color-change:hover {
  color: orange;
}

.completed-color-change:hover {
  color: green;
}
