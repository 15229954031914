.addNewCourse {
	position: relative;
	left: 1.9rem;
	top: 3rem;
}
.legendStyle {
	position: relative;
	top: 3rem;
}
.searchBar {
	top: 3rem;
	/* left: 12rem; */
	position: relative;
}

.pageFilter {
	position: relative;
	top: 3.5rem;
	left: 5rem;
}
.tableSearch {
	position: absolute;
	top: 7.6rem;
	left: 70rem;
	width: 12.5rem;
}
.downloadButtonStyle {
	position: absolute;
	top: 7.6rem;
	left: 66rem;
}
.numberButton {
	border: 0.125rem solid black;
	border-radius: 0.25rem;
	padding: 0.125rem;
}
.wrapText {
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	line-height: 1.58rem;
}
.subTitle {
	margin: 0;
}
.rowPadding {
	padding: 0.5rem 1rem;
}
.deleteUserButton {
	left: 8.75rem;
	position: relative;
	top: 3rem;
}
.buttonMargin {
	margin-top: -0.25rem;
}

.toggleExpandButton {
	transform: rotate(-180deg);
}

.expandRowContainer {
	max-width: 20rem;
	align-items: center;
	display: flex;
	flex-direction: column;
}

.expandRowTable {
	border: 0.0625rem solid #333333;
	margin-top: 0.8125rem;
	margin-right: 3.125rem;
	margin-bottom: 1.5rem;
}

.cardCountContainer {
	height: 9rem;
	width: 13.625rem;
	border-radius: 0.375rem;
	background-color: #f7f7f7;
	margin-top: 0.625rem;
	margin-bottom: 1.5rem;
	padding-top: 1.125rem;
}

.cardCountInnerContainer {
	height: 2.125rem;
	width: 9.375rem;
	text-align: center;
	color: #979797;
	font-family: RakutenRoundRegular;
	font-size: 0.875rem;
	font-weight: 600;
	margin: 0 auto;
	margin-top: 0.5625rem;
}

.statusCount {
	font-size: 2.25rem;
	font-weight: 600;
}

.status {
	border: 0.0625rem solid;
	background: none;
	font-family: RakutenRoundSemiBold;
}

.completed {
	color: #37b900;
}
.notCompleted {
	color: #bf0000;
}
.pending {
	color: #828282;
}
.courseName {
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
}

.courseIdName {
	border: 0.0625rem solid !important;
	background: none !important;
	padding-top: 1.25rem !important;
	padding-bottom: 1.25rem !important;
	padding-left: 2rem !important;
}

.expandTableTh {
	border: 0.0625rem solid #333333 !important;
	padding-left: 2rem !important;
	background: none !important;
	width: 12rem !important;
}

.courseIdNameTh {
	width: 20.75rem !important;
}

.message {
	display: flex;
	align-items: center;
	font-weight: bold;
	justify-content: center;
	color: #37b900;
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
	font-size: 1.25rem;
}

.modalContentStyle {
	max-height: 28rem;
	overflow-y: auto;
}
