.cardSubcategoryInner {
  width: 57.875rem;
  height: 14.813rem;
  margin-left: 2.031rem;
  display: flex;
  flex-direction: "row";
  height: auto;
  flex-wrap: wrap;
}

.subcategoryLabel {
  display: flex;
  border: solid 1px #9c9c9c;
  border-radius: 4px;
  margin: 0.438rem;
  padding-right: 0.625rem;
  font-family: "RakutenRoundRegular";
  padding-right: 24px;
  height: auto;
  margin-left: 16px;
  margin-top: 16px;
}

.button {
  border-color: white;
  background-color: white;
  padding: 0 0 0 0;
}

.cross {
  margin-top: -5px;
  width: 1.25rem;
  height: 1.25rem;
}

.addSubcategory {
  margin-top: 1.25rem;
  margin-left: 23.094rem;
}

.row {
  width: 20.125rem;
  height: 3.063rem;
  background-color: white;
  border: 1px solid #9c9c9c;
  border-radius: 4px;
}

.inputArea {}

.form {
  border-style: none;
  font-family: "RakutenRoundRegular";
}

.plusButton {
  height: 1.125rem;
  width: 1.125rem;
  background-color: white;
  border-radius: 0;
  text-align: center;
}

.nameMargins {
  margin-left: "24px";
  margin-right: "16px";
}