@import "../../../../variables.scss";

.click-here-span {
  color: $primary;
}

@media screen {
  .eLearning {
    margin-bottom: 7.688rem;
  }

  .topTextUp {
    font-size: 2rem;
    font-family: "RakutenRoundRegular";
  }

  .topTextDown {
    font-family: "RakutenRoundSemiBold";
    font-size: 2.5rem;
    line-height: 3.375rem;
  }

  .eLearningBox {
    border: 0.0625rem solid #b6b6b6;
    height: 10.6875rem;
    width: 32.7119rem;
    border-radius: 0.3125rem;
    // margin-left: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .eLearningBox:hover {
    box-shadow: 0 0.125rem 0.75rem 0 #d1d1d1;
  }

  .pluralsightImage {
    height: 3.875rem;
    margin-top: 3.4375rem;
    margin-left: 7.9575rem;
  }
  .udemyImage {
    // height: 3.125rem;
    // margin-top: 3.4375rem;
    // margin-left: 11.125rem;
  }

  .rowELearning {
    margin-left: -5rem;
    margin-top: 5.3125rem;
    margin-bottom: 4.75rem;
  }
  .bottomText {
    font-size: 1rem;
    font-family: "RakutenRoundRegular";
    line-height: 1.813rem;
  }
}

@media screen and (max-width: 767px) {
  .eLearning {
    margin-bottom: 2.688rem;
    // width: 289px;
    width: 23rem;
  }
  .topTextUp {
    font-size: 1.5rem;
    font-family: "RakutenRoundRegular";
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }

  .topTextDown {
    font-family: "RakutenRoundSemiBold";
    font-size: 2.5rem;
    line-height: 3.375rem;
  }

  .mobile-content-phrase {
    display: flex;
    flex-direction: column;
    width: 23rem;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    font-family: "RakutenRoundRegular";
  }

  .eLearningBox {
    border: 0.0625rem solid #b6b6b6;
    height: 4.6875rem;
    width: 15.7119rem;
    border-radius: 0.3125rem;
    // margin-left: 9rem;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 1rem;
  }
  .eLearningBox:hover {
    box-shadow: 0 0.125rem 0.75rem 0 #d1d1d1;
  }

  .pluralsightImage {
    height: 2.875rem;
    margin-top: 0rem;
    margin-left: 0rem;
  }
  .udemyImage {
    height: 2.125rem;
    margin-top: 0;
    margin-left: 0;
  }

  .rowELearning {
    margin-left: -5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .bottomText {
    font-size: 1rem;
    font-family: "RakutenRoundRegular";
    line-height: 1.5rem;
    text-align: center;
  }

  .rowELearning2 {
    width: 22rem;
    margin: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-link {
  }
}

.link {
  font-size: 1rem;
  font-family: "RakutenRoundRegular";
  line-height: 1.813rem;
  color: #00a0f0;
  text-decoration: underline;
  text-decoration-color: $primary;
}
