.textOverVideo {
  color: #ffffff;
  font-family: "RakutenRoundRegular";
  font-size: 3rem;
  letter-spacing: 0;
  line-height: 4.125rem;
  text-align: start;
  margin-bottom: 3.125rem;
  width: 31.25rem;
  left: 3.75rem;
  bottom: 6.25rem;
}

.scrollText {
  color: #333333;
  font-family: "RakutenRoundRegular";
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.813rem;
  text-align: center;
  bottom: 0;
}

.title {
  padding-top: 8.2rem;
  height: 3.9375rem;
  width: 53.0625rem;
  color: #ffffff;
  font-family: "RakutenRoundRegular";
  font-size: 3.5rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 3.9375rem;
}

.description {
  margin-left: 0.1875rem;
  margin-top: 5rem;
  height: 4.25rem;
  width: 43.5rem;
  color: #ffffff;
  font-family: "RakutenRoundRegular";
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.4375rem;
}
