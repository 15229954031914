.courseCard {
  margin: 0 !important;
  position: relative;
  height: 356px;
  max-width: 312px;
  border: none;
  display: flex;
  flex-direction: column;
}
.courseTitle {
  height: 60px;
  font-family: "RakutenRoundRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
  padding: 0;
  margin: 8px 12px 0px 16px;
  margin-bottom: 24px;
}
.courseCardCover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 172px;
  padding: 16px;
  background-color: #1e1e1e42;
  display: flex;
  justify-content: space-between;
}
.courseCardImage {
  height: 172px;
}
.courseCardContent {
  display: flex;
  height: 96px;
}
.courseDetailsFlex {
  display: flex;
  margin-left: 16px;
}
.courseDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 18px;
}
.courseDetail {
  font-family: "RakutenRoundRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}
.courseCheckIcon {
  display: flex;
  width: 40px;
  height: 100%;
  padding-right: 18px;
  padding-bottom: 18px;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: end;
}
.courseCheckIcon svg {
  width: 40px;
  height: 40px;
}

.card {
  border-radius: 0.5rem;
  border-color: #f7f7f7;
  background-color: #f7f7f7;
  width: 19.5rem;
  min-height: 22.25rem;
  max-height: 22.25rem;
  box-shadow: 0 0.125rem 0.25rem 0 #d1d1d1;
  margin-right: 0.3125rem;
  z-index: 0;
}
.cardLP {
  border-radius: 0.5rem;
  border-color: #f7f7f7;
  background-color: #f7f7f7;
  width: 19.5rem;
  min-height: 22.25rem;
  max-height: 22.25rem;
  box-shadow: 0 0.125rem 0.25rem 0 #d1d1d1;
  margin: 1rem;
  z-index: 0;
}

.overlayText {
  margin-top: 1.519rem;
  margin-left: 0.188rem;
  font-family: "RakutenRoundRegular";
  font-size: 0.75rem;
  line-height: 2.5rem;
  letter-spacing: 0;
  color: #ffffff;
}
.imageDiv {
  background-color: black;
  border-radius: 0.5rem 0.5rem 0 0;
}
.image {
  border-radius: 0.5rem 0.5rem 0 0;
  opacity: 0.7;
}
.duration {
  margin-left: 1rem;
  padding-left: 1rem;
  font-family: "RakutenRoundRegular";
  font-size: 0.875rem;
  line-height: 1.438rem;
  overflow: hidden;
  white-space: nowrap;
}
.roundProgressBar {
  margin-top: -1.75rem;
  margin-left: 0.2rem;
  padding-top: 0rem;
  height: 5rem;
  width: 5rem;
}
.assignedStatus {
  border-radius: 0.25rem;
  background-color: #bf0000;
  color: #ffffff;
  text-align: center;
  padding: 0.25rem 0.75rem;
  font-size: 0.75rem;
  margin-left: 8.25rem;
  text-transform: uppercase;
  font-family: "RakutenRoundRegular";
}
.cardOverlayText {
  margin-top: 1.25rem;
}
.circleProgressBar {
  width: 5rem;
  height: 5rem;
  cursor: pointer;
  float: right;
  margin-left: 0.3rem;
  margin-top: 1.15rem;
}
.addSelectCourse {
  cursor: pointer;
}
.wishlistImage {
  padding-top: 0.2rem;
  z-index: 2;
  margin-left: 12rem;
}

.wishlistImage > img {
  width: 20px;
  height: 20px;
  display: inline !important;
}

.plusCircleStyle {
  position: absolute;
}

.roundProgressBar {
  margin-top: -1.75rem;
  padding-top: 0rem;
}
