.container {
  display: inline-block;
  margin: 1rem 1.2813rem;
  vertical-align: top;
}

.card {
  border-radius: 0.5rem;
  background-color: #f7f7f7;
  width: 40rem;
  height: 6.5625rem;
  box-shadow: 0 0.125rem 0.25rem 0 #d1d1d1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1.25rem;
  z-index: 0;
}

.card:hover {
  box-shadow: 0 0.125rem 0.75rem 0 #d1d1d1;
}

.img {
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  margin: 0 1.25rem 0 0;
}

.body {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.textData {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  width: 38%;
  padding-left: 0.938rem;
  font-family: "RakutenRoundRegular";
  font-size: 0.75rem;
}

.cardTitle {
  height: 2.75rem;
  font-size: 1.625rem !important;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 1rem;
  letter-spacing: 0;
  font-family: "RakutenRoundRegular";
  color: #000000 !important;
}

.details {
  font-size: 0.875rem;
  margin: 0 1.25rem 0 0;
  color: #686868;
}

.bold {
  font-family: "RakutenRoundSemiBold";
  color: black;
}

.bold::after {
  content: " ";
}

.bottomText {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.heartImage {
	padding-top: 0.7rem;
	z-index: 2;
  margin-right: 1.3rem;
}

.favouriteIcon {
  width: 20px;
  height: 20px;
}

.learningPathName {
  max-width: 30rem;
  text-overflow: ellipsis;
  overflow: hidden;
}