@import "../../../variables.scss";
.AntdCourseAddEdit-container {
  margin-top: 7rem;
}

.AntdCreateAddEdit-page-title {
  font-family: "RakutenRoundSemiBold";
  font-size: 2.5rem;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.courseAddEdit-input {
  font-family: "RakutenRoundRegular";
  height: 3rem;
  font-size: 1rem;
  margin-bottom: 2.5rem;
}

.courseAddEdit-courseOriginTitle {
  color: #000000;
  font-family: "RakutenRoundSemiBold";
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
}

.courseAddEdit-crumbs {
  font-family: "RakutenRoundRegular";
}

.courseAddEdit-courseOriginTitleOuterBox {
  padding: 0;
}

.courseAddEdit-validation {
  margin-left: 0.375rem;
  font-size: small;
  // margin-top: -2rem;
  margin-bottom: 1.063rem;
  color: #ff0000;
  font-family: "RakutenRoundRegular";
}

.courseAddEdit-label {
  font-family: "RakutenRoundRegular";
  padding-bottom: 0.4rem;
  font-size: 1rem;
}

.courseAddEdit-approvalTitle {
  color: #000000;
  font-family: "RakutenRoundSemiBold";
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
}

.courseAddEdit-numberField {
  padding-top: 0.45rem;
  height: 2.875rem;
  width: 26.5rem;
  font-family: "RakutenRoundSemiBold";
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 2.46875rem;
}

.courseAddEdit-required {
  color: #ff0000;
}

.courseAddEdit-approvalCheckBox {
  border-radius: 0.2rem;
  vertical-align: top;
  color: transparent;
  transition: 0.2s;
}

.courseAddEdit-resetButton {
  border-color: $primary;
  color: $primary;
  font-family: "RakutenRoundSemiBold";
  text-transform: uppercase;
  width: 10rem;
  height: 2.7rem;
  font-size: 1.2rem;
}

.courseAddEdit-updateButton {
  color: #ffffff;
  background-color: $primary;
  font-family: "RakutenRoundSemiBold";
  text-transform: uppercase;
  width: 10rem;
  height: 2.7rem;
  font-size: 1.2rem;
}

.courseAddEdit-biggerSvg {
  margin-top: 6.375rem;
  margin-left: 10.25rem;
  cursor: pointer;
}

.courseAddEdit-biggerSvgBox {
  background-color: #cdced0;
  top: 2.875rem;
  right: 1.0625rem;
  margin-top: -0.75rem;
  height: 17.625rem;
  border-radius: 0.3125rem;
}

@media screen and (min-width: 4320px) {
  .AntdCourseAddEdit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 37.5%;
    margin-right: 34%;
  }
}

@media screen and (min-width: 3400px) and (max-width: 4319px) {
  .AntdCourseAddEdit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 33.5%;
    margin-right: 29.5%;
  }
}

@media screen and (min-width: 3200px) and (max-width: 3400px) {
  .AntdCourseAddEdit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 29.5%;
    margin-right: 29.5%;
  }
}

@media screen and (min-width: 3000px) and (max-width: 3200px) {
  .AntdCourseAddEdit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 26.5%;
    margin-right: 26.5%;
  }
}

@media screen and (min-width: 2880px) and (max-width: 3000px) {
  .AntdCourseAddEdit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 26.5%;
    margin-right: 26.5%;
  }
}

@media screen and (min-width: 2160px) and (max-width: 2880px) {
  .AntdCourseAddEdit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 25%;
    margin-right: 25%;
  }
}

@media screen and (min-width: 1920px) and (max-width: 2160px) {
  .AntdCourseAddEdit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 17.5%;
    margin-right: 17.5%;
  }
}

@media screen and (min-width: 1800px) and (max-width: 1920px) {
  .AntdCourseAddEdit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 13.5%;
    margin-right: 12.5%;
  }
}

@media screen and (min-width: 1680px) and (max-width: 1800px) {
  .AntdCourseAddEdit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 11%;
    margin-right: 10%;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1679px) {
  .AntdCourseAddEdit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 6%;
    margin-right: 6%;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1599px) {
  .AntdCourseAddEdit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media screen and (min-width: 1350px) and (max-width: 1440px) {
  .AntdCourseAddEdit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 0;
    margin-right: 5%;
    // width: 100%;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1350px) {
  .AntdCourseAddEdit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .AntdCourseAddEdit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media screen and (min-width: 1150px) and (max-width: 1200px) {
  .AntdCourseAddEdit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 5%;
    margin-right: 5%;
  }
}
