.primryButton {
  width: 9.29625rem;
  height: 2.1875rem;
  font-family: "RakutenRoundRegular";
  font-size: 0.75rem;
  font-weight: 600;
  border-radius: 0.25rem;
  background-color: #00a0f0;
}

.hint {
  color: #333333;
  font-family: "RakutenRoundRegular";
  font-size: 0.75rem;
  text-align: center;
  margin-bottom: 0rem;
  margin-top: 1.25rem;
}
.outerBox {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  padding: 24px 60px;
  height: 128px;
  border: 1px dashed #9C9C9C;
  border-radius: 4px;
  background-color: #F7F7F7;
  margin: 24px 24px 18px 24px;
}

.fileName {
  color: #00a0f0;
  font-family: "RakutenRoundRegular";
  font-size: 0.75rem;
  text-align: center;
  padding: 0;
  margin: 0;
}
