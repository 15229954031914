.carousel-indicators {
  right: 0rem;
  left: unset;
  bottom: 1.25rem;
}

.carousel-indicators ol {
  list-style: none;
}

.carousel-indicators li {
  color: #333333;
  opacity: 0.2;
  box-sizing: inherit;
  counter-increment: custom;
  font-family: "RakutenRoundRegular";
  text-indent: 0rem !important;
}

.carousel-indicators li::before {
  content: "0" counter(custom) " ";
}

.carousel-indicators li:first-child {
  counter-reset: custom;
}

.scrollDownArrowNonPromotional {
  position: relative;
  left: 50%;
  top: 1rem;
  margin-left: -0.9rem;
  margin-top: -3rem;
  width: 1.5rem;
  height: 0.85rem;
  background: url(../../assets/icons/dropdownWhite.svg);
  background-size: cover;
  cursor: pointer;
}

.scrollDownArrowPromotional {
  position: relative;
  left: 50%;
  top: 1rem;
  margin-left: -0.9rem;
  margin-top: -3rem;
  width: 1.5rem;
  height: 0.85rem;
  background: url(../../assets/icons/dropdownBlack.svg);
  background-size: cover;
  cursor: pointer;
}
.custom-control-prev-icon {
  background-image: url("../../assets/icons/left-arrow-hero-banner.svg");
  height: 3rem;
  width: 3rem;
  background-repeat: no-repeat;
}
.custom-control-next-icon {
  background-image: url(../../assets/icons/right-arrow-hero-banner.svg);
  height: 3rem;
  width: 3rem;
  background-repeat: no-repeat;
}
.bounce {
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-1.875rem);
  }
  60% {
    transform: translateY(-0.938rem);
  }
}
