.addNewCourse {
  position: relative;
  top: 3rem;
}

.downloadButtonStyle {
  position: relative;
  top: 3rem;
}

.subTitle {
  margin: 0;
  font-size: 16px;
}

.secButton {
  width: 10.875rem;
  border: 0.0625rem solid #019ff0;
  color: #019ff0;
  background-color: white;
  position: relative;
  top: 3rem;
  float: right;
}

.disclaimerMessage {
  font-family: "RakutenRoundRegular";
  font-size: 13px;
  color: #333333;
  margin-top: 10px;
}

.modal {
  margin-left: -18.75rem;
  min-width: 66.313rem;
  align-content: center;
  border-radius: 0.5rem;
}

.grayBox {
  width: 100%;
  background-color: #f7f7f7;
  border-radius: 0.5rem;
  margin-top: 16px;
  padding: 24px;
}

.title {
  font-family: "RakutenRoundRegular";
  font-size: 1.5rem;
  margin-left: 1.25rem;
  line-height: 3.063rem;
  margin-bottom: -0.625rem;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.footer {
  border-top: none;
}

.header {
	margin: 24px -15px;
  align-items: center;
}

.footerButton {
  margin-top: 2.125rem;
  margin-left: 28.875rem;
  margin-bottom: 2.125rem;
}

.importExcel {
  display: flex;
  justify-content: center;
  margin-top: 34px;
  font-family: "RakutenRoundRegular";
  line-height: 18px;
}

.primaryButton {
  margin-right: 1rem;
}

.btnContainer {
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
}

.contentEnd {
  display: flex;
  justify-content: end;
}

.inlineBlock {
  display: inline-block;
}

.downloadIcon {
  margin-right: 18px;
}

.searchInput {
  width: 222px;
  margin-right: 18px;
}

.uploadButton {
  width: 113px;
  margin-right: 18px;
}

.finalStatusButton {
  width: 248px;
  font-size: 14px;
  font-family: 'RakutenRoundRegular';
}

.content {
  text-align: center;
  font-family: 'RakutenRoundRegular';
  font-size: 14px;
}

.dBlock {
  display: block;
}