@import "../../variables.scss";

.send-button-email-action-modal {
  background-color: $primary;
  border-radius: 0.25rem;
  font-family: "RakutenRoundSemiBold";
  font-size: 1rem;
  line-height: 1.25rem;
  color: #ffffff;
  padding: 0.7rem 1.5rem;
  border: none;
  text-transform: uppercase;
  padding: 0.7rem 1.5rem;
  height: 2.7rem;
  width: 6.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cancel-button-email-action-modal {
  height: 2.7rem;
  background-color: #828282;
  border-radius: 0.25rem;
  font-family: "RakutenRoundSemiBold";
  font-size: 1rem;
  line-height: 1.25rem;
  color: #ffffff;
  padding: 0.7rem 1.5rem;
  border: none;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}
