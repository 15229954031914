@media screen {
  .learningpath-home-heading {
    color: #000000;
    font-family: "RakutenRoundRegular";
    font-size: 2.25rem;
    letter-spacing: 0;
    line-height: 2.125rem;
  }

  .learningpath-home-heading-row {
    padding-bottom: 40px;
  }

  .learningpath-page-link {
    font-family: "RakutenRoundRegular";
    margin-right: 0.7rem;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 24px;
  }
}

@media screen and (max-width: 425px) {
  .learningpath-home-heading {
    color: #000000;
    font-family: "RakutenRoundSemiBold";
    font-size: 1.375rem;
    letter-spacing: 0;
    line-height: 2.125rem;
    padding-left: 0rem;
    margin-left: 0.5rem;
  }

  .learningpath-home-heading-row {
    padding-bottom: 1rem;
    margin-right: 0.5rem;
  }
}
