.successVariant {
	background-color: #047205;
}

.errorVariant {
	background-color: #ff0000;
}

.normalVariant {
	background-color: #00a0f0;
}

.primaryButton {
	border-radius: 0.25rem;
	font-family: "RakutenRoundSemiBold";
	font-size: 1rem;
	line-height: 1.25rem;
	color: #ffffff;
	padding: 0rem 0rem;
	border: none;
	text-transform: uppercase;
	width: 7rem;
	height: 2rem;
}

.primaryButton:hover {
	background-color: "#ffffff"
}

.iconStyle {
	padding: 0.25em;
}