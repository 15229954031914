.buttonStyle {
  font-family: "RakutenRoundRegular";
  min-width: 1.5rem;
  min-height: 1.5rem;
  border: 0.125rem solid #000;
  color: #000;
  text-align: center;
  background: #fff;
  border-radius: 0.25rem;
}
