@import "../../../../variables.scss";
.secButton {
  height: 3rem;
  width: 10.125rem;
  color: $primary;
  margin-right: 1rem;
  background-color: white;
  border: 0.063rem solid $primary;
  border-radius: 0.25rem;
}

.browseText {
  color: $primary;
  font-family: "RakutenRoundRegular";
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.063rem;
  text-align: center;
  cursor: pointer;
}

.innerBox {
  box-sizing: border-box;
  height: 14.688rem;
  width: 49.625rem;
  border: 0.188rem dashed $primary;
  border-radius: 0.5rem;
  margin: 0 auto;
  margin-top: 2.625rem;
  padding-top: 3.75rem;
}
