.topColumn {
	padding: 0;
	margin-right: 1.4375rem;
}

.innerColumn {
	padding: 0;
	margin: 0;
}

.right {
	margin: 0 !important;
	padding: 0 !important;
}

.text_field {
	height: 2.875rem;
	max-width: 26.5625rem;
	font-family: "RakutenRoundSemiBold";
	font-size: 1rem;
	font-weight: 600;
	margin-bottom: 2.46875rem;
}

.image {
	max-width: 26.5rem;
	height: 17.1875rem;
	margin-top: 2rem;
	border-radius: 0.3125rem;
}

.url {
	max-width: 23.375rem;
	margin-top: 0.90625rem;
	margin-left: -10rem;
}

.box_image {
	height: 5.5rem;
	width: 5.3125rem;
	/* margin-top: 4.3125rem;
	margin-left: 4.4375rem; */
	background-color: "cornflowerblue";
	border-radius: 0.375rem;
}

.primryButton {
	width: 10.875rem;
}
.adminUpdateButton{ 
	background-color: #00a0f0;
    font-family: "RakutenRoundSemiBold";
    color: #ffffff;
    border-radius: 0.25rem;
	border-top-right-radius: 0.25rem !important;
	border-bottom-right-radius: 0.25rem !important;
    text-transform: uppercase;
}

.secButton {
	width: 10.875rem;
	border: 0.0625rem solid #019ff0;
	color: #019ff0;
	background-color: white;
}

.editButton {
	height: 2.0625rem;
	width: 2.0625rem;
	margin-left: 15.125rem;
	position: absolute;
	border: 0.125rem solid white;
	border-radius: 50%;
	background-color: white;
	top: 2.875rem;
	right: 1.0625rem;
	cursor: pointer;
}

.biggerSvgBox {
	background-color: #cdced0;
	top: 2.875rem;
	right: 1.0625rem;
	margin-top: -0.75rem;
	height: 17.625rem;
	border-radius: 0.3125rem;
}

.biggerSvg {
	margin-top: 6.375rem;
	margin-left: 9.25rem;
	cursor: pointer;
}

.imageText {
	color: #000000;
	font-family: "RakutenRoundRegular";
	font-size: 0.875rem;
	letter-spacing: 0;
	line-height: 1.0625rem;
	text-align: center;
	margin-top: 5.3125rem;
}

.courseOriginTitle {
	color: #000000;
	font-family: "RakutenRoundSemiBold";
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.25rem;
}

.courseOriginTitleOuterBox {
	margin-left: 2.375rem;
	max-width: 13.125rem;
	padding: 0;
}

.countryOriginCheckNote {
	height: 2rem;
	width: 41.6875rem;
	color: #000000;
	font-family: "RakutenRoundRegular";
	font-size: 0.8125rem;
	letter-spacing: 0;
	line-height: 1rem;
}

.approvalCheckBox{
	content: "\2713";
	border: 0.125rem solid black;
	margin-left: 0rem;
	display: inline-block;
	border-radius: 0.2rem;
	width: 1.5rem;
	height: 1.5rem;
	vertical-align: top;
	color: transparent;
	transition: 0.2s;
	
}

.approvalCheckBox:checked{
	background-color: #019ff0;
	color: #fff;
}

.approvalTitle{
	color: #000000;
	font-family: "RakutenRoundSemiBold";
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.25rem;
	margin-left: -40.3rem;
}

.adminNotes{
	max-width: 82.5rem;
	margin-bottom: 2rem;
}
