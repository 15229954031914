.buttonStyle {
	font-family: "RakutenRoundRegular";
	min-width: 1.5rem;
	min-height: 1.5rem;
	color: #000;
	text-align: center;
	background: transparent;
	border: none;
	background-color: #fff;
	border-radius: 50%;
	padding: 0.5rem 0.75rem;
	width: fit-content;
}

/* .buttonStyle img {
	width: 100;
	height: 100;
} */
