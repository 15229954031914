@import "../../variables.scss";

@media screen {
  .detailLabel {
    font-size: 2rem;
    margin-bottom: 1rem;
    word-wrap: break-word;
    font-family: "RakutenRoundRegular";
  }
  .contentPadding {
    word-wrap: break-word;
    padding-right: 1rem;
    width: 594px;
    padding-bottom: 50px;
    overflow: hidden;
    padding-left: 0.3rem;
  }
  .contentPadding > li {
    list-style-type: circle;
  }
  .contentPadding a {
    text-decoration: underline !important;
    color: $primary !important;
    /* font-family: "RakutenRoundRegular"; */
  }
}
@media screen and (max-width: 476px) {
  .detailLabel {
    font-size: 20px;
    /* margin-bottom: 1rem; */
    word-wrap: break-word;
    font-family: "RakutenRoundRegular";
    padding-left: 20px;
  }
  .contentPadding {
    word-wrap: break-word;
    padding-right: 1rem;
    width: 324px;

    padding-bottom: 20px;
    padding-left: 20px;
  }
  .contentPadding a {
    text-decoration: underline !important;
    color: $primary !important;
    /* font-family: "RakutenRoundRegular"; */
  }
}
