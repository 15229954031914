@import "../../../../variables.scss";
.custom-bread-crumb {
  margin-left: 40px;
  margin-top: 40px;
}

.language-header-container {
  padding: 16px;
  margin-left: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.language {
  margin-right: 16px;
  font-family: "RakutenRoundSemiBold";
}

.identity-id {
  font-family: "RakutenRoundRegular";
  margin-left: 4px;
}

.course-hours-container {
  margin-top: 12px;
  padding: 16px;
  margin-left: 24px;
}

.course-container {
  margin-right: 8px;
  background: white;
  padding: 16px;
}

.course {
  background: #f7f7f7;
  border-radius: 50%;
  padding: 10px;
  padding-left: 14px;
  padding-right: 14px;
  margin-right: 8px;
}

.hours {
  background: #f7f7f7;
  border-radius: 50%;
  padding: 10px;
  padding-left: 12px;
  padding-right: 12px;
  margin-right: 8px;
}

.hours-container {
  margin-left: 8px;
  background: white;
  padding: 16px;
}

.completed-user-count-container {
  margin-top: 12px;
  padding: 16px;
  margin-left: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.bookmark-icon {
  margin-left: 8px;
}

.banner-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen {
  .course-description-banner-content {
    padding-left: 128px;
    margin-bottom: 32px;
  }
  .title-container {
    padding: 16px;
    margin-left: 24px;
    margin-top: 32px;
  }

  .title-heading {
    margin-left: 8px;
    font-family: "RakutenRoundRegular";
  }

  .title-value {
    font-size: 48px;
    font-family: "RakutenRoundSemiBold";
  }

  .bookmark-button {
    color: $primary;
    border-color: $primary;
  }

  .banner-image {
    clip-path: circle();
    width: 420px;
  }
}

@media screen and (min-width: 4320px) {
  .course-description-banner-content {
    padding-left: 34%;
    margin-bottom: 32px;
  }
  .banner-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 30%;
  }
}

@media screen and (min-width: 3400px) and (max-width: 4319px) {
  .course-description-banner-content {
    padding-left: 34%;
    margin-bottom: 32px;
  }
  .banner-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 25%;
  }
}

@media screen and (min-width: 3200px) and (max-width: 3400px) {
  .course-description-banner-content {
    padding-left: 34%;
    margin-bottom: 32px;
  }
  .banner-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 23%;
  }
}

@media screen and (min-width: 3000px) and (max-width: 3200px) {
  .course-description-banner-content {
    padding-left: 34%;
    margin-bottom: 32px;
  }
  .banner-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 20%;
  }
}

@media screen and (min-width: 2880px) and (max-width: 3000px) {
  .course-description-banner-content {
    padding-left: 34%;
    margin-bottom: 32px;
  }
  .banner-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 17%;
  }
}

@media screen and (min-width: 2160px) and (max-width: 2880px) {
  .course-description-banner-content {
    padding-left: 25.7%;
    margin-bottom: 32px;
  }
  .banner-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 15%;
  }
}

@media screen and (min-width: 1920px) and (max-width: 2160px) {
  .course-description-banner-content {
    padding-left: 17.5%;
    margin-bottom: 32px;
  }
}

@media screen and (min-width: 1800px) and (max-width: 1920px) {
  .course-description-banner-content {
    padding-left: 13.5%;
    margin-bottom: 32px;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1800px) {
  .course-description-banner-content {
    padding-left: 7.5%;
    margin-bottom: 32px;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1600px) {
  .course-description-banner-content {
    padding-left: 1.5rem;
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 1440px) {
  .course-description-banner-content {
    padding-left: 1.5rem;
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 768px) {
  .course-description-banner-content {
    padding-left: 0rem;
    margin-bottom: 32px;
  }
  .title-container {
    padding: 16px;
    margin-left: 24px;
    margin-top: 32px;
  }

  .title-heading {
    margin-left: 8px;
    font-family: "RakutenRoundRegular";
  }

  .title-value {
    font-size: 2rem;
    font-family: "RakutenRoundSemiBold";
  }

  .bookmark-button {
    color: $primary;
    border-color: $primary;
    margin-top: 1rem;
  }

  .banner-image {
    clip-path: none;
    width: 420px;
  }
}

.region-error-text {
  color: #bf0000;
  font-family: "RakutenRoundRegular";
  font-size: 16px;
}

@media screen and (max-width: 467px) {
  .banner-image {
    width: 100vw;
  }
}
