@import "../../../../variables.scss";

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.seats-container {
  background: white;
  border-radius: 8px;
  padding-top: 8px;
}

.empty-session-phrase {
  margin-top: 16px;
  padding: 8px;
  font-family: "RakutenRoundRegular";
}

.empty-session-phrase-text {
  font-family: "RakutenRoundRegular";
}

.classroom-schedule {
  font-family: "RakutenRoundSemiBold";
  color: $primary;
  font-size: 24px;
  margin-bottom: 16px;
}

.no-sessions-container {
  background: #f7f7f7;
  padding-right: 8px;
  border-radius: 8px;
}
