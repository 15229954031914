.outer {
	width: 13.938rem;
	height: 2.625rem;
	font-family: "RakutenRoundRegular";
	border-color: #000;
	border-style: solid;
	border-width: 0.063rem;
	border-radius: 0.375rem;
	font-weight: 600;
	margin: 3rem 0;
}
label {
	margin-bottom: 0rem;
}
