@import "../../../variables.scss";

.avatar {
  font-family: "RakutenRoundSemiBold";
  font-size: 5rem;
  height: 6.3125rem;
  margin-bottom: 2rem;
  color: $primary;
}

.label {
  font-family: "RakutenRoundRegular";
  font-size: 0.875rem;
  height: 1.0625;
  margin-bottom: 0.5rem;
}

.profileContainer {
  margin-top: 4rem;
  margin-left: 4rem;
}

.myProfile {
  // width: 111rem;
  margin: auto;
}

.employeeName {
  text-align: center;
  font-family: "RakutenRoundRegular";
  font-size: 1.5rem;
  padding-left: 1.5rem;
}

.employeeId {
  text-align: center;
  margin-top: 0.5rem;
  padding-left: 1rem;
}

.employeeIdLabel {
  font-family: "RakutenRoundRegular";
  font-size: 1rem;
  height: 1.25rem;
}

.employeeIdValue {
  font-family: "RakutenRoundSemiBold";
  font-size: 1rem;
  height: 1.25rem;
}

.employeeInfo {
  font-family: "RakutenRoundSemiBold";
  font-size: 1.25rem;
  height: 1.5625rem;
  color: $primary;
  margin-bottom: 4.1875rem;
}

@media screen {
  .profileBreadcrumb {
    padding-top: 3rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-family: "RakutenRoundRegular";
  }

  .profileBreadcrumb a {
    color: $primary;
  }
}

@media screen and (max-width: 1600px) {
  .myProfile {
    padding-left: 4rem;
  }
}

@media screen and (max-width: 1440px) {
  .myProfile {
    padding-left: 0rem;
  }
}

@media screen and (max-width: 476px) {
  .employeeInfo {
    font-size: 1rem;
    margin-bottom: 1.1875rem;
  }

  .avatar {
    margin-bottom: 2rem;
  }

  .employeeName {
    font-family: "RakutenRoundSemiBold";
    font-size: 1rem;
    padding-left: 1.1rem;
    padding-top: 0.4rem;
  }

  .employeeId {
    text-align: center;
    margin-top: 0rem;
    padding-left: 2rem;
  }

  .employeeIdLabel {
    font-family: "RakutenRoundRegular";
    font-size: 0.8rem;
    height: 1.25rem;
  }

  .profileContainer {
    margin-top: 0rem;
    margin-left: 1.5rem;
  }

  .myProfile {
    display: none;
  }
}
