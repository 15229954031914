@import "../../../../variables.scss";

.esc-switch-button {
  font-family: "RakutenRoundSemiBold";
  font-size: 1rem;
  line-height: 0.5rem;
  color: $primary;
  background-color: white;
  border: 1px solid $primary;
  border-radius: 3px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 17rem;
  height: 3rem;
}

.esc-modal-buttons {
  margin-left: 12rem;
}

.esc-parentcategorymodal {
  color: $primary;
  font-family: "RakutenRoundRegular";
  font-size: 1.125rem;
  line-height: 1.375rem;
  margin-left: 0.3rem;
}

.esc-subTitlemodal {
  font-family: "RakutenRoundSemiBold";
  font-size: 1.125rem;
  line-height: 1.375rem;
}

.esc-cancel-button {
  margin: 1rem;
  background-color: #828282;
  border-radius: 0.25rem;
  font-family: "RakutenRoundSemiBold";
  font-size: 1rem;
  line-height: 1.25rem;
  color: #ffffff;
  padding: 0.7rem 1.5rem;
  height: 2.5rem;
  border: none;
}
.esc-submit-button {
  margin: 1rem;
  background-color: $primary;
  border-radius: 0.25rem;
  font-family: "RakutenRoundSemiBold";
  font-size: 1rem;
  line-height: 1.25rem;
  color: #ffffff;
  padding: 0.7rem 1.5rem;
  border: none;
  height: 2.5rem;
  margin-top: 15px;
}
