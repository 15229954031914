.crane-select {
  position: relative;
}
.crane-select.open .crane-select-input > input {
  cursor: text;
}
.crane-select.open .crane-select-input-group {
  border-bottom: 0;
  padding-bottom: 1px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.crane-select.open .crane-select-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #666;
  border-top: 0;
}
.crane-select.open .crane-select-arrow:hover {
  border-bottom: 5px solid #262626;
}
.crane-select.focus:not(.open) .crane-select-input-group {
  border: 1px solid #5e9ed6;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}
.crane-select.focus:not(.open).outer-focus .crane-select-input > input {
  opacity: 0;
}
.crane-select.disabled .crane-select-arrow-container {
  cursor: default;
  pointer-events: none;
  opacity: 0.35;
}
.crane-select.disabled .crane-select-input-group {
  background-color: #f9f9f9;
  user-select: none;
}
.crane-select.disabled .crane-select-input-group:hover {
  box-shadow: none;
}
.crane-select-input-group {
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  /* make table so arrow and clear button can vertically center */
  display: table;
  width: 100%;
  box-sizing: border-box;
}
.crane-select-value-group {
  /* make absolute so it appears under the arrow and clear button */
  position: absolute;
  top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 46px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}
.crane-select-group-header {
  font-weight: bold;
}
.crane-select-focus-placeholder,
.crane-select-input > input {
  height: 2.875rem;
  display: inline-block;
  vertical-align: middle;
  outline: none;
}
.crane-select-input > input {
  width: 100%;
  border: 0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0 5px 0 10px;
  background: none transparent;
  box-shadow: none;
  cursor: default;
  font-family: inherit;
  font-size: inherit;
  outline: none;
}
.crane-select-inner-input {
  display: inline-block;
  margin-left: -1px;
}
.crane-select-focus-placeholder {
  border: 0;
  width: 1px;
  padding: 0;
  background: none;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.crane-select-loading-container {
  /* make table-cell so it can vertically center */
  display: table-cell;
  cursor: pointer;
  width: 20px;
  text-align: center;
  vertical-align: middle;
}
/* credit to https://projects.lukehaas.me/css-loaders/ */
.crane-select-loading {
  font-size: 1px;
  margin: 10px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #444;
  background: linear-gradient(to right, #444 10%, rgba(0, 0, 0, 0) 42%);
  position: relative;
  animation: spinner 1.4s infinite linear;
  transform: translateZ(0);
}
.crane-select-loading:before {
  width: 50%;
  height: 50%;
  background: #444;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}
.crane-select-loading:after {
  background: #fff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.crane-select-menu-container {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid #ddd;
  max-height: 250px;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 9000;
  box-sizing: border-box;
  background-color: #fff;
}
.crane-select-menu {
  max-height: 248px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.crane-select-option {
  padding: 10px;
  vertical-align: middle;
}
.crane-select-option.focused {
  background: #e7eff7;
  outline: none;
}
.crane-select-placeholder {
  color: #757575;
}
.crane-select-arrow-container {
  /* make table-cell so it can vertically center */
  display: table-cell;
  cursor: pointer;
  width: 20px;
  text-align: center;
  vertical-align: middle;
  padding-right: 5px;
  /* make relative to it is clickable over absolute-positioned value-group */
  position: relative;
}
.crane-select-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #666;
  display: inline-block;
}
.crane-select-arrow:hover {
  border-top: 5px solid #262626;
}
.crane-select-clear-container {
  /* make table-cell so it can vertically center */
  display: table-cell;
  cursor: pointer;
  width: 20px;
  text-align: center;
  vertical-align: middle;
  /* make relative to it is clickable over absolute-positioned value-group */
  position: relative;
}
.crane-select-clear {
  font-size: 18px;
  color: #666;
}
.crane-select-clear:hover {
  color: #262626;
}
.crane-select-empty-divider {
  border-top: 1px solid #ddd;
}
.crane-select-static-divider {
  border-top: 1px solid #ddd;
}
.crane-select-loading-text,
.crane-select-no-results {
  border: 1px solid #ddd;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 10px;
  vertical-align: middle;
  color: #757575;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 9000;
  box-sizing: border-box;
  background-color: #fff;
}
.sr-only {
  z-index: 9999;
  border: 0;
  clip: "rect(1px, 1px, 1px, 1px)";
  height: 1px;
  width: 1px;
  position: absolute;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
}

input[type="checkbox"] {
  margin-right: 0.2rem;
}

.crane-select-placeholder {
  color: black;
}

.crane-select {
  margin-bottom: 2.25rem;
}
