.mailToUserTitle {
  color: #000000;
  font-family: "RakutenRoundSemiBold";
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 3.063rem;
}

.textFields {
  box-sizing: border-box;
  height: 3.125rem;
  width: 56rem;
  border: 0.063rem solid #9c9c9c;
  border-radius: 0.375rem;
}

.modal {
  margin-left: -18.75rem;
  min-width: 60rem;
  min-height: 37.125rem;
  align-content: center;
  border-radius: 0.5rem;
}

.cancelButton {
  height: 2.871rem;
  width: 10rem;
  border-radius: 0.25rem;
}

.submitButton {
  margin-left: 0rem !important;
  margin-top: 0rem !important;
  height: 2.871rem;
  width: 10rem;
  border-radius: 0.25rem;
  background-color: #00a0f0;
  font-family: "RakutenRoundRegular";
}

.note {
  color: #000000;
  font-family: "RakutenRoundRegular";
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.063rem;
}

.custom_toggle {
  width: 14.125rem;
  height: 2.625rem;
  background-color: #d2eaf6;
  border-radius: 0.375rem;
  margin-right: 1.473rem;
  font-family: "RakutenRoundRegular";
}

.icon_box {
  background-color: #00a0f0;
  max-width: 2rem;
  height: 2rem;
  margin-top: 0.3125rem;
  margin-left: 0.3125rem;
  border-radius: 0.25rem;
  padding-left: 0.375rem;
}

.toggle_title {
  font-family: "RakutenRoundSemiBold";
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 0.875rem;
  letter-spacing: 0;
  margin-top: 0.8rem;
  margin-left: 0.589375rem;
  padding: 0;
  max-width: 10.375rem;
  color: #019ff0;
}

.quillEmail {
  width: 56.25rem;
  box-sizing: border-box;
  background-color: white;
  height: 21.375rem;
}
