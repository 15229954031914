
.tile {
  height: 16.438rem;
  width: 23.563rem;
  border-radius: 0.5rem;
  background-color: #FFFFFF;
  box-shadow: 0 0.125rem 0.25rem 0 #D1D1D1;
  padding-top: 1.25rem;
  padding-left: 1.25rem;
  margin-right: 4rem;
  display: inline-block;
}

.tile:hover {
  box-shadow: 0 0.125rem 0.75rem 0 #D1D1D1;
  cursor: pointer;
}

.tileImage {
  background-position: center center;
	background-repeat: no-repeat;
	background-size: auto 9.125rem;
  height: 9.125rem;
  width: 11.25rem;
  border-radius: 0.5rem;
  margin-top: 1.563rem;
}

.subheadingImage {
	width: 1.4375rem;
}

.subheadingText {
	font-family: RakutenRoundRegular;
	font-size: 0.75rem;
	margin-left: 0.3125rem;
	color: #333333;
}

.wishlistIcon {
    height: 1.124rem;
    width: 1.254rem;
    float: right;
    margin-right: 1.25rem;
}

.title {
  height: 3.75rem;
  width: 19.563rem;
  color: #000000;
  font-family: "RakutenRoundRegular";
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin-top: 1.25rem;
}

.title a {
  color: #000000 !important;
}

.content {
  color: #000000;
  font-family: "RakutenRoundRegular";
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.438rem;
  margin-top: 1.25rem;
  width: 12.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.provider {
  color: #000000;
  font-family: "Rakuten Rounded";
  font-size: 0.75rem;
  letter-spacing: 0;
  line-height: 1.063rem;
  margin-top: 2.5rem;
  width: 12.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.courseProviderIcon {
  height: 0.875rem;
  width: 0.813rem;
  margin-right: 0.313rem;
}

.heartImage {
  margin-left: 15.5rem;
}

.favouriteIcon{
  height: 20px;
  width: 20px;
}