.alertText {
    font-family: "RakutenRoundBold";
    font-size: 0.813rem;
    text-align: center;
    color: tomato;
}

.disclaimerMessage {
    font-family: "RakutenRoundRegular";
    font-size: 0.813rem;
    text-align: left;
    color: #333333;
    margin-left: 1.25rem;
    margin-top: 1.063rem;
}

.functionalBox {
    min-height: 22.813rem;
    position: relative;
    margin-left: 1.25rem;
    display: inline-block;
    background-color: #F7F7F7;
    white-space: initial;
    border-radius: 0.5rem;
    margin: auto 64px;
}

.boldTitle {
    font-family: "RakutenRoundSemiBold";
    font-size: 1.5rem;
    line-height: 3.063rem;
}

.boldSubtitle {
    font-family: "RakutenRoundSemiBold";
    font-size: 1rem;
    line-height: 1.25rem;
}

.title {
    font-family: "RakutenRoundRegular";
    font-size: 1.5rem;
    margin-left: 64px;
    line-height: 3.063rem;
    margin-bottom: -0.625rem;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}

.cancelButton {

    margin-right: 1.5rem;
}

.footer {
    border-top: none;
}

.header {
    margin: 33px 0px;
    border-bottom: none;
}

.footerButton {
    margin-top: 2.125rem;
    margin-left: 28.875rem;
    margin-bottom: 2.125rem;
}

.textBox {
    font-family: 'RakutenRoundRegular';
    font-size: 16px;
    padding-top: 24px;
    text-align: center;
}


.leftBox {
    min-height: 233px;
    margin-top: 24px;
    display: inline-block;
    background-color: white;
    white-space: initial;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
    width: 390px;
}


.rightBox {
    position: relative;
    min-height: 233px;
    margin-top: 24px;
    display: inline-block;
    background-color: white;
    white-space: initial;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
    width: 390px;
}

.innerBox {
    margin: 30px 24px;
}

.circleBox {
    border-radius: 50%;
    background-color: white;
    background: white;
    width: 2.75rem;
    height: 2.75rem;
    margin: auto 24px;
    margin-top: 8.625rem;
    text-align: center;
    padding-top: 0.563rem;
}

.buttonBox {
    box-sizing: border-box;
    height: 128px;
    border: 1px dashed #9C9C9C;
    border-radius: 4px;
    background-color: #F7F7F7;
    margin: 24px 24px 18px 24px;
}

.browseButton {
    margin: 24px auto 18px auto;
    width: 200px;
}

.footText {
    font-family: "RakutenRoundRegular";
    font-size: 0.75rem;
    text-align: center;
}

.fileBox {
    height: 28px;
    border-radius: 4px;
    background-color: #EBEBEB;
    margin: auto 24px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    width: fit-content;
}

.fileText {
    line-height: 18px;
    color: #00A0F0;
    font-family: "RakutenRoundRegular";
    font-size: 14px;
    letter-spacing: 0;
    margin-left: 10px;
    margin-right: 10px;
}

.leftTitle {
    color: #000000;
    font-family: "RakutenRoundRegular";
    font-size: 0.875rem;
    letter-spacing: 0;
    line-height: 1.063rem;
}

.form {
    margin-top: 1.281rem;
    border-style: none;
    font-family: "RakutenRoundRegular";
}

.hint {
    color: #333333;
    font-family: "RakutenRoundRegular";
    font-size: 0.75rem;
    text-align: center;
    width: 200px;
    margin: 0px auto;
}

.privateSession {
    color: #F59600;
}

.publicSession {
    color: #00A0F0;
}

.p24 {
    padding: 0px 24px;
}

.removeFile {
    height: 8px;
    width: 8px;
}