/* .modal {
	margin-left: -3.75rem;
	min-width: 40.43rem;
	min-height: 12.813rem;
	border-radius: 1rem;
} */

.modal {
  margin-left: 8rem;
  min-width: 40.43rem;
  min-height: 12.813rem;
  border-radius: 1rem;
}

.header {
  border-bottom: none;
}

.buttonStyle {
  min-width: 10rem;
}

.modalContent {
  margin: -1rem 1rem 1rem 2.25rem;
}

.bellHeader {
  position: relative;
}

.bell {
  width: 4.75rem;
  height: 4.75rem;
  border-radius: 50%;
  position: absolute;
  top: -1.5rem;
  right: -1.5rem;
}

.modalbody {
  text-align: center;
  margin-right: 1.5rem;
  margin-top: 1rem;
}

.buttonWrapper {
  padding-top: 1rem;
  display: block;
  padding-left: 1.5rem;
}

.leftButton {
  margin-right: 1rem;
}

.rightButton {
  margin-left: 1rem;
}

@media screen and (max-width: 476px) {
  .modal {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 6rem;
    min-width: 20.5rem;
    min-height: 14rem;
  }

  .bell {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    position: absolute;
    top: -1.5rem;
    right: -1.5rem;
  }

  .buttonStyle {
    width: 16rem;
    margin-left: -1rem;
  }

  .header {
    margin-bottom: -1rem;
  }

  .leftButton {
    margin-left: 1rem;
  }

  .rightButton {
    margin-left: 0rem;
    width: 16rem;
  }
}
