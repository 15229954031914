@import "../../../variables.scss";

.span-course-count {
  color: $primary;
}
@media screen {
  .countText {
    height: 2.125rem;
    width: 54.4375rem;
    color: #000000;
    font-family: "RakutenRoundRegular";
    font-size: 0.875rem;
    letter-spacing: 0;
    line-height: 1.0625rem;
  }

  .il-course-count {
    margin-left: -27rem;
  }

  .infinite-scroll-container {
    align-items: center;
    justify-content: center;
  }

  .il-second-container {
    width: 100 !important;
    padding: 0rem;
    padding-bottom: 7.5rem;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0.5rem;
  }

  .container {
    max-width: 100rem;
    background-color: white;
    padding: 0;
    position: relative;
    margin-top: 7rem;
    margin-left: 0rem;
  }

  .elearning-common-container {
    padding: 3rem;
    margin-top: 3rem;
  }

  .course-list {
    // display: grid;
    // grid-template-columns: repeat(4, 1fr);
    // grid-template-rows: repeat(4, 1fr);
  }

  .il-course-filter {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .il-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 1400px) {
  .il-course-filter {
    margin-left: 0;
    margin-right: 0;
  }
  .il-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1310px) {
  .il-course-count {
    margin-left: -20rem;
  }
}

@media screen and (max-width: 1250px) {
  .il-course-count {
    margin-left: -16rem;
  }
}

@media screen and (max-width: 1230px) {
  .il-second-container {
    margin-left: -1rem;
  }
}

@media screen and (max-width: 1200px) {
  .il-second-container {
    margin-left: -2rem;
  }
}

@media screen and (max-width: 768px) {
  .il-grid {
    justify-content: center;
    margin-left: 1rem;
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
  }

  .il-course-count {
    margin-left: 0rem;
  }

  .countText {
    height: 2.125rem;
    width: 54.4375rem;
    color: #000000;
    font-family: "RakutenRoundRegular";
    font-size: 0.875rem;
    letter-spacing: 0;
    line-height: 1.0625rem;
    margin: 0.5rem;
  }

  .infinite-scroll-container {
    align-items: center;
    justify-content: center;
  }

  .il-second-container {
    width: 23rem;
    /* margin: 0rem; */
    padding: 0rem;
    padding-bottom: 0rem;
    background-color: white;
    margin-left: 0.5rem;
  }

  .container {
    max-width: 23rem;
    width: 23rem;
    background-color: white;
    padding: 0;
    position: relative;
    margin-top: 7rem;
    margin-left: 0rem;
  }

  .ilearning-common-container {
    padding: 0.5rem;
    margin-top: 2rem;
  }
}
