@import "../../../../variables.scss";

.course-launch-button {
  // display: flex;
  // align-items: center;
  // justify-content: center;
}

.course-launch-button-container {
  width: 100%;
  margin-left: 20%;
  margin-right: 20%;
}

.course-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-collapse {
  .ant-collapse-item {
    .ant-collapse-expand-icon {
      align-self: center;
      .ant-collapse-arrow {
        font-size: 16px;
        margin-right: 8px;
      }
    }
  }
}

.completed-timeline-dot-tick {
  .check-icon {
    font-size: 16px;
  }
}
.in-progress-timeline-dot {
  background: $primary;
  color: white;
  border: 1px solid white;
  outline: 2px solid $primary;
}

.custom-timeline-item {
  .ant-timeline-item-content {
    margin-left: 40px;
  }
}

.completed-timeline-dot {
  background: $primary-with-opacity;
}

.completed-timeline-dot-tick {
  color: $primary;
}

.inactive-course-text {
  margin-top: 8px;
  font-family: "RakutenRoundRegular";
}

.hours-row-container {
  display: flex;
  align-items: center;
}

.course-provider-icon {
  width: 16px;
  display: flex;
  margin-left: 16px;
}
