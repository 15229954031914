.searchIcon {
	position: absolute;
	right: 0rem;
	top: 0.75rem;
	/* z-index: 9999; */
	background: url("../../assets/icons/searchIcon.svg") no-repeat;
	width: 2rem;
	height: 2rem;
}

.searchInput {
	font-family: "RakutenRoundRegular";
	width: 12.5rem;
	height: 2.75rem;
	border-radius: 0.25rem;
	border-top-right-radius: 0.25rem !important;
	border-bottom-right-radius: 0.25rem !important;
}