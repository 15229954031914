@import "../../../variables.scss";

.moduleList-addNewCourse {
  background-color: $primary;
  color: white;
  margin-top: 1rem;
  width: 10rem;
  height: 2.2rem;
  text-transform: uppercase;
  font-family: "RakutenRoundRegular";
}

.legendStyle {
  position: relative;
  top: 3rem;
}

.searchBar {
  top: 3rem;
  /* left: 12rem; */
  position: relative;
}

.pageFilter {
  position: relative;
  top: 3.5rem;
  /* left: 15rem; */
}

.tableSearch {
  position: absolute;
  top: 7.6rem;
  left: 70rem;
  width: 12.5rem;
}

.downloadButtonStyle {
  position: absolute;
  top: 7.6rem;
  left: 66rem;
}

.numberButton {
  border: 2px solid black;
  border-radius: 4px;
  padding: 2px;
}

.wrapText {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-height: 1.58rem;
}

.subTitle {
  margin: 0;
}

.rowExpandStyle {
  padding: 1rem;
  margin: 1rem;
  background-color: #f7f7f7;
}

.rowExpandTitle {
  font-family: "RakutenRoundSemiBold";
  font-size: 1rem;
  padding: 0 1rem;
}

.rowExpandDetails {
  background-color: #f7f7f7;
}

.rowPadding {
  padding: 0.5rem 1rem;
}

.zoomValue {
  font-family: "RakutenRoundSemiBold";
}

.zoomValueContainer {
  padding: 0 1rem;
}

.toggleExpandButton {
  transform: rotate(-180deg);
}

.moreDataColor {
  color: $primary;
}

.moduleList-table {
  width: 83rem;
  padding-top: 1rem;
}

.crumbs-module-list {
  color: $primary !important;
}

.crumbs-module-list:visited {
  color: $primary !important;
}

.moduleList-crumbs {
  width: 82rem;
  font-family: "RakutenRoundRegular";
}

@media screen and (max-width: 1400px) {
  .moduleList-table {
    width: 70rem;
  }

  .moduleList-crumbs {
    width: 70rem;
  }
}
