@import "../../../variables.scss";

@media screen {
  .rowAddImage {
    margin-top: 2.4375rem;
    width: 81rem;
  }

  .formImageAdd {
    background-color: #ffffff;
    border-radius: 0.375rem;
  }

  .biggerSvgBox {
    background-color: #ffffff;
    width: 25.45rem;
    height: 15.9375rem;
    border-radius: 0.3125rem;
    border: dashed 0.0625rem;
  }

  .biggerSvg {
    margin-top: 2.375rem;
    margin-left: 6.25rem;
    cursor: pointer;
    width: 13.75rem;
    height: 13.625rem;
  }

  .imageGuideline {
    width: 25.45rem;
    text-align: center;
    font-family: RakutenRoundRegular;
    font-size: 0.8125rem;
    line-height: 1.25rem;
    margin-top: 2.375rem;
    color: #828282;
  }

  .errorMessage {
    width: 25.45rem;
    font-family: RakutenRoundRegular;
    font-size: 0.8125rem;
    color: #ff0000;
    margin-top: 1rem;
  }

  .imageText {
    color: #000000;
    font-family: "RakutenRoundRegular";
    font-size: 0.8125rem;
    letter-spacing: 0;
    line-height: 1.0625rem;
    text-align: center;
    margin-top: 2.3125rem;
    font-weight: bold;
  }

  .secButton {
    width: 10.875rem;
    height: 3rem;
    border: 0.0625rem solid $primary;
    color: $primary;
    background-color: white;
  }

  .deleteButton {
    width: 10.875rem;
    height: 3rem;
  }

  .primryButton {
    width: 10.875rem;
    height: 3rem;
    text-transform: uppercase;
    font-family: "RakutenRoundSemiBold";
  }

  .inputButton {
    display: none;
  }

  .buttonGroup {
    margin-top: 2.5rem;
    margin-bottom: 3rem;
  }

  .secButtonContainer {
    margin: 0rem;
    padding: 0rem;
    margin-left: 0.9375rem;
  }

  .courseImageCrumbs {
    width: 82rem;
    margin-top: 1rem;
    font-family: "RakutenRoundRegular";
  }

  .courseImagePageTitle {
    font-family: "RakutenRoundSemiBold";
    font-size: 2.5rem;
    width: 82.2rem;
  }

  .courseImageForm {
    width: 83rem;
  }

  .title {
    font-family: "RakutenRoundRegular";
    font-size: 1.5rem;
    /* margin-left: 2.25rem;  */
    margin-bottom: 0.25rem;
  }

  .secondaryButtonStyle {
    width: 21rem;
    height: 2.7rem;
  }

  .modal {
    margin-left: -18.75rem;
    min-width: 66.313rem;
    min-height: 12.813rem;
  }

  .titleBold {
    font-family: "RakutenRoundSemiBold";
    font-size: 1.5rem;
    margin-bottom: 0.25rem;
  }

  .header {
    border-bottom: none;
  }

  .leftButton {
    margin-right: 3.438rem;
    margin-top: 2rem;
    margin-left: 5.25rem;
  }

  .buttonStyle {
    min-width: 10rem;
  }

  .modalContent {
    margin: -1rem 1rem 1rem 2.25rem;
  }

  .orLabel {
    position: relative;
    left: -1.75rem;
    font-family: "RakutenRoundSemiBold";
  }

  .courseImage-ButtonStyle {
    height: 3rem;
    width: 10rem;
    background-color: $primary;
    color: #ffffff;
    font-size: 1rem;
    font-family: "RakutenRoundRegular";
  }
}

@media screen and (max-width: 1400px) {
  .courseImageCrumbs {
    width: 72rem;
    margin-top: 1rem;
  }

  .courseImagePageTitle {
    width: 72.2rem;
  }

  .courseImageForm {
    width: 73rem;
  }

  .colAddImage {
    padding-left: 0;
  }
}

@media screen and (max-width: 476px) {
  .modal {
    min-width: 20.5rem;
    min-height: 13.875rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 6rem;
  }

  .buttonStyle {
    width: 18.5rem;
  }

  .orLabel {
    text-align: center;
    position: relative;
    left: 8.4rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .header {
    margin-bottom: -1rem;
  }

  .titleBold {
    font-family: "RakutenRoundSemiBold";
    font-size: 1.125rem;
    margin-bottom: 0.25rem;
  }

  .secondaryButtonStyle {
    width: -webkit-fill-available;
  }
}
