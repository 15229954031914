/* .quill {
  width: 56rem;
    box-sizing: border-box;
    border: 0.063rem solid #9C9C9C;
    border-radius: 0.375rem;
    background-color: white;
    height: 21.375rem;
  }

.ql-container {
    box-sizing: border-box;
    font-family: "RakutenRoundRegular";
    font-size: 0.813rem;
    height: 8.5rem;
    position: relative;
    height: 18.5rem;
} */

.modal-dialog {
  margin-top: 5rem;
  margin-bottom: 5rem;
  margin-left: 37rem;
}