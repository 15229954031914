@import "../../../variables.scss";

@media screen {
  .courseImage-pageHeader {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .courseImage-right {
    display: flex;
    align-items: center;
  }

  .courseimage-tag-style {
    height: 2rem;
    overflow: scroll;
    overflow-x: hidden;
    text-overflow: ellipsis;
    display: flex;
    flex-wrap: wrap;
    border: none;
    align-items: center;
    background-color: none;
  }

  .courseImage-searchIcon {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    z-index: 1;
    background: url("./../../../assets/icons/searchIcon.svg") no-repeat;
    width: 2rem;
    height: 2rem;
  }

  .courseImage-buttonStyle {
    white-space: nowrap;
    margin-left: 1.5rem;
  }

  .courseImage-imageTagComponent {
    width: 19.5rem;
  }

  .courseImage-searchInput {
    font-family: "RakutenRoundRegular";
    font-size: 0.8125rem;
    width: 14rem;
    height: 2.75rem;
    border-radius: 0.25rem;
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
    display: inline-flex;
  }

  .courseImage-imageContainer {
    margin-right: -1.5rem;
    width: 103rem;
  }

  .pageHeader {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .right {
    display: flex;
    align-items: center;
    margin-right: -40rem;
  }

  .searchIcon {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    z-index: 1;
    background: url("./../../../assets/icons/searchIcon.svg") no-repeat;
    width: 2rem;
    height: 2rem;
  }

  .imageTagComponent {
    width: 19.5rem;
  }

  .courseImageListCrumbs {
    width: 82rem;
    margin-top: 1rem;
    margin-bottom: 0.7rem;
    font-family: "RakutenRoundRegular";
  }

  .courseImagesSearch {
    height: 2.33rem;
    width: 15rem;
    right: -3rem;
    margin-left: -3rem;
    font-size: 1rem;
    font-family: "RakutenRoundRegular";
    border-radius: 3px;
  }

  .courseImageListPageTitle {
    font-family: "RakutenRoundSemiBold";
    font-size: 2.5rem;
    margin-left: -41.5rem;
  }

  .searchInput {
    font-family: "RakutenRoundRegular";
    font-size: 0.8125rem;
    width: 14rem;
    height: 2.3rem;
    border-radius: 0.25rem;
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
    display: inline-flex;
  }

  .imageContainer {
    margin-right: -1.5rem;
    width: 84rem;
    margin-top: 2rem;
  }

  .courseList-buttonStyle {
    background-color: $primary;
    color: #ffffff;
    font-family: "RakutenRoundRegular";
    font-size: 1rem;
    font-weight: 600;
    height: 2.4rem;
    width: 10rem;
    border-radius: 3px;
  }

  .courseList-iconStyle {
    padding-right: 0.5rem;
    padding-bottom: 1rem;
  }

  .basicStyle {
    display: inline-block;
    position: relative;
  }

  .editButton {
    height: 1.5rem;
    width: 1.5rem;
    position: absolute;
    border: 0.125rem solid white;
    border-radius: 50%;
    background-color: white;
    right: 0.6875rem;
    top: 0.6875rem;
  }

  .tagItem {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.375rem;
    margin-bottom: 0.375rem;
    border-width: 0.0625rem;
    border-style: solid;
    height: 1.6125rem;
    border-radius: 0.25rem;
    padding: 0.3125rem;
    font-family: RakutenRoundRegular;
    font-size: 0.875rem;
  }

  .editButtonImage {
    height: 0.8125rem;
    margin-left: 0.1875rem;
    margin-bottom: 0.125rem;
    cursor: pointer;
    color: $primary;
  }

  .mainImage {
    width: 100%;
    border-radius: 0.375rem;
  }
}

@media screen and (max-width: 1400px) {
  .courseImageListCrumbs {
    width: 72rem;
  }

  .courseImageListPageTitle {
    width: 47.2rem;
  }

  .imageContainer {
    margin-right: -1.5rem;
    width: 63rem;
  }
}
