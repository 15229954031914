@import "../../variables.scss";

.card {
  border-radius: 6px;
  border-color: #ffffff;
  background-color: #ffffff;
  margin: 1rem;
  width: 22rem;
}

.category-card-style {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  margin-top: 0rem;
  margin-bottom: 1.5rem;
  border-radius: 0.25rem;
  padding: 1rem;
}

.category-card-innerstyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0rem;
  font-family: "RakutenRoundSemiBold";
}
.category_name {
  color: $primary;
  font-family: "RakutenRoundRegular";
  font-size: 0.813rem;
  margin-bottom: 1rem;
  text-align: left;
}

.cardsubcategory {
  position: relative;
  margin-top: 13px;
  display: inline-block;
  background-color: #f7f7f7;
  white-space: initial;
}

.category {
  display: flexbox;
  color: #000000;
  font-family: "RakutenRoundSemiBold";
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: 0;
  margin-bottom: 0px;
}

.parentCategory {
  color: $primary;
  font-family: "RakutenRoundRegular";
  font-size: 0.813rem;
  letter-spacing: 0;
  margin-top: -5px;
  text-align: left;
  line-height: 1rem;
  padding-top: 5px;
}

.pencilIcon {
  width: 1.25rem;
  height: 1.25rem;
}

.subcategoryLabel {
  display: inline-block;
  min-width: 6.75rem;
  min-height: 2.313rem;
  border-style: solid;
  border-width: 1px;
  border-color: #9c9c9c;
  border-radius: 4px;
  padding-left: 0.625rem;
  padding-top: 0.313rem;
  margin: 0.438rem;
  padding-right: 0.625rem;
  font-family: "RakutenRoundRegular";
  white-space: normal;
  align-content: center;
}

.trashIcon {
  width: 1.25rem;
  height: 1.25rem;
}
.category-card-buttons {
  display: flex;
  flex-direction: row;
  margin-right: 0rem;
}

.category-tile-button {
  margin-left: auto;
  border-color: none;
  background-color: none;
  padding: 0 0 0 0;
  width: 2rem;
}

.cardsubcategory {
  width: 24.125rem;
  position: relative;
  margin-top: 0.813rem;
  display: inline-block;
  background-color: #f7f7f7;
  white-space: initial;
}

.category {
  display: flexbox;
  color: #000000;
  font-family: "RakutenRoundSemiBold";
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: 0;
  margin-bottom: 0px;
}

.parentCategory {
  color: $primary;
  font-family: "RakutenRoundRegular";
  font-size: 0.813rem;
  letter-spacing: 0;
  margin-top: -0.313rem;
  text-align: left;
  line-height: 1rem;
  padding-top: 0.313rem;
}

.pencilIcon {
  float: inline-end;
  width: 1.25rem;
  height: 1.25rem;
}

.subcategoryLabel {
  display: inline-block;
  min-width: 6.75rem;
  min-height: 2.313rem;
  border: solid 1px #9c9c9c;
  border-radius: 4px;
  padding-left: 0.625rem;
  padding-top: 0.313rem;
  margin: 0.438rem;
  padding-right: 0.625rem;
  font-family: "RakutenRoundRegular";
  white-space: normal;
  align-content: center;
}

.trashIcon {
  width: 1.25rem;
  height: 1.25rem;
}
