.alert {
  padding: 0.3125rem !important;
  position: relative;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  margin-top: 1.25rem;
}
@media screen and (max-width: 476px) {
.alert{
  padding: 0.3125rem !important;
  position: absolute;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  margin-top: 1.50rem;

  display: flex;
  align-items: center;
  width:21.5rem;
  font-family: "RakutenRoundRegular";
  font-weight: 600;
  color: black;
  margin-left: 1rem;
  margin-right: 1rem;
  z-index: 9999;
}
}