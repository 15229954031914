.tileContainer {
  height: 8.5rem;
  background-color: #ffffff;
  margin-bottom: 2rem;
  padding: 1rem;
  text-transform: uppercase;
  color: #303030;
  font-family: "RakutenRoundSemiBold";
  width: 90%;
}
.tileLabel {
  font-size: 0.875rem;
  line-height: 1rem;
}
.tileValue {
  font-size: 1.75rem;
}
