.path-course-details {
  background: white;
  border-radius: 16px;
}

.path-course-details-pane {
  padding: 32px;
  padding-left: 48px;
}

.course-description {
  font-size: 32px;
  font-family: "RakutenRoundRegular";
  margin-bottom: 16px;
}

.course-materials {
  border-color: #00a0f0;
  color: #00a0f0;
  font-family: "RakutenRoundRegular";
  border-radius: 0;
  font-size: 16px;
  height: 42px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 16px;
}

.timezone-alert {
  color: red;
}

.show-more-less-clickable {
  margin-top: 16px;
  text-decoration: none;
  color: #00a0f0;
  margin-right: 4px;
}

span.show-more-less-clickable {
  text-decoration: none;
}

.read-more-less-container {
  margin-top: 16px;
}

.course-details-child-container {
  padding: 16px;
  padding-left: 48px !important;
}

.render-course-detail {
  padding-left: 0px !important;
}

.path-course-additional-details {
  width: 90%;
}

@media screen and (min-width: 4320px) {
  .path-course-additional-details {
    width: 90%;
  }
}
@media screen and (min-width: 3400px) and (max-width: 4319px) {
  .path-course-additional-details {
    width: 90%;
  }
}
@media screen and (min-width: 3200px) and (max-width: 3400px) {
  .path-course-additional-details {
    width: 90%;
  }
}
@media screen and (min-width: 3000px) and (max-width: 3200px) {
  .path-course-additional-details {
    width: 90%;
  }
}
@media screen and (min-width: 2880px) and (max-width: 3000px) {
  .path-course-additional-details {
    width: 90%;
  }
}
@media screen and (min-width: 2160px) and (max-width: 2880px) {
  .path-course-additional-details {
    width: 90%;
  }
}
@media screen and (min-width: 1920px) and (max-width: 2160px) {
  .path-course-additional-details {
    width: 90%;
  }
}
@media screen and (min-width: 1800px) and (max-width: 1920px) {
  .path-course-additional-details {
    width: 90%;
  }
}
@media screen and (min-width: 1600px) and (max-width: 1799px) {
  .path-course-additional-details {
    width: 73.5%;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1599px) {
  .path-course-additional-details {
    width: 83.5%;
  }
}
