.sc-cancel-button {
  background-color: #828282;
  border-radius: 0.25rem;
  font-family: "RakutenRoundSemiBold";
  font-size: 1rem;
  line-height: 1.25rem;
  color: #ffffff;
  padding: 0.7rem 1.5rem;
  height: 2.5rem;
  border: none;
  width: 100%;
}

@media screen and (min-width: 4320px) {
  .sc-container {
    margin: auto;
  }
}

@media screen and (min-width: 4320px) {
  .sc-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 37.5%;
    margin-right: 34%;
  }
}

@media screen and (min-width: 3400px) and (max-width: 4319px) {
  .sc-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 33.5%;
    margin-right: 29.5%;
  }
}

@media screen and (min-width: 3200px) and (max-width: 3400px) {
  .sc-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 29.5%;
    margin-right: 29.5%;
  }
}

@media screen and (min-width: 3000px) and (max-width: 3200px) {
  .sc-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 26.5%;
    margin-right: 26.5%;
  }
}

@media screen and (min-width: 2880px) and (max-width: 3000px) {
  .sc-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 26.5%;
    margin-right: 26.5%;
  }
}

@media screen and (min-width: 2160px) and (max-width: 2880px) {
  .sc-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 25%;
    margin-right: 25%;
  }
}

@media screen and (min-width: 1920px) and (max-width: 2160px) {
  .sc-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 17.5%;
    margin-right: 17.5%;
  }
}

@media screen and (min-width: 1800px) and (max-width: 1920px) {
  .sc-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 13.5%;
    margin-right: 12.5%;
  }
}

@media screen and (min-width: 1680px) and (max-width: 1800px) {
  .sc-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 11%;
    margin-right: 10%;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1679px) {
  .sc-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 6%;
    margin-right: 6%;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1599px) {
  .sc-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media screen and (min-width: 1350px) and (max-width: 1440px) {
  .sc-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 0;
    margin-right: 5%;
    width: 100%;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1350px) {
  .sc-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .sc-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media screen and (min-width: 1150px) and (max-width: 1200px) {
  .sc-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 5%;
    margin-right: 5%;
  }
}
