@media screen {
  .course-details-heading {
    font-size: 24px;
    font-family: "RakutenRoundRegular";
    margin-bottom: 16px;
  }

  .course-details-child-container-lp {
    padding: 16px;
    padding-left: 48px;
  }

  .course-details-audience {
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 768px) {
  .course-details-child-container-lp {
    padding: 8px;
    padding-left: 0.3rem;
  }
}
