.baseLine {
	height: 0.125rem;
	background-color: #D1D1D1;
	border-radius: 0.125rem;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-top: 0.125rem;
}

.progressLine {
	height: 100%;
}