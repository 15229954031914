@import "../../../../variables.scss";
.wrapText {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-height: 1.58rem;
}

.subTitle {
  margin: 0;
}

.rowPadding {
  padding: 0.5rem 1rem;
}

.elCompleted-crumbs {
  width: 83rem;
  font-family: "RakutenRoundRegular";
}

.elCompleted-table {
  width: 83rem;
}

.elCompleted-header {
  width: 83rem;
}

@media screen and (max-width: 1400px) {
  .elCompleted-crumbs {
    width: 71rem;
  }

  .elCompleted-table {
    width: 71rem;
  }

  .elCompleted-header {
    width: 71rem;
  }
}

.common-breadcrumbs-color {
  color: $primary;
}
