.AntdSessionAddEdit-container {
  margin-top: 7rem;
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AntdAddsession-page-title {
  font-family: "RakutenRoundSemiBold";
  font-size: 2.5rem;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.sessionAddEdit-crumbs {
  font-family: "RakutenRoundRegular";
  width: 83rem;
}

.sessionAddEdit-input {
  font-family: "RakutenRoundRegular";
  width: 25rem;
  height: 3rem;
}

.sessionAddEdit-label {
  font-family: "RakutenRoundRegular";
  padding-bottom: 0.4rem;
  font-size: 1rem;
}

.innerclass__control {
  height: 2.875rem;
  max-width: 26.563rem;
  font-family: "RakutenRoundSemiBold";
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 2.469rem;
}

.sessionAddEdit-rightButtonStyle {
  min-width: 18rem;
  height: 3rem;
  background-color: #abb2b6;
  color: #ffffff;
  font-family: "RakutenRoundRegular";
  font-size: 1rem;
  font-weight: 600;
}

.sessionAddEdit-buttonStyle {
  min-width: 18rem;
  height: 3rem;
  background-color: #00a0f0;
  color: #ffffff;
  font-family: "RakutenRoundRegular";
  font-size: 1rem;
  font-weight: 600;
}

.sessionAddEdit-required {
  color: #FF0000;
}

.sessionAddEdit-updateButton {
  background-color: #00a0f0;
  font-family: "RakutenRoundSemiBold";
  color: #ffffff;
  font-size: 1.2rem;
  height: 2.8rem;
  width: 12rem;
  border-radius: 0.25rem;
  text-transform: uppercase;
}

.sessionAddEdit-deleteButton {
  background-color: #95989a;
  font-family: "RakutenRoundSemiBold";
  color: #ffffff;
  font-size: 1.2rem;
  height: 2.8rem;
  width: 12rem;
  border-radius: 0.25rem;
  text-transform: uppercase;
}

.sessionAddEdit-validation {
  margin-left: 0.375rem;
  font-size: small;
  margin-bottom: 1.063rem;
  color: #FF0000;
  font-family: "RakutenRoundRegular";
}

.noCalendar {
  top: 24.841812rem;
  left: 46.03125rem;
  width: 12rem;
  padding: 0rem;
  padding-right: 1.25rem;
  right: auto;
}

.datetimePicker {
  height: 3rem;
  padding: 0.375rem 0.75rem;
  border: 0.0625rem solid #ced4da;
  border-radius: 0.25rem;
  border: 0.063rem solid #9C9C9C;
  font-family: "RakutenRoundSemiBold";
  color: #495057;
  cursor: pointer;
}

.img {
  position: absolute;
  right: .75rem;
  top: 0.75rem;
}

.custom_toggle {
  max-width: 12.5625rem;
  height: 2.875rem;
  background-color: white;
  border-radius: 0.375rem;
  border: 0.03125rem solid #545b622b;
  font-family: "RakutenRoundSemiBold";
  color: #495057;
  cursor: pointer;
}

.numberContainer {
  margin-top: 0.5625rem;
}

.firstColumn {
  margin-left: -0.4rem;
}

.counter {
  font-family: "RakutenRoundSemiBold" !important;
  color: #495057;
  margin-left: 0.75rem;
}

.text_field {
  padding: 0;
  margin: 0;
  border: none;
  width: 3.75rem;
  margin-top: -0.375rem;
  text-align: center;
}



@media screen and (min-width: 4320px) {
  .AntdSessionAddEdit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 37.5%;
    margin-right: 34%;
  }
}

@media screen and (min-width: 3400px) and (max-width: 4319px) {
  .AntdSessionAddEdit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 33.5%;
    margin-right: 29.5%;
  }
}

@media screen and (min-width: 3200px) and (max-width: 3400px) {
  .AntdSessionAddEdit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 29.5%;
    margin-right: 29.5%;
  }
}

@media screen and (min-width: 3000px) and (max-width: 3200px) {
  .AntdSessionAddEdit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 26.5%;
    margin-right: 26.5%;
  }
}

@media screen and (min-width: 2880px) and (max-width: 3000px) {
  .AntdSessionAddEdit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 26.5%;
    margin-right: 26.5%;
  }
}

@media screen and (min-width: 2160px) and (max-width: 2880px) {
  .AntdSessionAddEdit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 25%;
    margin-right: 25%;
  }
}

@media screen and (min-width: 1920px) and (max-width: 2160px) {
  .AntdSessionAddEdit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 17.5%;
    margin-right: 17.5%;
  }
}

@media screen and (min-width: 1800px) and (max-width: 1920px) {
  .AntdSessionAddEdit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 13.5%;
    margin-right: 12.5%;
  }
}

@media screen and (min-width: 1680px) and (max-width: 1800px) {
  .AntdSessionAddEdit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 11%;
    margin-right: 10%;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1679px) {
  .AntdSessionAddEdit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 6%;
    margin-right: 6%;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1599px) {
  .AntdSessionAddEdit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media screen and (min-width: 1350px) and (max-width: 1440px) {
  .AntdSessionAddEdit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 0;
    margin-right: 5%;
    width: 100%;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1350px) {
  .AntdSessionAddEdit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 5%;
    margin-right: 5%;
  }

  .sessionAddEdit-crumbs {
    font-family: "RakutenRoundRegular";
    width: 72rem;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .AntdSessionAddEdit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 5%;
    margin-right: 5%;
  }

  .sessionAddEdit-crumbs {
    font-family: "RakutenRoundRegular";
    width: 71rem;
  }
}

@media screen and (min-width: 1150px) and (max-width: 1200px) {
  .AntdSessionAddEdit-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 5%;
    margin-right: 5%;
  }

  .sessionAddEdit-crumbs {
    font-family: "RakutenRoundRegular";
    width: 71rem;
  }
}