@import "../../variables.scss";

@media screen {
  .primaryPublicButton {
    background-color: $primary;
    border-radius: 0rem;
    font-family: "RakutenRoundSemiBold";
    font-size: 1rem;
    line-height: 1.25rem;
    color: #ffffff;
    padding: 0.7rem 1.5rem;
    border: none;
    text-transform: uppercase;
    min-width: 11rem;
    height: 48px;
    margin-top: 15px;
  }
  .primaryPrivateButton {
    background-color: $secondary;
    border-radius: 0rem;
    font-family: "RakutenRoundSemiBold";
    font-size: 1rem;
    line-height: 1.25rem;
    color: #ffffff;
    padding: 0.7rem 1.5rem;
    border: none;
    text-transform: uppercase;
    width: 200px;
    height: 48px;
    margin-top: 15px;
  }

  .iconStyle {
    padding: 0.25em;
    margin-left: -10.5rem;
    margin-top: 0.3rem;
  }
}

@media screen and (max-width: 476px) {
  .primaryPublicButton {
    background-color: $primary;
    border-radius: 0rem;
    font-family: "RakutenRoundSemiBold";
    font-size: 1rem;
    line-height: 1.25rem;
    color: #ffffff;
    padding: 0.7rem 1.5rem;
    border: none;
    border-radius: 4px;
    text-transform: uppercase;
    width: 272px;
    height: 40px;
    margin-top: 15px;
    margin-left: 16px;
  }
  .primaryPrivateButton {
    background-color: $secondary;
    border-radius: 0rem;
    font-family: "RakutenRoundSemiBold";
    font-size: 1rem;
    line-height: 1.25rem;
    color: #ffffff;
    padding: 0.7rem 1.5rem;
    border: none;
    border-radius: 4px;
    text-transform: uppercase;
    width: 272px;
    height: 40px;
    margin-top: 15px;
    margin-left: 16px;
  }

  .iconStyle {
    padding: 0.25em;
  }
}
