.modal {
	margin-left: -18.75rem;
	min-width: 66.313rem;
	min-height: 37.125rem;
	align-content: center;
	border-radius: 0.5rem;
}
.title {
	font-family: "RakutenRoundRegular";
	font-size: 1.5rem;
	margin-left: 1.25rem;
	line-height: 3.063rem;
	margin-bottom: -0.625rem;
	flex: 0 0 auto;
	width: auto;
	max-width: 100%;
}

.header {
	border-bottom: none;
}

.functionalBox {
	width: 61.938rem;
	min-height: 22.813rem;
	position: relative;
	margin-left: 1.25rem;
	display: inline-block;
	background-color: #f7f7f7;
	white-space: initial;
	border-radius: 0.5rem;
	text-align: center;
}

.reactCrop {
	display: inline-block;
	margin: 0 auto;
	padding: 10px;
	position: relative;
	background-color: #f7f7f7;
	white-space: initial;
	border-radius: 0.5rem;
}

.submit {
	margin-top: 2.125rem;
	margin-left: 28.875rem;
	margin-bottom: 2.125rem;
}
