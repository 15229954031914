@import "../../variables.scss";

.breadcrumb {
  font-family: "RakutenRoundRegular";
  font-size: 14px;
  line-height: 18px;
  background: transparent;
  padding-left: 0px;
}
.active {
  color: #000000;
}
.breadcrumbTitle {
  text-transform: capitalize;
  color: $primary;
}
