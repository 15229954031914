@import "../../variables.scss";

.primary-color {
  color: $primary;
}

@media screen {
  .pageFooter {
    background-color: black;
    margin-top: 10rem;
    // padding: 4.438rem;
    position: absolute;
    bottom: 0;
    z-index: 998;
    height: 33rem;
    width: 100%;
    overflow: hidden;
    padding-top: 5rem;
  }

  .rakutenAllRight {
    color: grey;
    font-family: "RakutenRoundRegular";
    font-size: 0.813rem;
    letter-spacing: 0.014rem;
    line-height: 1rem;
    text-align: center;
  }

  .eLearningHeading {
    color: $primary;
    font-family: "RakutenRoundSemiBold";
    font-size: 1rem;
    letter-spacing: 0.077rem;
    line-height: 0.813rem;
    text-align: left;
    margin-bottom: 26px;
  }

  .eLearningContents {
    color: #ffffff;
    font-family: "RakutenRoundRegular";
    font-size: 0.875rem;
    letter-spacing: 0.077rem;
    line-height: 0.813rem;
    text-align: left;
    cursor: pointer;
  }

  .instructorLedHeading {
    color: $primary;
    font-family: "RakutenRoundSemiBold";
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 0.077rem;
    line-height: 0.813rem;
    margin-bottom: 26px;
  }

  .instructorLedContents {
    color: #ffffff;
    font-family: "RakutenRoundRegular";
    font-size: 0.875rem;
    letter-spacing: 0.068rem;
    line-height: 1.125rem;
    margin-right: 5rem;
    width: 23rem;
    overflow-wrap: break-word;
  }
  .footerPadding {
    margin-right: 0rem;
    margin-left: auto;
    margin-bottom: 4rem;
    margin-top: 3rem;
  }
  .elearningPadding {
    margin-right: auto;
    margin-left: 0.5rem;
    margin-bottom: 4rem;
  }
}
@media screen and (max-width: 476px) {
  .pageFooter {
    background-color: black;
    margin-top: 10rem;
    padding: 4.438rem;
    position: absolute;
    bottom: 0;
    z-index: 998;
    height: 300px;
    width: none;
    overflow: hidden;
    padding-top: 5rem;
  }
  .footerPadding {
    margin-right: 0rem;
    margin-left: 3rem;
    margin-bottom: 0rem;
    margin-top: 3rem;
  }
  .rakutenAllRight {
    color: grey;
    font-family: "RakutenRoundRegular";
    font-size: 0.813rem;
    letter-spacing: 0.014rem;
    line-height: 1rem;
    padding-left: 5px;
    margin-left: 5rem;
  }
  .eLearningHeading {
    color: $primary;
    font-family: "RakutenRoundSemiBold";
    font-size: 1rem;
    letter-spacing: 0.077rem;
    line-height: 0.813rem;
    text-align: left;
    margin-bottom: 26px;
    padding-top: 1rem;
  }
  .elearningPadding {
    margin-right: 0rem;
    margin-left: 3rem;
    margin-bottom: 4rem;
  }
}
