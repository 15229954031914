@import "../../../variables.scss";

@media screen {
  .recommendto-button {
    background-color: $primary;
    font-family: "RakutenRoundSemiBold";
    color: #ffffff;
    border-radius: 0.25rem;
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
    text-transform: uppercase;
    height: 2.85rem;
    min-width: 11rem;
  }

  .getmyteam-modal {
    .ant-modal-body {
      margin-top: 2rem;
      width: 60rem;
    }

    .ant-modal-content {
      width: 64rem;
      margin-left: -7rem;
    }
  }

  .getmyteam-modal-search {
    font-family: "RakutenRoundRegular";
  }
}

@media screen and (max-width: 768px) {
  .getmyteam-modal {
    .ant-modal-content {
      width: 22rem;
      margin-left: 0rem;
      overflow: scroll;
    }
  }
}
