@import "../../../variables.scss";

@media screen {
  .courseList-addNewCourse {
    color: #ffffff;
    font-family: "RakutenRoundSemiBold";
    width: 10rem;
    height: 2.3rem;
    border: none;
    background-color: $primary;
  }

  .table-row-dark {
    background-color: rgba(255, 118, 117, 0.1);
  }

  .legendStyle {
    position: relative;
    top: 3rem;
  }

  .searchBar {
    top: 3rem;
    /* left: 12rem; */
    position: relative;
  }

  .pageFilter {
    position: relative;
    top: 3.5rem;
    /* left: 15rem; */
  }

  .tableSearch {
    position: absolute;
    top: 6.6rem;
    left: 70rem;
    width: 12.5rem;
  }

  .downloadButtonStyle {
    position: absolute;
    top: 6.6rem;
    left: 65rem;
  }

  .courseList-numberButton {
    border: none;
    background-color: none;
    border-radius: 4px;
    font-size: 1.3rem;
    font-weight: 900;
    padding: 2px;
  }

  .wrapText {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-height: 1.58rem;
  }

  .plusIconStyle {
    transform: scale(0.65);
  }

  .course-tabs {
    width: 15rem;
    position: relative;
    left: 4rem;

    .ant-tabs-tab-btn {
      font-family: "RakutenRoundRegular";
      font-size: 1rem;
      font-weight: 600;
      display: flex;
      align-items: center;
    }

    .ant-tabs-nav .ant-tabs-nav-wrap {
      overflow: visible;
    }

    .ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $primary !important;
    }

    .ant-tabs-tab .ant-tabs-tab-btn:hover {
      color: $primary !important;
    }

    .ant-tabs-nav-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .ant-tabs-tab {
      width: 45%;
      justify-content: center;
    }

    .ant-tabs-ink-bar {
      width: 5rem;
      border-bottom: 3px solid $primary;
      border-radius: 5px;
    }
  }

  .courseList-table {
    width: 83rem;
    margin-right: auto;
    margin-left: auto;
  }

  .courseList-header {
    width: 82rem;
    align-items: center;
  }

  .courseList-crumbs {
    width: 82rem;
    font-family: "RakutenRoundRegular";
  }

  .courseList-legendContainer {
    background-color: #ebebeb;
    padding: 0.5rem 0;
    font-size: 0.875rem;
    border-radius: 0.25rem;
  }

  .courseList-publicIcon {
    height: 0.75rem;
    width: 0.75rem;
    border-radius: 50%;
    background-color: $primary;
    position: relative;
    top: 0.3rem;
    left: 0.5rem;
  }

  .baseLine {
    width: 3em;
    height: 0.25em;
    background-color: #ebebeb;
    border-radius: 2px;
  }

  .progressLine {
    height: 100%;
  }

  .countValue {
    font-family: "RakutenRoundSemiBold";
    font-size: 0.8125rem;
  }

  .currentCount {
    font-family: "RakutenRoundRegular";
  }

  .minCountValue {
    font-family: "RakutenRoundRegular";
    font-size: 0.8125rem;
  }

  .progressCircle {
    position: relative;
    display: inline-block;
    margin: 0 0 0 0.25rem;
    top: 0.25rem;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: #ebebeb;
  }

  .progressCircle:after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -webkit-animation: colorload 2s;
    animation: colorload 2s;
  }

  .progressCircle .innerCircle {
    font-size: 0.8125rem;
    color: #8b8b8b;
    position: absolute;
    left: 50%;
    top: 50%;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    /* line-height: 18px; */
    margin-left: -19px;
    margin-top: -19px;
    text-align: center;
    border-radius: 50%;
    background: #fff;
    z-index: 1;
    font-family: "RakutenRoundSemiBold";
    font-size: 0.8125rem;
  }

  .progressCircle:last-child {
    margin-left: 0.5rem;
  }

  .outerContainer {
    cursor: pointer;
  }

  .circleProgressBar {
    width: 3.125rem;
    height: 3.125rem;
    cursor: pointer;
    float: left;
    margin-right: 0.25rem;
  }

  .admin-alert {
    width: 83rem;
    margin-bottom: 1rem;
    font-family: "RakutenRoundRegular";
  }
}

@media screen and (max-width: 1400px) {
  .courseList-header {
    width: 71.5rem;
  }

  .admin-alert {
    width: 71rem;
  }

  .courseList-table {
    width: 71rem;
  }

  .courseList-crumbs {
    width: 71rem;
  }
}
