@media screen {
  .myteam-container {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .myteam-alert {
    font-family: "RakutenRoundRegular";
    margin-top: 1.3rem;
  }

  .myteam-pageTitle {
    font-family: "RakutenRoundSemiBold";
    font-size: 2.5rem;
  }

  .myteam-crumbs {
    margin-top: 2rem;
    width: 82rem;
    margin-bottom: 1rem;
    color: #00a0f0;
    font-family: "RakutenRoundRegular";
  }

  .myteamSearch {
    font-family: "RakutenRoundRegular";
    margin-top: 1rem;
    width: 10rem;
    font-size: 1rem;
    border-radius: 4px;
  }

  .myteam-header {
    width: 83rem;
    margin-left: auto;
    margin-right: auto;
  }

  .myteam-table {
    width: 83rem;
    margin-right: auto;
    margin-left: auto;
    margin-top: 1rem;
  }
  .table-row-private {
    background-color: #fef4e5;
  }

  .table-row-elPublic {
    background-color: #f7f7f7;
  }

  .table-row-ilPublic {
    background-color: #e5f5fd;
  }
}

@media screen and (max-width: 768px) {
  .myteam-table {
    width: 23rem;
    overflow: scroll;
  }

  .myteam-pageTitle {
    font-size: 1.7rem;
  }

  .myteam-crumbs {
    margin-top: 0rem;
    width: 23rem;
  }

  .myteam-container {
    margin-top: 0rem;
  }

  .myteamSearch {
    margin-top: 1rem;
    width: 7rem;
    font-size: 1rem;
  }

  .myteam-header {
    width: 23rem;
  }
}
