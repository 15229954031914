@import "../../../variables.scss";
@media screen {
  .coursedetail-container {
    margin-top: 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .coursedetail-breadcrumb {
    width: 83rem;
    font-family: "RakutenRoundRegular";
    color: "$primary";
  }

  // .coursedetails-tabs {
  //   width: 10rem;
  // }

  .coursedetails-pageTitle {
    font-family: "RakutenRoundSemiBold";
    font-size: 2.5rem;

    .ant-typography {
      word-break: none;
    }
  }

  .tile-grid-style {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-left: -3rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .coursedetail-table {
    width: 90%;
  }

  .antd-course-detail-crumb {
    color: $primary !important;
  }

  .coursedetails-tabs {
    width: 15rem;

    .ant-tabs-tab-btn {
      font-family: "RakutenRoundRegular";
      font-size: 1rem;
      font-weight: 600;
      display: flex;
      align-items: center;
    }

    .ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $primary !important;
    }

    .ant-tabs-tab .ant-tabs-tab-btn:hover {
      color: $primary !important;
    }

    .ant-tabs-nav-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .ant-tabs-tab {
      width: 50%;
      justify-content: center;
    }

    .ant-tabs-ink-bar {
      border-bottom: 3px solid $primary;
      border-radius: 5px;
      width: 7rem !important;
    }
  }
}

@media screen and (max-width: 476px) {
  .coursedetail-container {
    margin-top: 2rem;
  }

  .tile-grid-style {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-left: 0.7rem;
  }

  .coursedetail-table {
    width: 23rem;
    overflow: scroll;
  }

  .coursedetail-breadcrumb {
    width: 23rem;
    font-family: "RakutenRoundRegular";
    color: "$primary";
  }

  .coursedetails-pageTitle {
    font-family: "RakutenRoundSemiBold";
    font-size: 1.4rem;
  }
}
