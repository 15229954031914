.baseLine {
	width: 3em;
	height: 0.25em;
	background-color: #ebebeb;
	border-radius: 2px;
}

.progressLine {
	height: 100%;
}

.countValue {
	font-family: "RakutenRoundSemiBold";
	font-size: 0.8125rem;
}

.currentCount {
	font-family: "RakutenRoundRegular";
}

.minCountValue {
	font-family: "RakutenRoundRegular";
	font-size: 0.8125rem;
}

.progressCircle {
	position: relative;
	display: inline-block;
	margin: 0 0 0 0.25rem;
	top: 0.25rem;
	width: 3rem;
	height: 3rem;
	border-radius: 50%;
	background-color: #ebebeb;
}

.progressCircle:after {
	content: "";
	display: inline-block;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	-webkit-animation: colorload 2s;
	animation: colorload 2s;
}

.progressCircle .innerCircle {
	font-size: 0.8125rem;
	color: #8b8b8b;
	position: absolute;
	left: 50%;
	top: 50%;
	display: block;
	width: 2.375rem;
	height: 2.375rem;
	/* line-height: 18px; */
	margin-left: -19px;
	margin-top: -19px;
	text-align: center;
	border-radius: 50%;
	background: #fff;
	z-index: 1;
	font-family: "RakutenRoundSemiBold";
	font-size: 0.8125rem;
}

.progressCircle:last-child {
	margin-left: 0.5rem;
}
.outerContainer {
	cursor: pointer;
}
.circleProgressBar {
	width: 3.125rem;
	height: 3.125rem;
	cursor: pointer;
	float: left;
	margin-right: 0.25rem;
}
