@import "../../../../variables.scss";
.primary-button-learning-course-list {
  background-color: $primary;
  //   border-radius: 0.25rem;
  font-family: "RakutenRoundSemiBold";
  font-size: 1rem;
  //   line-height: 1.25rem;
  color: #ffffff;
  padding: 0.7rem 1.5rem;
  border: none;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  //   padding: 0.7rem;
  height: 2.7rem;
  //   width: 6.7rem;
  &:hover {
    background-color: $primary !important;
  }
}

.el-filter-style {
  color: $primary;
  border-bottom: 2px solid $primary;
}
