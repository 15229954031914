.addNewCourse {
	position: relative;
	left: 1rem;
	top: 3rem;
}
.legendStyle {
	position: relative;
	top: 3rem;
}
.searchBar {
	top: 3rem;
	/* left: 12rem; */
	position: relative;
}

.pageFilter {
	position: absolute;
	top: 3.5rem;
	left: -15rem;
	z-index: 100;
}

.pageFilterPrivate {
	position: absolute;
	top: 3.5rem;
	left: -28rem;
}

.tableSearch {
	position: absolute;
	top: 7.6rem;
	left: 70rem;
	width: 12.5rem;
}
.downloadButtonStyle {
	position: absolute;
	top: 7.6rem;
	left: 66rem;
}
.numberButton {
	border: 2px solid black;
	border-radius: 4px;
	padding: 2px;
}
.wrapText {
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	line-height: 1.58rem;
}
.subTitle {
	margin: 0;
}
.rowPadding {
	padding: 0.5rem 1rem;
}
.deleteUserButton {
	left: 18rem;
	position: relative;
	top: 3rem;
	width: 8rem;
	margin-left: 12.5rem;
}
.deleteUserButtonPrivate {
	left: 18rem;
	position: relative;
	top: 3rem;
	width: 8rem;
}

.acceptedButton {
	margin-left: 4rem;
	width: min-content;
	height: fit-content;
}
.declinedButton {
	max-width: max-content;
	max-height: max-content;
}
.pendingTableHeader {
	height: 3.5rem;
}

.modalContentStyle {
	max-height: 28rem;
	overflow-y: auto;
}
/* 
.outlineSessionPriButton{ 
	margin-left: -8rem;
	margin-top: 3rem;
} */