@import "../../../variables.scss";

@media screen {
  .waitList-addNewCourse {
    width: 9.5rem;
    height: 2.3rem;
    background-color: $primary;
    color: #ffffff;
    font-family: "RakutenRoundSemiBold";
    font-size: 1rem;
    font-weight: 600;
    border-radius: 5px;
  }

  .legendStyle {
    position: relative;
    top: 3rem;
  }

  .searchBar {
    top: 3rem;
    /* left: 12rem; */
    position: relative;
  }

  .waitlist-pageFilter {
    position: relative;
  }

  .tableSearch {
    position: absolute;
    top: 7.6rem;
    left: 70rem;
    width: 12.5rem;
  }

  .downloadButtonStyle {
    position: absolute;
    top: 7.6rem;
    left: 66rem;
  }

  .numberButton {
    border: 0.125rem solid black;
    border-radius: 0.25rem;
    padding: 0.125rem;
  }

  .wrapText {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-height: 1.58rem;
  }

  .subTitle {
    margin: 0;
  }

  .rowPadding {
    padding: 0.5rem 1rem;
  }

  .deleteUserButton {
    left: 8.75rem;
    position: relative;
    top: 3rem;
  }

  .buttonMargin {
    margin-top: -0.25rem;
  }

  .toggleExpandButton {
    transform: rotate(-180deg);
  }

  .expandRowContainer {
    max-width: 20rem;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .expandRowTable {
    border: 0.0625rem solid #333333;
    margin-top: 0.8125rem;
    margin-right: 3.125rem;
    margin-bottom: 1.5rem;
  }

  .cardCountContainer {
    height: 9rem;
    width: 13.625rem;
    border-radius: 0.375rem;
    background-color: #f7f7f7;
    margin-top: 0.625rem;
    margin-bottom: 1.5rem;
    padding-top: 1.125rem;
  }

  .cardCountInnerContainer {
    height: 2.125rem;
    width: 9.375rem;
    text-align: center;
    color: #979797;
    font-family: RakutenRoundRegular;
    font-size: 0.875rem;
    font-weight: 600;
    margin: 0 auto;
    margin-top: 0.5625rem;
  }

  .statusCount {
    font-size: 2.25rem;
    font-weight: 600;
  }

  .status {
    border: 0.0625rem solid;
    background: none;
    font-family: RakutenRoundSemiBold;
  }

  .completed {
    color: #37b900;
  }

  .notCompleted {
    color: #bf0000;
  }

  .pending {
    color: #828282;
  }

  .courseName {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }

  .courseIdName {
    border: 0.0625rem solid !important;
    background: none !important;
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
    padding-left: 2rem !important;
  }

  .expandTableTh {
    border: 0.0625rem solid #333333 !important;
    padding-left: 2rem !important;
    background: none !important;
    width: 12rem !important;
  }

  .courseIdNameTh {
    width: 20.75rem !important;
  }

  .message {
    display: flex;
    align-items: center;
    font-weight: bold;
    justify-content: center;
    color: #37b900;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
  }

  .modalContentStyle {
    max-height: 28rem;
    overflow-y: auto;
    font-family: "RakutenRoundRegular";
  }

  .tooltipContent {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }

  .Waitlist-crumbs {
    font-family: "RakutenRoundRegular";
    width: 86rem;
    margin-top: 1rem;
  }

  .waitingList-pageTitle {
    font-family: "RakutenRoundSemiBold";
    font-size: 2.5rem;
  }

  .waitingList-Table {
    width: 83rem;
    margin-right: 5rem;
  }

  .waitingList-tableHeader {
    width: 86rem;
    display: flex;
    align-items: center;
    padding-bottom: 2rem;
    padding-top: 1.4rem;
  }

  .tableSearch {
    position: relative;
    top: -7.7rem;
    left: 57.5rem;
    width: 13.5rem;
    z-index: 1000;
  }

  .waitlist-tabs {
    width: 15rem;

    .ant-tabs-tab-btn {
      font-family: "RakutenRoundRegular";
      font-size: 1rem;
      font-weight: 600;
      display: flex;
      align-items: center;
    }

    .ant-tabs-nav .ant-tabs-nav-wrap {
      overflow: visible;
    }

    .ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $primary !important;
    }

    .ant-tabs-tab .ant-tabs-tab-btn:hover {
      color: $primary !important;
    }

    .ant-tabs-nav-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .ant-tabs-tab {
      width: 45%;
      justify-content: center;
    }

    .ant-tabs-ink-bar {
      width: 5rem;
      border-bottom: 3px solid $primary;
      border-radius: 5px;
    }
  }

  .waitlist-enrollButton {
    background-color: #047205;
    color: #ffffff;
    font-family: "RakutenRoundRegular";
  }

  .waitlist-subTitle {
    width: 87rem;
  }

  .waitList-deleteButton {
    width: 8rem;
    height: 2.5rem;
    background-color: $primary;
    color: #ffffff;
    font-family: "RakutenRoundRegular";
    font-size: 1rem;
    font-weight: 600;
    border-radius: 5px;
    margin-right: -2rem;
  }

  .waitlist-buttonStyle {
    min-width: 18rem;
    height: 3rem;
    background-color: $primary;
    color: #ffffff;
    font-family: "RakutenRoundRegular";
    font-size: 1rem;
    font-weight: 600;
  }

  .waitlist-rightButtonStyle {
    min-width: 18rem;
    height: 3rem;
    background-color: #abb2b6;
    color: #ffffff;
    font-family: "RakutenRoundRegular";
    font-size: 1rem;
    font-weight: 600;
  }

  .waitlist-modalContent {
    font-family: "RakutenRoundRegular";
  }
}

@media screen and (max-width: 1400px) {
  .Waitlist-crumbs {
    width: 75rem;
  }

  .waitingList-Table {
    width: 71rem;
    margin-left: 1.2rem;
  }

  .waitingList-tableHeader {
    width: 75.5rem;
  }

  .waitlist-subTitle {
    width: 75rem;
  }

  .tableSearch {
    left: 45.5rem;
  }
}
