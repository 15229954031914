.datetimePicker {
  height: 3rem;
  padding: 0.375rem 0.75rem;
  border: 0.0625rem solid #ced4da;
  border-radius: 0.25rem;
  border: 0.063rem solid #9C9C9C;
  font-family: "RakutenRoundSemiBold";
  color: #495057;
  cursor: pointer;
}

.img {
  position: absolute;
  right: .75rem;
  top: 0.75rem;
}
