.learnShare {
  font-size: small;
  font-family: "RakutenRoundRegular";
  margin-bottom: 0.2rem;
}
.discourseTech {
  font-size: xx-large;
}
.exploreNow {
  color: #00a0f0;
  font-size: small;
  text-decoration: underline;
  cursor: pointer;
}
.heading {
  font-family: "RakutenRoundSemiBold";
  margin-top: -12rem;
  margin-left: 8rem;
}
.topContainer {
  margin-bottom: 10rem;
}
