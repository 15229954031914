.ant-card-body {
  padding: 0 !important;
  height: 0px;
}

@media screen {
  .tile {
    width: 19.5rem;
    border-width: 0.063rem;
    border-radius: 0.563rem;
    background-color: #ffffff;
    box-shadow: 0 0.125rem 0.25rem 0 #d1d1d1;
    text-align: center;
    height: 12.5rem;
    margin-bottom: 1.5rem;
    margin-left: 0;
  }

  .skillTile {
    width: 312px;
    height: 361px;
  }

  .skillTileBody {
    width: 19.5rem;
    height: 22.5625rem;
  }

  .title {
    color: #00a0f0;
    font-family: "RakutenRoundSemiBold";
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.563rem;
    padding-top: 2.875rem;
  }

  a:hover {
    text-decoration: none;
  }

  .centered {
    position: absolute;
    color: #ffffff;
    font-size: 1.25rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.5625rem;
    font-family: "RakutenRoundSemiBold";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .cardBody {
    margin: 0;
    padding: 0;
    border-radius: 0.5rem;
    position: relative;
    text-align: center;
    color: #ffffff;
  }

  .cardBody :hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

@media screen and (max-width: 1300px) {
  .skillTile {
    width: 270px;
    height: 275px;
  }

  .skillTileBody {
    width: 17.5rem;
    height: 22.5625rem;
  }
}