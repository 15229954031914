.el-secondContainer {
  width: 82.5 !important;
  margin: 0rem;
  padding: 0rem;
  padding-bottom: 7.5rem;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
}

.container {
  background-color: white;
  max-width: 82.5;
  margin-right: 5rem;
  padding: 4rem;
  position: relative;
  margin-top: 7rem;
}

.el-list-InfiniteScroll {
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.countText {
  height: 2.125rem;
  width: 54.4375rem;
  color: #000000;
  font-family: "RakutenRoundRegular";
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.0625rem;
}
.el-filter-container {
  margin-left: auto;
  margin-right: auto;
  width: 81rem;
}
@media screen {
  .elearning-common-container {
    padding: 3rem;
    margin-top: 3rem;
  }
}

@media screen and (max-width: 1440px) {
  .elearning-common-container {
    padding: 3rem;
    margin-top: 1rem;
  }
  .el-filter-container {
    margin-left: 0rem;
    margin-right: 0rem;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1400px) {
  .el-filter-container {
    margin-left: 10rem;
    margin-right: auto;
    width: 60rem;
  }
  .el-list-InfiniteScroll {
    margin-right: 2rem;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1200px) and (max-width: 1280px) {
  .el-filter-container {
    margin-left: 5rem;
    margin-right: auto;
    margin-bottom: 0rem;
    width: 61rem;
  }
  .el-list-InfiniteScroll {
    margin-left: -12rem;
    margin-top: -2rem;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 786px) {
  .elearning-common-container {
    padding: 0.5rem;
    margin-top: 2rem;
  }
}
