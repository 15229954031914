.mobile-elearning-card {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.elearning-home {
  margin: 1rem;
}

.courseslick.slick-slide img {
  display: block;
}

.courseslick.slick-slider {
  width: 100%;
}

.courseslick.slick-slide {
  width: 100%;
  height: 40.875rem;
}
.customSelectELHome {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  flex-wrap: wrap;
  margin: 0rem;
  padding: 0rem;
  width: 19.5rem;
}

.customSelectELHome__control {
  border-radius: 0px !important;
  width: 19.5rem !important;
  height: 0.0625rem !important;
  border: 0.0625rem solid #000000 !important;
  flex-wrap: wrap;
}

.customSelectELHome__value-container {
  color: black !important;
  font-family: "RakutenRoundRegular";
  font-size: 13px;
}

@media screen {
  .el-home-heading {
    color: #000000;
    font-family: "RakutenRoundRegular";
    font-size: 2.25rem;
    letter-spacing: 0;
    line-height: 2.125rem;
    padding-left: 0rem;
    margin-left: 1rem;
  }

  .el-home-row {
    margin-left: -0.938rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.688rem;
    padding-top: 1rem;
  }
  .el-home-col {
    margin-right: 0rem;
    display: inline-flex-box;
    margin-top: 0.2rem;
    font-family: "RakutenRoundRegular";
    font-size: 14px;
    line-height: 24px;
  }
}

@media screen and (max-width: 425px) {
  .el-home-heading {
    color: #000000;
    font-family: "RakutenRoundSemiBold";
    font-size: 1.375rem;
    letter-spacing: 0;
    line-height: 2.125rem;
    padding-left: 0rem;
    margin-left: 0.5rem;
  }

  .el-home-row {
    margin-left: 0;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.688rem;
    padding-top: 1rem;
  }
}

.footer {
  margin-top: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 0.625rem;
}



.card {
  margin-bottom: 0.625rem;
}

.slider {
  width: 100%;
  height: 100%;
  margin-left: 1rem;
}

.elearning-card {
  width: 19.5rem;
}

.elearning-course-list {
  display: flex;
  // width: 19.5rem;
  overflow: scroll;
}

.el-home-parent-container {
  background: white;
}
