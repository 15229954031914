@import "../../variables.scss";

.courseFilter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "RakutenRoundRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}
.filterItem {
  padding-left: 16px;
}

.blueTick {
  padding-right: 8px;
}
.blue {
  color: $primary;
}
