@import "../../../variables.scss";
.AntdILReports-container {
  margin-top: 7rem;
}

.ILReports-page-title {
  font-family: "RakutenRoundSemiBold";
  font-size: 2.5rem;
  margin-top: 1rem;
  margin-bottom: 3rem;
}
.AntdReport-Schedule-Container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-right: 1rem;
  margin-left: 1rem;
}
.selectRegionReport .ant-select-selector {
  height: 3rem !important;
}
.selectRegionReport .ant-select-selection-item {
  width: 10rem;
  font-family: "RakutenRoundSemiBold";
  margin: auto;
}

.datePickerReport .ant-picker-input {
  font-family: "RakutenRoundSemiBold";
}
.errorMessageDatePicker {
  margin-left: 0.4rem;
  margin-top: -2.188rem;
  font-size: small;
  margin-bottom: 1.07rem;
  color: red;
}
.reportResetButton {
  background-color: $primary;
  border-radius: 0.25rem;
  font-family: "RakutenRoundSemiBold";
  font-size: 1rem;
  line-height: 1.25rem;
  color: #ffffff;
  padding: 0.7rem 1.5rem;
  text-transform: uppercase;
  min-width: 11rem;
  height: 48px;
  margin-top: 15px;
}
.reportApplyButton {
  background-color: $primary;
  border-radius: 0.25rem;
  font-family: "RakutenRoundSemiBold";
  font-size: 1rem;
  line-height: 1.25rem;
  color: #ffffff;
  padding: 0.7rem 1.5rem;
  text-transform: uppercase;
  min-width: 11rem;
  height: 48px;
  margin-top: 15px;
}
.downloadCSVButton {
  margin-right: 2rem;
  background-color: white;
  color: black;
  font-size: 1.5rem;
}
.reportsTile {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.scheduleTime {
  font-size: 0.875rem;
}
.departmentBarChartContainer {
  height: 31.25rem;
  max-width: 100%;
}
.box {
  height: 100%;
  width: 100%;
}
.pieChart {
  height: 25em;
  padding: 0 1rem;
}
.donutWrapper {
  position: absolute;
  top: 60%;
  left: 30%;
  width: 7rem;
  text-align: center;
}
.attendanceText {
  font-size: 24px;
  color: #303030;
  font-family: "RakutenRoundRegular";
  margin-top: 1rem;
}
.progressBarStyle {
  height: 0.25rem;
  width: 70%;
  margin: 1rem;
}
.progressBarContent {
  font-size: 16px;
  margin-top: 0.35rem;
  font-family: "RakutenRoundRegular";
}
.marginTop3Reports {
  margin-top: 8rem;
  margin-left: 1rem;
}
.tileContainer {
  height: 8.5rem;
  background-color: #ffffff;
  margin-bottom: 2rem;
  padding: 1rem;
  text-transform: uppercase;
  color: #303030;
  font-family: "RakutenRoundSemiBold";
}
.tileLabel {
  font-size: 0.875rem;
  line-height: 1rem;
}
.tileValue {
  font-size: 1.75rem;
}

@media screen and (min-width: 4320px) {
  .AntdILReports-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 37.5%;
    margin-right: 34%;
  }
}

@media screen and (min-width: 3400px) and (max-width: 4319px) {
  .AntdILReports-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 33.5%;
    margin-right: 29.5%;
  }
}

@media screen and (min-width: 3200px) and (max-width: 3400px) {
  .AntdILReports-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 29.5%;
    margin-right: 29.5%;
  }
}

@media screen and (min-width: 3000px) and (max-width: 3200px) {
  .AntdILReports-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 26.5%;
    margin-right: 26.5%;
  }
}

@media screen and (min-width: 2880px) and (max-width: 3000px) {
  .AntdILReports-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 26.5%;
    margin-right: 26.5%;
  }
}

@media screen and (min-width: 2160px) and (max-width: 2880px) {
  .AntdILReports-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 25%;
    margin-right: 25%;
  }
}

@media screen and (min-width: 1920px) and (max-width: 2160px) {
  .AntdILReports-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 17.5%;
    margin-right: 17.5%;
  }
}

@media screen and (min-width: 1800px) and (max-width: 1920px) {
  .AntdILReports-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 13.5%;
    margin-right: 12.5%;
  }
}

@media screen and (min-width: 1680px) and (max-width: 1800px) {
  .AntdILReports-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 11%;
    margin-right: 10%;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1679px) {
  .AntdILReports-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 6%;
    margin-right: 6%;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1599px) {
  .AntdILReports-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media screen and (min-width: 1350px) and (max-width: 1440px) {
  .AntdILReports-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 0;
    margin-right: 5%;
    width: 100%;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1350px) {
  .AntdILReports-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .AntdILReports-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media screen and (min-width: 1150px) and (max-width: 1200px) {
  .AntdILReports-container {
    padding: 3rem;
    margin-top: 5rem;
    margin-left: 5%;
    margin-right: 5%;
  }
}
