@import "../../../../variables.scss";

.slickDots {
  position: absolute;
  bottom: -1.5625rem;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slickDots li {
  position: relative;
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  padding: 0;
  cursor: pointer;
}

.slickDots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  padding: 0.3125rem;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slickDots li button::before {
  font-family: "slick";
  font-size: 0.5rem;
  line-height: 1.25rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 1.25rem;
  height: 1.25rem;
  content: "•";
  text-align: center;
  color: #d1d1d1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slickDots li.slick-active button::before {
  color: $primary;
}
