.enrolledUsersModal .react-bootstrap-table-pagination {
  position: relative;
  text-align: center;
  /* width: 18rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 13rem;
}

.enrolledUsersModal .react-bootstrap-table {
  margin: 1rem;
}

.enrolledUsersModal {
  z-index: 9999;
}
