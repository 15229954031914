@media screen {
  .landing-page-lod-team-benefits-text {
    width: 40%;
    font-size: 16px;
  }
  .custom-carousel {
    position: relative;
    height: inherit;
  }
  .landing-page-talk-to-us-text-container {
    padding-right: 7rem;
    padding-left: 7rem;
    padding-top: 5.625rem;
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
  }
  .custom-carousel .carousel-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 249px; /* Adjust as needed */
  }
  .custom-carousel .slick-dots {
    width: 100%;
    position: absolute;
    margin-top: 0px;
    text-align: right;
  }
  .custom-carousel .slick-dots-bottom {
    bottom: -32px;
  }
  .custom-carousel .slick-dots li {
    width: 12px; /* Adjust the width of the dot */
    height: 12px; /* Adjust the height of the dot */
    margin: 0 2px; /* Adjust the spacing between dots */
    border-radius: 100%; /* Make the dots rounded */
    background-color: #ccc; /* Set the color of the dots */
    transition: background-color 0.5s ease; /* Add a smooth transition */
  }
  .custom-carousel .slick-dots li .slick-active {
    height: 6px;
    width: 6px;
    border-radius: 100%;
    background-color: #620f9f;
  }

  .ant-carousel .slick-dots li button {
    display: none;
  }
  .ant-carousel .slick-dots li.slick-active {
    width: 12px;
    height: 12px;
    background-color: #620f9f;
  }

  .ant-carousel .slick-dots li button {
    border-radius: 50%;
    background-color: gray;
  }

  .custom-carousel .carousel-item img {
    max-width: 100%;
    max-height: 100%;
  }

  .custom-carousel .carousel-item .carousel-content {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: #fff; /* Set text color */
  }

  .custom-carousel .carousel-item .carousel-content h2 {
    margin: 0;
    font-size: 24px; /* Adjust as needed */
  }

  .custom-carousel .carousel-item .carousel-content p {
    margin: 5px 0 0;
    font-size: 14px; /* Adjust as needed */
  }

  .custom-carousel-dots .slick-dots {
    bottom: 10px; /* Adjust the position of the dots as needed */
  }

  .sub-upper-text {
    margin-bottom: 24px;
    font-size: 42px;
    font-family: "RakutenSansRegular";
    flex-wrap: wrap;
    text-align: center;
    line-height: 3.063rem;
    width: 1099px;
    color: #686868;
  }
  .landing-page-talk-to-us-container-text {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .middle-sub-text {
    background-color: #fcf6ff;
    padding-top: 16px;
    display: block;
    padding-left: 21rem;
    padding-right: 21rem;
    padding-bottom: 8rem;
    font-size: 20px;
    line-height: 25.24px;
    font-family: "RakutenSansSemiBold";
    flex-wrap: wrap;
    text-align: center;
    color: #686868;
  }

  .upper-text {
    font-size: 71px;
    line-height: 79px;
    font-family: "RakutenSansBold";
    flex-wrap: wrap;
    text-align: center;
    width: 100%;
    color: #6c00a5;
  }
  .landing-page-banner-image-container {
    position: relative;
    padding: 0rem 8rem;
    padding-bottom: 6.625rem;
    background: linear-gradient(to bottom, #f7f7f7, #fcf6ff);
  }

  .image-container {
    // position: relative;
    width: 300px; /* Adjust the width as needed */
    height: 300px; /* Adjust the height as needed */
  }

  .image-container img {
    position: absolute;

    // top: 0;
    left: 0;
    width: 100%;
    // height: 100%;
    object-fit: cover;
  }
  .top-image {
    mix-blend-mode: multiply;
  }

  .pg-dots {
    margin: 6rem 0rem;
  }
  .promotional-page-banner-image {
    border-radius: 4rem;
    height: 635px;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    object-fit: cover;
    width: 100%;
  }

  .middle-text {
    background-color: #fcf6ff;
    font-size: 48px;
    padding: 0rem 21rem;
    display: block;
    line-height: 60.58px;
    font-family: "RakutenSansBold";
    flex-wrap: wrap;
    text-align: center;
    color: #6c00a5;
    font-weight: bolder;
  }
  .landing-page-end-section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    padding: 0rem 8rem;
    background: linear-gradient(to bottom, #fcf6ff, #f7f7f7);
  }

  .landing-page-center-image-container {
    position: relative;
    width: 500px; /* Adjust as needed */
    height: 500px; /* Adjust as needed */
    margin: 50px auto;
    display: flex;
    justify-content: center;
    margin-top: 10rem;
    margin-bottom: 15rem;
  }

  .promotional-page-center-span {
    font-size: 14px;
    color: black;
    font-weight: 100;
  }

  .landing-page-center-image-container img {
    width: 100%;
    height: auto;
    display: block;
  }

  .landing-page-center-image-text {
    position: absolute;
    padding: 5px 10px;
    color: #620f9f;
    border-radius: 4px;
    text-transform: uppercase;
    font-family: "RakutenRoundBold";
    font-size: 24px;
  }

  .landing-page-center-image-line {
    position: absolute;
    width: 2px;
    background: #ccc;
  }

  .landing-page-center-image-text-top {
    top: -150px;
    left: 60%;
    transform: translateX(-50%);
  }
  .landing-page-center-image-line-top {
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    height: 70px;
  }

  /* Bottom text and line */
  .landing-page-center-image-text-bottom {
    bottom: -200px;
    left: 50%;
    transform: translateX(-50%);
  }

  .landing-page-center-image-line-bottom {
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    height: 30px;
  }

  /* Left text and line */
  .landing-page-center-image-text-left {
    left: -300px;
    top: 50%;
    transform: translateY(-50%);
  }

  .landing-page-center-image-line-left {
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 2px;
  }

  .landing-page-center-image-text-right {
    right: -400px;
    top: 50%;
    transform: translateY(-50%);
  }

  .landing-page-center-image-line-right {
    right: -60px;
    top: 50%;
    transform: translateY(-50%);
    width: 80px;
    height: 2px;
  }

  /* Top-left text and line */
  .landing-page-center-image-text-top-left {
    top: -100px;
    left: -150px;
  }

  .landing-page-center-image-line-top-left {
    top: -10px;
    left: -40px;
    width: 60px;
    height: 2px;
    transform: rotate(45deg);
  }

  /* Top-right text and line */
  .landing-page-center-image-text-top-right {
    top: -150px;
    right: -220px;
  }

  .landing-page-center-image-line-top-right {
    top: -10px;
    right: -40px;
    width: 60px;
    height: 2px;
    transform: rotate(-45deg);
  }

  /* Bottom-left text and line */
  .landing-page-center-image-text-bottom-left {
    bottom: -100px;
    left: -280px;
  }
  .landing-page-footer-text {
    font-size: 14px;
    margin-top: 16px;
    color: #6c00a5;
  }

  .landing-page-center-image-line-bottom-left {
    bottom: -10px;
    left: -40px;
    width: 60px;
    height: 2px;
    transform: rotate(-45deg);
  }

  /* Bottom-right text and line */
  .landing-page-center-image-text-bottom-right {
    bottom: -140px;
    right: -280px;
  }

  .landing-page-center-image-line-bottom-right {
    bottom: -10px;
    right: -40px;
    width: 60px;
    height: 2px;
    transform: rotate(45deg);
  }

  .landing-page-end-user-benefits-container {
    display: flex;
    padding: 0rem 8rem;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .landing-page-lod-benefits-container {
    margin-top: 8.75rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 8rem;
    padding-right: 8rem;
    padding-bottom: 10rem;
  }

  .landing-page-learning-activity-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10rem;
    padding-left: 8rem;
    padding-right: 8rem;
    padding-bottom: 5.813rem;
  }

  .landing-page-container {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    margin-left: 8rem;
    margin-right: 8rem;
    flex-direction: column;
  }

  .landing-page-card-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin-top: 4rem;
    > div:first-child {
      margin-bottom: 2rem;
    }
  }

  .landing-page-footer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    margin-top: 4.563rem;
    margin-bottom: 8rem;
  }

  .landing-page-footer-container-1 {
    width: 50%;
    margin-top: 2rem;
    display: flex;
    justify-content: flex-start;
    margin-left: 15rem;
    flex-direction: column;
    align-items: flex-start;
  }
  .landing-page-footer-container-2 {
    width: 50%;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
    margin-left: 8rem;
  }
  .landing-page-header-signin-button {
    background: #6c00a5;
    color: white;
    border-color: #6c00a5;
    font-weight: bold;
    width: 8rem;
    height: 3rem;
    font-size: 14px;
  }
  .landing-page-header-growlogo-container-1 {
    width: 33.3%;
  }
  .landing-page-header-growlogo-container-2 {
    width: 33.3%;
    display: flex;
    justify-content: flex-end;
  }
  .landing-page-header {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .landing-page-end-user-benefits-text {
    width: 40%;
    font-size: 16px;
  }
  .landing-page-lod-team-benefits-text {
    width: 100%;
    margin-top: 2.375rem;
  }

  .landing-page-footer-mail {
    margin-top: 1.25rem;
  }
  .landing-page-top-graph-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2.125rem;
    margin-top: 5.813rem;
  }
  .landing-page-end-section-container-firstText {
    font-family: "RakutenSansBold";
    font-size: 48px;
    line-height: 60px;
    color: #6c00a5;
    margin-top: 9.25rem;
    margin-bottom: 8px;
  }
  .landing-page-end-section-container-secondText {
    color: #686868;
    width: 590x;
    text-align: center;
    font-family: "RakutenSansSemiBold";
  }
  .landing-page-card-body {
    width: 400px;
    border-radius: 16px;
    border-top-color: #6c00a5;
    border-top-width: 8px;
    padding-bottom: 2rem;
  }
  .landing-page-talk-to-us-container-image {
    border-radius: 38px;
    height: 262px;
    width: 100%;
  }
  .landing-page-talk-to-us-container {
    display: flex;
    flex-direction: row;
    margin-top: 6rem;
    position: relative;
  }
  .landing-page-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 425px) {
  .landing-page-button-container {
    margin-top: 1.875rem;
    border-radius: 12px;
  }
  .landing-page-rakuten-logo {
    width: 100%;
    margin-top: 3.25rem;
    margin-bottom: 1.75rem;
    display: flex;
    justify-content: center;
  }
  .landing-page-footer {
    margin-top: 0rem;
  }
  .pg-dots {
    margin-top: 3rem;
    margin-bottom: 2.563rem;
  }
  .promotional-page-banner-image {
    height: 400px;
    border-radius: 0px;
  }
  .sub-upper-text {
    font-family: "RakutenSansSemiBold";
    width: 80%;
    margin-top: 0;
    margin-bottom: 39px;
    font-size: 1rem;
    line-height: 1.313rem;
  }
  .upper-text {
    width: 75%;
    font-size: 33px;
    line-height: 39px;
  }
  .middle-text {
    width: 100%;
    font-size: 1.313rem;
    line-height: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 37px;
    padding-bottom: 8px;
    line-height: 26.5px;
    background-color: #fcf6ff;
  }
  .middle-sub-text {
    font-size: 0.875rem;
    line-height: 1.104rem;
    padding: 0rem 1rem;
    background-color: #fcf6ff;
  }
  .landing-page-footer-text {
    display: flex;
    margin-top: 19px;
    justify-content: center;
  }
  .landing-page-end-user-benefits-container {
    flex-direction: column;
    padding: 0rem 1rem;
  }
  .landing-page-lod-benefits-container {
    flex-direction: column;
    margin-top: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 4rem;
    background-color: #fcf6ff;
  }
  .landing-page-end-section-container-secondText {
    color: #686868;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    padding: 0rem 1rem;
  }

  .landing-page-lod-benefits-image {
    order: 2;
  }
  .landing-page-container {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .landing-page-footer-container-1 {
    margin-left: 0rem;
    margin-top: 19px;
    width: 92%;
    text-align: center;
  }

  .landing-page-footer {
    flex-direction: column-reverse;
    margin-top: rem;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 4rem;
  }
  .landing-page-banner-image-container {
    padding: 0rem;
  }

  .landing-page-card-container {
    flex-direction: column;
    justify-content: space-between;
    margin-top: 2rem;
    align-items: center;
  }
  .landing-page-footer-container-2 {
    margin-left: 0rem;
    margin-top: 0rem;
    width: 92%;
  }
  .landing-page-header-signin-button {
    display: flex;
    justify-content: center;
    width: 4.125rem;
    height: 2rem;
  }

  .landing-page-header-growlogo-container-1 {
    width: 0rem;
  }
  .landing-page-header-growlogo-container-2 {
    width: 0rem;
  }
  .landing-page-header {
    display: flex;
    justify-content: space-between;
    background-color: white;
    position: fixed;
    padding: 1rem;
    z-index: 99;
    top: 0;
    border-bottom-style: inset;
  }

  .landing-page-end-user-benefits-text {
    width: 100%;
    margin-top: 2.375rem;
  }
  .landing-page-footer-mail {
    margin-top: 5px;
    display: flex;
    justify-content: center;
  }
  .landing-page-top-graph-icon {
    margin-top: 4.688rem;
    margin-bottom: 1.75rem;
  }
  .landing-page-end-user-image {
    order: 2;
  }
  .landing-page-end-section-container {
    width: 100%;
    padding: 0rem 0rem;
    margin-top: 2.875rem;
    background: none;
  }
  .landing-page-end-section-container-firstText {
    width: 75%;
    font-size: 1.5rem;
    line-height: 30px;
    margin-top: 0rem;
    padding: 0rem 1rem;
    text-align: center;
  }
  .landing-page-footer-icon-container {
    display: flex;
    justify-content: center;
  }
  .landing-page-card-body {
    background-color: #fcf6ff;
    width: 100%;
    padding-top: 0.5rem;
    border-left-color: #6c00a5;
    border-left-width: 12px;
    border-top-width: 0;
    border-top-color: none;
  }
  .landing-page-talk-to-us-container-image {
    border-radius: 0;
    height: 210px;
  }
  .landing-page-learning-activity-image-container {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 2.813rem;
    padding-bottom: 3rem;
    margin-bottom: 0px;
  }
  .landing-page-talk-to-us-container {
    flex-direction: column;
    margin-top: 3rem;
    width: 100%;
  }
  .landing-page-talk-to-us-container-text {
    width: 100%;
    text-align: center;
  }
  .landing-page-talk-to-us-text-container {
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 2rem;
    flex-direction: column;
  }
}
