@import "../../variables.scss";

@media screen {
  a,
  a:hover,
  a:visited {
    color: $primary;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    color: #9c9c9c;
  }
  .breadcrumb {
    font-family: "RakutenRoundRegular";
    font-size: 0.875rem;
    letter-spacing: 0;
    padding: 0;
    background-color: transparent;
    margin-top: 1rem;
    margin-bottom: 0;
    width: 700px;
    line-height: 17px;
  }
  .breadcrumbBackground {
    font-family: "RakutenRoundRegular";
    font-size: 0.875rem;
    letter-spacing: 0;
    padding: 0;
    background-color: transparent;
    margin-top: 2.25rem;
    margin-bottom: 0;
    color: white !important;
  }
  .breadcrumb.active {
    color: #333 !important;
  }
  .breadcrumb-title {
    text-transform: capitalize;
    text-decoration: none;
  }
}

@media screen and (max-width: 476px) {
  a,
  a:hover,
  a:visited {
    color: $primary;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    color: #9c9c9c;
  }
  .breadcrumb {
    font-family: "RakutenRoundRegular";
    font-size: 14px;
    letter-spacing: 0;
    // padding-left: 12px;
    width: 360px;
    background-color: transparent;
    margin-top: 11px;
    margin-bottom: 0;

    line-height: 17px;
  }
  .breadcrumbBackground {
    font-family: "RakutenRoundRegular";
    font-size: 0.875rem;
    letter-spacing: 0;
    padding: 0;
    background-color: transparent;
    margin-top: 2.25rem;
    margin-bottom: 0;
    color: white !important;
  }
  .breadcrumb.active {
    color: #333 !important;
  }
  .breadcrumbTitle {
    text-transform: capitalize;
    text-decoration: underline;
  }
}
