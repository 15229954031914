.child-toggle-btn {
  color: #000000;
  border: none;
  box-shadow: none;
  font-size: 0.875rem;
}

.child-toggle-btn:hover {
  text-decoration: none;
  color: #000000;
}

.child-toggle-btn.active {
  text-decoration: underline;
}

.child-toggle-btn.active:before {
  display: none;
}
