.buttonStyle {
	font-family: "RakutenRoundRegular";
	min-width: 1.5rem;
	min-height: 1.5rem;
	color: #000;
	text-align: center;
	background: transparent;
	border: none;
}

/* .buttonStyle img {
	width: 100%;
	height: 100%;
} */
