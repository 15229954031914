.basicStyle {
  display: inline-block;
  position: relative;
}

.editButton {
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  border: 0.125rem solid white;
  border-radius: 50%;
  background-color: white;
  right: 0.6875rem;
  top: 0.6875rem;
}

.tagItem {
  margin-right: 0.375rem;
  margin-bottom: 0.375rem;
  border-width: 0.0625rem;
  border-style: solid;
  height: 1.6125rem;
  border-radius: 0.25rem;
  padding: 0.3125rem;
  border-color: #9c9c9c;
  font-family: RakutenRoundRegular;
  font-size: 0.875rem;
}

.editButtonImage {
  height: 0.8125rem;
  margin-left: 0.1875rem;
  margin-bottom: 0.125rem;
  cursor: pointer;
}

.mainImage {
  width: 100%;
  border-radius: 0.375rem;
}
