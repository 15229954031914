.learningPath-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.infinite-scroll-row {
  width: 100%;
}
@media screen {
  .learning-path-list {
    padding: 56px 0 56px 0;
  }
  .content {
    width: 1320px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1250px) and (max-width: 1300px) {
  .content {
    width: 1320px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1250px) {
  .content {
    width: 1200px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 768px) {
  .learning-path-list {
    padding: 1rem 0 1rem 0;
    display: flex;
    justify-content: center;
  }
  .content {
    width: 23rem;
    margin: 0 auto;
  }

  .lp-card-listing-page {
    margin-bottom: 1rem;
  }
}

.course-filter {
  padding: 24px 0 0 0;
  margin-bottom: -16px;
  padding-left: 3rem;
}
@media screen and (max-width: 768px) {
  .course-filter {
    padding: 24px 0 0 0;
    margin-bottom: -16px;
    padding-left: 0rem;
  }
}
.learning-skills {
  padding-top: 120px;
}
.quotation {
  padding-bottom: 120px;
}

.bg-white {
  background-color: white;
}
.bg-grey {
  background-color: #f7f7f7;
}
