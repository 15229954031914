.imageControl{
    max-width: 179px;
    height: 118px;
    position: relative;
    margin-right: 1.4rem;
    margin-bottom: 1.4rem;
    display: inline-block;
}

.thumbnail {
    max-width: 179px;
    height: 118px;
    border-radius: 6px;
    margin-right: 1.4rem;
    margin-bottom: 1.4rem;
}

.whiteTick{
    position: absolute;
    left: 0.8125rem;
    top: 0.8125rem;
    z-index: 999;
}

.selectImageButton{
    position: absolute;
    bottom: 1.25rem;
    left: 3.125rem;
    z-index: 999;
    width: 5rem;
    height: 2.1875rem;
    border-radius: 0.25rem;
    font-family: RakutenRoundSemiBold;
    font-size: 1rem;
    background-color: #00A0F0;
}

.grayShade{
    background-color: #888888;
    position: absolute;
    top: 0rem;
    height: 7.375rem;
    width: 11.1875rem;
    border-radius: 0.375rem;
    opacity: 0.9;
}