.toggleFilterNav .nav-pills {
	justify-content: left;
	margin-bottom: 3rem;
}

.toggleFilterNav .nav-link {
	margin-right: 4rem;
	height: 2.5rem;
	color: #000000;
	font-family: "RakutenRoundRegular";
	font-size: 1rem;
	letter-spacing: 0;
	line-height: 1.625rem;
	text-align: center;
	padding: 0;
}

.toggleFilterNav .nav-link.active {
	color: #000000 !important;
	border-bottom: 0.125rem solid #00a0f0;
	border-radius: 0rem;
	background-color: transparent !important;
	font-family: "RakutenRoundSemiBold";
	font-size: 1rem;
	letter-spacing: 0;
	line-height: 1.625rem;
	text-align: center;
	padding-left: 0rem;
	padding-right: 0rem;
}

.toggleFilterNav .nav-link.active:hover {
	color: #000000 !important;
}

.toggleFilterNav .nav-link:hover {
	color: #00a0f0 !important;
}