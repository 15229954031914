.publicContainer {
  min-height: 31.25rem;
  background: url("../../../../assets/images/elearningBanner.png") no-repeat;
  background-position: center;
}
.innerContainer {
  max-width: 82.5rem;
  padding: 0;
  position: relative;
  background-color: transparent;
  word-wrap: break-word;
}
.coursePageLabel {
  font-size: 1rem;
  text-transform: uppercase;
  padding-left: 0.25rem;
}
.courseName {
  font-family: "RakutenRoundSemiBold";
  font-size: 3rem;
  line-height: 3.7rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 12rem;
}
.courseTimeLanguage {
  font-family: "RakutenRoundSemiBold";
  font-size: 0.875rem;
  text-transform: uppercase;
}
.courseProvider {
  font-family: "RakutenRoundRegular";
  font-size: 0.875rem;
  text-transform: uppercase;
}
.enrollStatus {
  font-family: "RakutenRoundRegular";
  font-size: 1rem;
}
.textualSection {
  min-height: 15rem;
  margin-bottom: 3rem;
}

.detailLabel {
  font-size: 2rem;
  margin-bottom: 1rem;
  word-wrap: break-word;
}
.objectiveRow {
  margin-left: 2.75rem;
}
.otherContentContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}
.contentPadding {
  padding-right: 1rem;
  word-wrap: break-word;
  width: 98%;
}
.descriptionText > li {
  white-space: pre-wrap;
}
.courseImage {
  height: 18.25rem;
  width: 33.5rem;
  object-fit: fill;
  border-radius: 0.5rem;
  margin-top: 3rem;
  position: absolute;
}
.courseImageBelowText {
  height: 5rem;
  width: 33.5rem;
  background: #fff;
  align-items: center;
  justify-content: center;
}
.courseImageBelowText > span {
  color: #00a0f0;
  font-size: 1.5rem;
  font-family: "RakutenRoundSemiBold";
}

.iconRotate {
  transform: rotate(-180deg);
}
.chevronIcon {
  padding: 0 0.5rem;
}

.publicContentContainer {
  min-height: 15rem;
  background-color: #f7f7f7;
}
.bannerContainer {
  display: flex;
  flex-direction: row;
}
.bannerContent {
  display: flex;
  flex-direction: column;
  width: 18rem;
  text-align: center;
  margin: 4rem 0 0 0;
}
.bannerLabel {
  font-size: 1.125rem;
}
.bannerDescription {
  font-size: 1.875rem;
  font-family: "RakutenRoundRegular";
}

.bannerPublicHorizontalLine {
  width: 5.25rem;
  height: 0;
  border: 0.0625rem solid #00a0f0;
  margin: 7rem 4rem;
}
.buttonContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 19rem;
}
.statusContainer {
  position: absolute;
  top: 25rem;
}
.primaryButton {
  width: 12.5rem;
  height: 3.125rem;
  font-size: 1.125rem;
}
.publicButton {
  background-color: #00a0f0;
}

.unenrollButton {
  background-color: #828282;
}
.statusTextBold {
  font-family: "RakutenRoundSemiBold";
  font-size: 0.875rem;
}
.quoteContainer {
  margin-bottom: 5rem;
  background-color: #fff;
  padding: 5rem 0;
}
.modalFooterContent {
  color: #ff0000;
  font-size: 0.75rem;
  margin: 2rem 0 0 2rem;
}
.filler {
  height: 3rem;
  background-color: #fff;
}

.secondContainer {
  width: 82.63625rem !important;
  margin: 0rem;
  padding: 0rem;
  padding-bottom: 7.5rem;
}

.container {
  max-width: 82.5rem;
  background-color: white;
  padding: 0;
  position: relative;
  margin-top: 7rem;
}
.alertContainer {
  top: 4rem;
}

.descriptionButton {
  margin-top: 2rem;
  background-color: transparent;
  border: none;
  color: #00a0f0;
  text-transform: uppercase;
}
.showMore {
  overflow: hidden;
  word-wrap: break-word;
  height: auto;
  width: 98%;
}
.showLess {
  padding-bottom: 1rem;
  max-height: 10rem;
  overflow: hidden;
  word-wrap: break-word;
  -webkit-mask-image: linear-gradient(#fff, #fff, rgba(255, 255, 255, 0.2));
  position: relative;
  width: 98%;
}

.contentPadding a {
  text-decoration: underline !important;
  color: #00a0f0 !important;
}
.timeStamp {
  color: #ff0000;
}

.courseCountSpan {
  height: 2.125rem;
  width: 2.125rem;
  background-color: #f7f7f7;
  border-radius: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}
.elearningLogo {
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
}
.courseTitle {
  display: inline-block;
}
.courseHeading {
  font-size: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  letter-spacing: 0;
  line-height: 2rem;
  font-family: "RakutenRoundSemiBold";
  color: #000000 !important;
}
.courseDetails {
  font-family: "RakutenRoundRegular";
  font-size: 0.875rem;
  line-height: 1.438rem;
}
.courseDuration {
  display: inline-block;
  font-family: "RakutenRoundSemiBold";
  font-size: 0.875rem;
}
.courseGear {
  display: inline-block;
  margin-left: 2rem;
  text-transform: capitalize;
}

.courseList {
  width: 94%;
  height: 10rem;
  background-color: #ebebeb;
  border-radius: 5rem;
  margin-left: 3%;
  margin-bottom: 3rem;
}
.imageDiv {
  border: 1px solid #000;
  height: 7rem;
  width: 7rem;
  border-radius: 50%;
}
.courseStatus {
  height: 1.75rem;
  width: 1.75rem;
  border: 1px solid #333333;
  border-radius: 50%;
}
.paddingInternalBox {
  display: flex;
  align-items: center;
  padding-top: 2%;
}
.courseStatusAlignment {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}
.courseImgAlignment {
  display: flex;
  justify-content: center;
}

.countStyle {
  display: flex;
  font-size: 0.875rem;
  font-family: "RakutenRoundSemiBold";
}
.courseCountDiv {
  height: 3.125rem;
  width: 8.5rem;
  border-radius: 0.25rem;
  background-color: #ffffff;
  display: flex;
  align-content: space-around;
  align-items: center;
  justify-content: center;
}
.hoursCountDiv {
  height: 3.125rem;
  width: 8.5rem;
  border-radius: 0.25rem;
  background-color: #ffffff;
  display: flex;
  align-content: space-around;
  align-items: center;
  justify-content: space-evenly;
  margin-left: 2rem;
  margin-right: 2rem;
}

.pathStatusMessage {
  margin-top: 1.3125rem;
}

@media screen and (min-width: 1800px) {
  .secondContainer {
    /* margin-left: 15rem; */
    /* padding-bottom: 0rem; */
  }
}

@media screen and (max-width: 476px) {
  .secondContainer {
    /* margin-left: 15rem; */
    padding-bottom: 0rem;
  }
  .pageWidth {
    width: unset !important;
    overflow: hidden;
  }
  .innerContainer {
    max-width: unset !important;
    margin-right: unset;
  }
}
