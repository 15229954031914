.wrapText {
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	line-height: 1.58rem;
}
.buttonMargin {
	margin-right: 0.5rem;
    float: left;
    
}
.declineStyle{
    float: right;
}
.declineButton {
	transform: rotate(45deg);
}
.pageFilter {
	position: relative;
	top: 3.5rem;
	margin: 0 auto;
}
.toggleExpandButton {
    cursor: pointer;
  }
.moreDetailHeader{
    width: 8rem;
    margin: auto;
    text-align: center;
}
.sessionInfo{
	cursor: pointer;
	color: #00a0f0;
}
.headerColor{
	background-color: aqua !important;
}